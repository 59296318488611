import { field, on } from '@nsf/core/GraphQL.js'
import cartItemPrices from './cartItemPrices.js'

export default field('bound_products', [
  field('id'),
  field('stock'),
  field('quantity'),
  field('product', [
    field('sku'),
  ]),
  cartItemPrices,
  ...on('SimpleCartItem', [
    field('customizable_options', [
      field('label'),
      field('values', [
        field('value'),
        field('label'),
      ]),
    ]),
  ]),
])
