import logger from '@nsf/pickup-select/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import mapWithPharmacyMapperDeprecated from '../mappers/PharmacyMapperDeprecated.js'
import mapWithPharmacyMapper from '../mappers/PharmacyApiMapper.js'

const {
  rootConfig: {
    global: {
      pharmacyTypes,
    },
  },
  pickupSelect: {
    excludeRegionIds,
  },
} = useAppConfig()

const {
  public: {
    magentoUrl,
    pickupSelectDataFormat,
    pickupSelectPharmacyApiEnabled,
    pickupSelectPharmacyApiUrl,
  },
} = useRuntimeConfig()

/**
 * Normalize pharmacies data coming from different sources
 *
 * @param {Object[]} responseJson
 * @returns {Object[]}
 */
const normalizePharmaciesData = (responseJson) => {
  switch (pickupSelectDataFormat) {
    case 'cz-deprecated': // Original Apotheka API (CZ)
    case 'ro-static': // Based on the original Apotheka API (CZ)
      return mapWithPharmacyMapperDeprecated(responseJson)
    case 'cz-tmp': // Interim solution
    case 'ro-tmp': // Interim solution
    case 'unified': // Final solution for all countries
      return mapWithPharmacyMapper(responseJson)

    default:
      logger.error('normalizePharmaciesData(%o) failed: %s', responseJson, 'data format not valid')
      return []
  }
}

/**
 * Filter pharmacies by input filter
 * - Remove excluded pharmacies by excluded regionId defined in .env configuration
 * - Accept only specified pharmacy types defined in .env configuration. If no type is specified, we accept all types
 *
 * @param {Object[]} pharmacies
 * @returns {Object[]}
 */
const pharmaciesDataInputFilter = (pharmacies) => {
  let output = pharmacies
  if (excludeRegionIds.length > 0) {
    output = output.filter((pharmacy) => !excludeRegionIds.includes(pharmacy?.regionId?.toString()))
  }
  if (pharmacyTypes.length > 0) {
    output = output.filter((pharmacy) => pharmacyTypes.includes(pharmacy?.pharmacy_type?.toString()))
  }
  return output
}

/**
 * Fetch JSON with all pharmacies data
 *
 * @param {Object} graphqlConfig - Magento supplied configuration
 *
 * @returns {Promise<Object[]>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getPharmacies = async (graphqlConfig) => {
  try {
    let path
    if (pickupSelectPharmacyApiEnabled) {
      path = `${pickupSelectPharmacyApiUrl}/pharmacies/cart`
    } else {
      const jsonFile = graphqlConfig && graphqlConfig.drmaxNsfPharmacyJsonFile
      path = `${magentoUrl}/media/upload/${jsonFile}`
    }

    // Download data
    const response = await fetch(path)
    const json = await response.json()

    // Normalize pharmacies
    const pharmacies = pharmaciesDataInputFilter(normalizePharmaciesData(json))

    // Initial sort - by city name (alphabetically), then by location (from SE to NW)
    pharmacies.sort((a, b) => {
      if (a.city === b.city) {
        return (a.latitude + a.longitude < b.latitude + b.longitude) ? -1 : 0
      }
      return a.city < b.city ? -1 : 1
    })

    return pharmacies
  } catch (e) {
    logger.error('getPharmacies(%o, %o) failed: %s', magentoUrl, graphqlConfig, e.message)

    return []
  }
}
