import logger from '@nsf/base/logger.js'
import { mapDataToModel } from '@nsf/core/Mapper.js'
import { extract } from '@nsf/core/Utils.js'

export const mapDataToCmsPage = (data, translations = {}) => {
  const model = mapDataToModel(data, {
    content: JSON.parse,
    custom_json: mapJson,
    ...translations,
  })

  if (!model.metaTitle) {
    model.metaTitle = model.title
  }

  model.seo = extract(model, 'metaTitle', 'metaDescription', 'metaKeywords')

  return model
}

const mapJson = (data) => {
  if (!data) {
    return {}
  }
  try {
    return JSON.parse(data)
  } catch (e) {
    logger.withTag('graphql').error('mapping to JSON failed for wrong custom JSON format : %s', e.message)
    return {}
  }
}
