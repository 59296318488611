import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  catalog: {
    catalog: {
      pim,
    },
  },
} = useAppConfig()

export function isTemporaryUnavailable(product) {
  return product.drmaxPimStatus === 'Temporary unavailable' || product.drmaxPimStatus === pim.temporaryUnavailable
}

export function isPermanentlySoldOut(product) {
  return product.drmaxPimStatus === 'Permanently unavailable'
    || product.drmaxPimStatus === 'Delisted - sold off'
}

export function isAdvertisingProduct(product) {
  return product.attributeSetLabel === 'Promo'
}

export function isPreStock(product) {
  return product.drmaxPimStatus === 'Visible'
}

export function allowNegativeStock(product) {
  return product?.backorders === 1 || product?.backorders === 2 // index 1 = "Allow qty bellow 0" in M2
}

export function isInStock(product) {
  if (isPermanentlySoldOut(product) || isPreStock(product) || isTemporaryUnavailable(product)) {
    return false
  }
  return allowNegativeStock(product) || product.drmaxStockSalableQty > 0
}

export function isMarketplaceProduct(product) {
  return !!product.drmaxIsMarketplaceProduct
}
