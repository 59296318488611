import { Query, field } from '@nsf/core/GraphQL.js'

export default () => Query.named('countries')
  .fields([
    field('available_regions', [
      field('code'),
      field('id'),
      field('name'),
      field('extension_attributes', [
        field('validation_name'),
      ]),
    ]),
    field('id'),
    field('full_name_locale'),
    field('full_name_english'),
    field('three_letter_abbreviation'),
    field('two_letter_abbreviation'),
  ])
  .shouldBeCached(true)
