import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import logger from '@nsf/base/logger.js'

const { appUrlInternal, public: { appUrl } } = useRuntimeConfig()

const getUrl = () => appUrlInternal || appUrl

// eslint-disable-next-line import/prefer-default-export
export const getRating = async () => {
  try {
    const response = await fetch(`${getUrl()}/merchant-rating`)

    if (response.status !== 200) {
      throw new Error('Unable to load shop rating')
    }

    return await response.json()
  } catch (e) {
    logger.withTag('RatingRepository').error('getRating() failed: %s', e.message)
    return null
  }
}
