import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import features from './features.mjs'
import orderStatuses from './orderStatuses.mjs'
import agreementsSchema from './agreementsSchema.mjs'


export default defineAppConfig({
  ...features,
  ...orderStatuses,
  ...agreementsSchema,
  isExtendedAccountMenu: false,
})
