/**
 * Re-export browser consola for compatibility purposes
 */
const {
  createConsola,
  Consola,
  consola,
  LogLevels,
  LogTypes,
} = require('consola')

module.exports.Consola = Consola
module.exports.LogLevels = LogLevels
module.exports.LogTypes = LogTypes
module.exports.consola = consola
module.exports.createConsola = createConsola
module.exports.default = consola
