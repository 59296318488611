import logger from '@nsf/articles/logger.js'
import { withDefault } from '@nsf/core/Mapper.js'
import { variable } from '@nsf/core/GraphQL.js'
import {
  REQUIRED_STRING, REQUIRED_INT,
} from '@nsf/core/consts/GraphQLTypes.js'
import {
  authorQuery, authorsQuery,
} from '@ioc/graphql/queries.js'
import { mapDataToAuthor } from '../mappers/AuthorMapper.js'

export const getDefaultAuthor = () => ({
  id: 0,
  name: '',
})

export const getAuthorById = async (id) => {
  try {
    const response = await authorQuery()
      .where(
        'id',
        variable(
          'id',
          REQUIRED_INT,
        ),
      )
      .bind({ id })
      .get()

    const author = withDefault(
      getDefaultAuthor,
      mapDataToAuthor(response),
    )

    return { author }
  } catch (e) {
    logger.withTag('graphql')
      .error(
        'getAuthorById(%o) failed: %s',
        id,
        e.message,
      )

    return { author: getDefaultAuthor() }
  }
}

export const getAuthorByUrl = async (
  url, page = 1,
) => {
  try {
    const response = await authorQuery()
      .where(
        'url_key',
        variable(
          'url',
          REQUIRED_STRING,
        ),
      )
      .bind({ url })
      .bind({ page })
      .get()

    const author = withDefault(
      getDefaultAuthor,
      mapDataToAuthor(
        response,
        {},
        true,
      ),
    )

    return { author }
  } catch (e) {
    logger.withTag('graphql')
      .error(
        'getAuthorByUrl(%o) failed: %s',
        url,
        e.message,
      )

    return { author: getDefaultAuthor() }
  }
}

export const getAuthors = async (limit) => {
  try {
    const response = await authorsQuery()
      .where(
        'limit',
        variable(
          'limit',
          REQUIRED_INT,
        ),
      )
      .bind({ limit })
      .get()

    const authors = withDefault(
      getDefaultAuthor,
      mapDataToAuthor(response.items),
    )

    return { authors }
  } catch (e) {
    logger.withTag('graphql')
      .error(
        'getAuthors(%o) failed: %s',
        e.message,
      )

    return { authors: [] }
  }
}
