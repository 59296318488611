const pickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'Dostawa do' },
      {
        text: 'Paczkomaty24.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Gdy Twoja przesyłka będzie gotowa do odbioru, dostaniesz powiadomienie w aplikacji/e-mail/SMS.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Zamówienie możesz odebrać' },
      {
        text: 'w ciągu 48h od jego dostarczenia do Paczkomatu.',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: 'Dostawa do Paczkomaty24',
  unavailablePickupLabel: 'Paczkomat niedostępny',
  selectedPlaceLabel: 'Wybrany Paczkomat',
  confirmButtonLabel: 'Wybierz Paczkomat',
  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.street, this.location.city, this.externalId].join(', ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  getReviewAddress() {
    return [this.location.street, this.location.city, this.externalId].filter(Boolean).join(', ')
  },
  pins: {
    enabled: 'pin-paczkomaty-enabled.svg',
    disabled: 'pin-paczkomaty-disabled.svg',
    selected: 'pin-paczkomaty-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#FEC909',
  icon: '/img/delivery/box.svg',
}
