import { mapDataToModel } from '@nsf/core/Mapper.js'
import { extract } from '@nsf/core/Utils.js'
import { mapDataToArticle } from './ArticleMapper.js'

export const mapDataToAuthor = (
  data, translations = {}, seoSideEffects = false,
) => {
  const model = mapDataToModel(
    data,
    {
      author_id: [
        'id',
        Number,
      ],
      posts: (data) => ({
        items: mapDataToArticle(data.items),
        count: data.count,
      }),
      meta_tags: [
        'metaKeywords',
        String,
      ],
      ...translations,
    },
  )

  // seo side effects used only for author page
  if (model.id && seoSideEffects) {
    model.seo = extract(
      model,
      'metaTitle',
      'metaDescription',
      'metaKeywords',
    )
  }

  return model
}
