import { Query, field, variable } from '../../GraphQL.js'
import { REQUIRED_STRING } from '../../consts/GraphQLTypes.js'

export const urlResolverQuery = Query.named('urlResolver')
  .where('url', variable('url', REQUIRED_STRING))
  .fields([
    field('id'),
    field('type'),
    field('relative_url'),
  ])
  .shouldBeCached(true)
