import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { shouldUseNativeAnchor, normalizeUrl } from '@nsf/utils/UrlUtils.js';
export default {
  name: 'VLink',
  functional: true,
  props: {
    to: {
      type: String,
      default: ''
    },
    external: {
      type: Boolean,
      default: false
    },
    newWindow: {
      type: Boolean,
      default: false
    }
  },
  render: function render(h, ctx) {
    var url = normalizeUrl(ctx.props.to);
    var useAnchor = shouldUseNativeAnchor(url, ctx.props.external, ctx.props.newWindow);
    var component = useAnchor ? 'a' : 'nuxt-link';
    var attrs = useAnchor ? 'attrs' : 'props';
    var to = useAnchor ? 'href' : 'to';
    var on = useAnchor ? 'on' : 'nativeOn';
    var newWindow = ctx.props.newWindow ? {
      target: '_blank',
      rel: 'noopener'
    } : {};
    var listeners = ctx.listeners || {};
    var data = _objectSpread(_objectSpread({}, ctx.data), {}, _defineProperty(_defineProperty({}, attrs, _objectSpread(_objectSpread(_objectSpread({}, ctx.data[attrs] || {}), newWindow), {}, _defineProperty({}, to, url))), on, _objectSpread(_objectSpread({}, ctx.data[on] || {}), listeners)));
    return h(component, data, ctx.children);
  }
};