import { field } from '@nsf/core/GraphQL.js'

export default [
  field('question_id'),
  field('answer'),
  field('title'),
  field('meta_title'),
  field('created_at'),
  field('full_text'),
  field('visibility'),
  field('url_key'),
  field('is_top_answer'),
]
