import {
  Query, field,
} from '@nsf/core/GraphQL.js'

export default () => Query.named('blogCatlist')
  .fields([
    field('name'),
    field('category_id'),
    field('icon'),
    field('relative_url'),
    field('level'),
  ])
  .shouldBeCached(true)
