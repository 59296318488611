import Vue from 'vue'

Vue.prototype.$push = function(url) {
  url = url.replace(/\/\/+/g, '/')

  if (url[0] !== '/') {
    url = `/${url}`
  }

  if (url[url.length - 1] === '/') {
    url = url.slice(0, url.length - 1)
  }

  this.$router.push(url)
}
