import logger from '@nsf/gtm/logger.js'
import { isServer } from '@nsf/core/Utils.js'
import useAppConfig from '@nsf/use/composables/useAppConfig.js'

const {
  gtm: {
    dataLayer,
  },
} = useAppConfig()

/**
 * IMPORTANT:
 *  - This function must not be called from outside of @nsf/gtm module!
 *  - Every GTM event should have its function defined in @nsf/gtm/events directory.
 *  - This is to prevent misusing/abusing GTM implementation.
 *
 * @param event
 */
export const usePushEvent = (event) => {
  if (isServer()) {
    logger.warn('GTM push is only available on client.')
    return
  }

  // micro optimization for INP metric
  setTimeout(() => {
    window[dataLayer].push(event)
  }, 0)
}

export default usePushEvent
