import { registerPageResolver } from '@nsf/core/PageResolver.js'
import { BrandBuilder } from '@nsf/brands/builders/BrandBuilder.js'

export default function () {
  registerPageResolver(
    'BRAND',
    (id, url) => new BrandBuilder(id, url).build(),
    () => import(/* webpackChunkName: "dynamicPages/BRAND" */ '@nsf/brands/dynamicPages/BrandDetail.vue'),
  )
}
