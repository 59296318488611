import { mapDataToModel } from '@nsf/core/Mapper.js'

export const mapDataToPharmacist = (
  data, translations = {},
) => mapDataToModel(
  data,
  {
    description: String,
    fullName: String,
    photo_url: [
      'image',
      String,
    ],
    ...translations,
  },
)
