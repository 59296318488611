import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    features: {
      customerNotesEnabled,
      itInvoicingEnabled,
      privateInvoiceEnabled,
      streetNumberToMagentoEnabled,
    },
  },
  rootConfig: {
    global: {
      crnEnabled,
      skDicEnabled,
      streetNumberEnabled,
      vatIdEnabled,
    },
  },
} = useAppConfig()

export default field('billing_address', [
  field('firstname'),
  field('lastname'),
  field('telephone'),
  field('street'),
  field('postcode'),
  field('city'),
  field('region', [
    field('code'),
  ]),
  field('company'),
  ...vatIdEnabled ? [field('drmax_vat_id')] : [],
  ...crnEnabled ? [field('companyRegistrationNumber: drmax_crn')] : [],
  ...skDicEnabled ? [field('skDic: drmax_sk_dic')] : [],
  ...itInvoicingEnabled ? [field('itFiscalCode: drmax_fiscal_code')] : [],
  ...itInvoicingEnabled ? [field('itPec: drmax_invoice_pec')] : [],
  ...itInvoicingEnabled ? [field('itSdi: drmax_invoice_sdi')] : [],
  ...(streetNumberToMagentoEnabled && streetNumberEnabled ? [field('streetNumber: drmax_sk_house_number')] : []),
  ...privateInvoiceEnabled
    ? [
        field('drmax_invoice_requested'),
        field('drmax_invoice_recipient_type'),
      ]
    : [],
  field('country', [
    field('code'),
    field('label'),
  ]),
  ...(customerNotesEnabled ? [field('customer_notes')] : []),
])
