import { Query, field } from '@nsf/core/GraphQL.js'

export default () => Query.named('faq_categories')
  .fields([
    field('title'),
    field('category_id'),
    field('questions', [
      field('short_answer'),
      field('title'),
    ]),
  ])
  .shouldBeCached(true)
