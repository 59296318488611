const pickupInstructions = []

export default {
  orderLabel: 'Zamów na miejsce',
  reservationLabel: 'Rezerwacja w aptece',
  rxReservationLabel: 'Rezerwacja leków na receptę w aptece',
  unavailablePickupLabel: 'Miejsce niedostępne',
  selectedPlaceLabel: 'Wybrane miejsce',
  confirmButtonLabel: 'Wybierz miejsce',
  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.street, this.location.city].join(', ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-boxes-enabled.svg',
    disabled: 'pin-boxes-disabled.svg',
    selected: 'pin-boxes-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#FEC909',
  icon: '/img/delivery/box.svg',
}
