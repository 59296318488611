import { field } from '@nsf/core/GraphQL.js'

export const brandFields = [
  field('bottom_cms_block_id'),
  field('desc'),
  field('dsc_bottom'),
  field('dsc_top'),
  field('filter_code'),
  field('hide_brand'),
  field('id'),
  field('image'),
  field('layout'),
  field('meta_description'),
  field('meta_keywords'),
  field('meta_title'),
  field('option_id'),
  field('position'),
  field('short_description'),
  field('small_image'),
  field('small_image_in_short_description'),
  field('store_hide_brand'),
  field('store_option_setting_id'),
  field('title'),
  field('tsk'),
  field('url_key'),
  field('url_path'),
  field('visible_in_listing'),
  field('priority_categories'),
]
