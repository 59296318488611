export const state = () => ({
  // all available filter which can be used for place filtering in map
  // selected filter has property active set to true
  userFilters: [],
})

export const getters = {
  getUserFilters: (state) => state.userFilters,
  getActiveUserFilters: (state) => state.userFilters.filter((filter) => !!filter.active),
  isUserFilterActive: (state) => (filterId) => state
    .userFilters.find(({ id }) => id === filterId)?.active,
}

export const actions = {
  initUserFilters({ commit }, filters) {
    commit('clearUserFilters')
    for (const filter of filters) {
      commit('addUserFilter', filter)
    }
  },
}

export const mutations = {
  addUserFilter(state, filter) {
    state.userFilters.push(filter)
  },

  setUserFilters(state, filters) {
    state.userFilters = filters
  },

  clearUserFilters(state) {
    state.userFilters = []
  },

  toggleUserFilter(state, filterId) {
    state.userFilters.map((filter) => filter.id === filterId && (filter.active = !filter.active))
  },
}
