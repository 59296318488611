import { Query, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import baseFields from '../fragments/baseFields.js'
import tags from '../fragments/tags.js'
import itemsPharmacistsFields from '../fragments/pharmacistsFields.js'

export default () => Query.named('faqQuestionByUrl')
  .where('url_key', variable('urlKey', REQUIRED_STRING))
  .fields([
    ...baseFields,
    tags,
    field('pharmacist', itemsPharmacistsFields),
    field('categories', [
      field('category_id'),
      field('title'),
    ]),
    field('noindex'),
    field('nofollow'),
  ])
  .shouldBeCached(true)
