import { Query } from '@nsf/core/ElasticSearch.js'

export const state = () => ({
  query: null,
})

export const mutations = {
  setQuery(state, payload) {
    state.query = JSON.parse(JSON.stringify(payload))
  },
}

export const getters = {
  query: (state) => Object.assign(new Query(), state.query),
}
