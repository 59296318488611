import { isEmpty } from '@nsf/core/Utils.js'
import { ensureArray } from '@nsf/utils/ArrayUtils.js'
import { sliceHashPartFromUrl, sliceSlashFromUrl } from '@nsf/utils/UrlUtils.js'
import { getAttributeOptionByUrlPrefix } from '@nsf/catalog/repositories/AttributeRepository.js'

export async function fetchFilterPrefix() {
  const urlPrefix = getUrlPrefix(this._url)

  if (!urlPrefix) {
    return { _filterPrefix: {} }
  }

  const { option } = await getAttributeOptionByUrlPrefix(urlPrefix)
  return { _filterPrefix: option }
}

export function getFilter(queryFilter, data) {
  const prefix = data._filterPrefix
  if (!prefix || isEmpty(prefix)) {
    return ensureArray(queryFilter)
  }

  const filter = ensureArray(queryFilter)

  const index = filter.findIndex((option) => option.includes(prefix.attributeCode))

  if (index === -1) {
    filter.push(`${prefix.attributeCode},${prefix.value}`)
    return filter
  }
  if (filter[index].includes(prefix.value)) {
    return queryFilter
  }

  filter[index] += `,${prefix.value}`
  return filter
}

export function getUrlPrefix(url = '') {
  const urlSplit = sliceHashPartFromUrl(sliceSlashFromUrl(url)).split('/')
  return urlSplit.length > 1 ? urlSplit.shift() : ''
}
