import { queryToObject } from '@nsf/utils/UrlUtils.js'

export class Builder {
  constructor(id, fullUrl, ctx) {
    this._data = {}
    this._id = id
    this._fullUrl = fullUrl
    this._ctx = ctx

    const [url, query] = fullUrl.split('?')

    this._url = url
    this._query = queryToObject(query)
  }

  id() {
    return this._id
  }

  query(param) {
    return this._query[param]
  }

  async load(loaders = []) {
    const responses = await Promise.all(loaders.map((loder) => loder.call(this, this._data)))

    this._data = responses.reduce((data, response) => ({ ...data, ...(response || {}) }), this._data)
  }

  async build() {
    await this.load()

    return this._data
  }
}
