/**
 * Documentation: https://mydrmax.atlassian.net/wiki/spaces/WAD/pages/3386802177/User+context+v1
 */

import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'
import { get, set } from '@nsf/core/Storage.js'

/**
 * @param {Object} user
 */
export const pushUserEvent = (
  user = null,
) => {
  const event = {
    id: user?.clientId,
    firstname: user?.firstName,
    surname: user?.lastName,
    email: user?.email,
    phone: user?.phoneNumber,
    birthDate: user?.birthDate,
    gender: user?.sex,
    accountType: user?.accountType === 'O' ? 'full_account' : 'limited_account',
  }

  if (!user && get('user/event')) {
    usePushEvent({ user: get('user/event') })

    return
  }

  if (user && !get('user/event')) {
    usePushEvent({ user: event })

    set('user/event', event)
  }
}
