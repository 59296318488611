export default {
  apiUrl: 'https://maps.googleapis.com/maps/api/js',
  apiVersion: '3.58',
  gestureHandling: 'greedy',

  zoomLevels: {
    default: 14,
    geolocation: 15,

    /**
        * Point types used in Google Place API
        * List of available place types: https://developers.google.com/maps/documentation/places/web-service/supported_types#table2
      */
    country: 6,
    administrativeAreaLevel1: 8,
    administrativeAreaLevel2: 10,
    locality: 13,
    sublocality: 14,
    neighborhood: 14,
    route: 15,
    townSquare: 15,
    streetAddress: 16,
    establishment: 16,

    noCluster: 15, // lowest level with no clusters according to Pickup Places API

    maxZoom: 19,
  },

  pins: {
    userLocation: 'pin-user-location.png',
  },

  clusterFillColors: {
    default: '#808080',
  },

}
