import logger from '@nsf/apotheka-web/logger.js'
import { setState } from '@nsf/core/Store.js'
import { mapDataToPoints } from '../mappers/PointMappers.js'
import {
  POINT_TYPES,
  filteredByAll,
  withinMapBounds,
  distinctByDistanceFrom,
  nearestTo,
  separatePlacesByType,
} from '../utils/PlaceUtils.js'

export const state = () => ({
  /** Place selected by user */
  selectedPlace: null,

  /** Place hovered by user */
  hoveredPlace: null,

  isLoading: false,
})

export const getters = {
  /**
   * Gathers places from submodules.
   */
  places: (_, __, rootState) => {
    const pharmacyPlaces = rootState['apotheka-web/places/pharmacies'].places
    return [...pharmacyPlaces]
  },

  /**
   * Nearest place to the location.
   */
  nearestPlace: (_, { places }, __, rootGetters) => {
    const nearestToCurrentLocation = nearestTo(rootGetters['apotheka-web/map/location'])

    return nearestToCurrentLocation(places)
  },

  placesFiltered: (_, { places }, __, rootGetters) => {
    const filteredByAllFilters = filteredByAll(rootGetters['apotheka-web/filters/getFilters'])

    return filteredByAllFilters(places)
  },

  disabledPlacesIds: (_, { places }, __, rootGetters) => {
    const filters = rootGetters['apotheka-web/userFilters/getActiveUserFilters']
    if (!filters.length) {
      return []
    }

    const filteredIds = filteredByAll(filters.map((item) => item.func))(places).map((item) => item.pickupPlaceId)

    return places.map((item) => item.pickupPlaceId).filter((x) => !filteredIds.includes(x))
  },

  /**
   * Places that are now within boundaries of the map. Return empty array when map in not shown yet.
   */
  placesWithinMapBounds: (_, { places }, __, rootGetters) => {
    const filteredByAllFilters = filteredByAll(rootGetters['apotheka-web/filters/getFilters'])
    const withinCurrentMapBounds = withinMapBounds(rootGetters['apotheka-web/map/mapBounds'])

    return filteredByAllFilters(withinCurrentMapBounds(places))
  },

  /**
   * Places within map bounds and sorted.
   */
  placesWithinMapBoundsSorted: (_, { places }, __, rootGetters) => {
    const filteredByAllFilters = filteredByAll(rootGetters['apotheka-web/filters/getFilters'])
    const withinCurrentMapBounds = withinMapBounds(rootGetters['apotheka-web/map/mapBounds'])

    const autocompletePlace = rootGetters['apotheka-web/map/autocompletePlace']
    const userLocation = rootGetters['apotheka-web/map/location']

    const sortLocation = { ...userLocation }

    if (autocompletePlace) {
      sortLocation.longitude = autocompletePlace.geometry?.location?.lng()
      sortLocation.latitude = autocompletePlace.geometry?.location?.lat()
    }

    const sortedByDistance = distinctByDistanceFrom(sortLocation)
    return sortedByDistance(filteredByAllFilters(withinCurrentMapBounds(places)))
  },

  selectedPlace: (state) => state.selectedPlace,
  hoveredPlace: (state) => state.hoveredPlace,
  isLoading: (state) => state.isLoading,
}

export const mutations = {
  setState,

  setIsLoading: (state, payload) => {
    state.isLoading = payload
  },

  setSelectedPlace: (state, payload) => {
    state.selectedPlace = payload
  },

  setHoveredPlace: (state, payload) => {
    state.hoveredPlace = payload
  },
}

export const actions = {
  initPlaces: async ({ commit, dispatch }, { points, mode }) => {
    commit('setState', { isLoading: true })

    switch (mode) {
      case 'pointsBased': {
        const pointsNormalized = mapDataToPoints(points)
        const pointsByType = separatePlacesByType(pointsNormalized)
        const types = Object.keys(pointsByType)

        const supportedTypes = [POINT_TYPES.PHARMACY]

        if (types.includes(POINT_TYPES.PHARMACY)) {
          await dispatch('apotheka-web/places/pharmacies/initPlaces', {
            points: pointsByType[POINT_TYPES.PHARMACY],
            mode,
          }, { root: true })
        }

        types.forEach((type) => {
          if (!supportedTypes.includes(type)) {
            logger.warn(`initPlaces(): unknown type ${type}`)
          }
        })

        break
      }

      case 'allPharmaciesOverride':
        await dispatch('apotheka-web/places/pharmacies/initPlaces', {
          mode,
        }, { root: true })
        break
      case 'allDermocentersOverride':
        await dispatch('apotheka-web/places/pharmacies/initPlaces', {
          mode,
          queryParams: 'onlyDermatologyConsulting=true',
        }, { root: true })
        break

      default:
        logger.warn(`initPlaces(): unsupported mode ${mode}`)
    }

    commit('setState', { isLoading: false })
  },

  clearPlaces: ({ commit }) => {
    commit('apotheka-web/places/pharmacies/clearPlaces', null, { root: true })
  },
}
