export default {
  category: {
    filters: {
      defaultMaxPrice: 100,
      defaultPriceFieldName: 'final_price',
    },
    meiro: {
      baseUrl: 'https://cdp.drmax.meiro.io',
    },
    root: {
      id: 633,
    },
  },
}
