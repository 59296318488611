import { Query, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { tagPage } from '../fragments/tagPage.js'

export default () => Query.named('tagPageByUrl')
  .where('url', variable('url', REQUIRED_STRING))
  .fields([
    field('tag_id'),
    field('full_content'),
    field('url_key'),
    field('name'),
    field('seo_nofollow'),
    field('seo_noindex'),
    field('seo_meta_robots'),
    field('seo_canonical_url'),
    field('page_layout'),
    ...tagPage,
  ])
  .shouldBeCached(true)
