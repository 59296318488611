import { Query, variable, field } from '@nsf/core/GraphQL.js'

export default () => Query.named('fastestDeliveries')
  .toDeliveryCalculator()
  .where('products', variable('products', '[ProductInput!]'))
  .where('deliveryMethods', variable('deliveryMethods', '[Float!]')) // TODO: change to Int
  .where('includeReservations', variable('includeReservations', 'Boolean!', false))
  .fields([
    field('bundle', [
      field('deliveryMethod'),
      field('pickupPlaceType'),
      field('deliveryTime'),
    ]),
    field('separate', [
      field('sku'),
      field('methods', [
        field('deliveryMethod'),
        field('pickupPlaceType'),
        field('deliveryTime'),
      ]),
    ]),
  ])
