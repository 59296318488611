import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import configRobots from '../robots.mjs'
import features from './features.mjs'
import footer from './footer.mjs'
import robots from './robots.mjs'

export default defineAppConfig({
  logo: {
    src: '/images/company-apteka-logo.svg',
    width: 274,
    height: 50,
    alt: 'Dr. Max Apteka',
  },
  ...features,
  ...footer,
  robots: {
    ...robots,
    ...configRobots.robots,
  },
  ...config,
})
