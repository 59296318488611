import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getPlacementProducts } from '@nsf/product-relationship-loader-placement/repositories/PlacementRepository.js'

const {
  productRelationshipLoaderPlacement: {
    placementHeroLimit,
  },
} = useAppConfig()

export default async function () {
  const userId = ''
  const config = this?.config ?? { limit: placementHeroLimit }
  return await getPlacementProducts('hero', userId, config, this?.ctx)
}
