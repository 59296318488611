import logger from '@nsf/apotheka-web/logger.js'

/**
 * Retrieve user location via Geolocation API
 *
 * @returns {Promise<Object|null>}
 */
export const getUserLocation = () => new Promise((resolve) => {
  if (!navigator?.geolocation) {
    return resolve(null)
  }

  navigator.geolocation.getCurrentPosition((position) => {
    const { longitude, latitude } = position.coords

    if (!longitude || !latitude) {
      resolve(null)
    }

    resolve({ latitude, longitude })
  }, (err) => {
    logger.warn('getUserLocation(): %s', err.message)

    return resolve(null)
  })
})
