import { field } from '@nsf/core/GraphQL.js'

export const menuRoot = field(
  'menu_root',
  [
    field('group_id'),
    field('menu_id'),
    field('parent_ids'),
    field('uses_menu'),
  ],
)
