import { mapDataToModel } from '@nsf/core/Mapper.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    features: {
      shippingAddressNameEnabled,
    },
  },
} = useAppConfig()

const mapDataToShippingAddress = (data, translations = {}) => {
  const {
    firstname,
    lastname,
    street: [street] = [],
    streetNumber,
    city,
    postcode,
    region: { code: regionCode } = {},
  } = mapDataToModel(data, { ...translations })

  return {
    ...shippingAddressNameEnabled && { firstname, lastname },
    street: street?.trim() ?? '',
    streetNumber,
    city,
    postcode,
    regionCode,
  }
}

export default mapDataToShippingAddress
