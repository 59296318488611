import dayjs from 'dayjs'

/**
 * Takes a number which represent days from now (positive or negative) and make them
 * to fit 0 through 6 (i.e. monday to sunday).
 *
 * @param {number} day
 * @returns {number}
 */
export const wrapWeek = (day) => day % 7

/**
 * Takes id of today and returns ids of following days, including today.
 *
 * E.g. today is saturday, i.e. 6. We want 3 days. We receive 6 (sat), 7 (sun), 1 (mon).
 *
 * @param count
 * @returns {[]}
 */
export const daysIdsStartingToday = (count) => {
  const today = dayjs().day()

  const days = []

  for (let i = 0; i < count; i++) {
    const followingDay = today + i

    days.push(wrapWeek(followingDay))
  }

  return days
}

/**
 * Normalize Sunday to 0 instead of 7.
 *
 * @param day {number|string}
 * @returns {number|string}
 */
export const normalizeSunday = (day) => (Number(day) === 7 ? 0 : day)

export const isSameDayPickupAvailable = (isReservation, openHours) => {
  if (!isReservation || !openHours.length) {
    return false
  }

  const currentDate = dayjs().format('YYYY-MM-DD')
  const openHoursToday = openHours.find((day) => day.date === currentDate)

  if (!openHoursToday?.close) {
    return false
  }

  const now = dayjs()
  const closesAt = dayjs(`${currentDate}T${openHoursToday.close}`)
  const difference = closesAt.diff(now, 'minute')

  return difference >= 60
}

export const toOpeningHours = (openingHours) => openingHours.map((openingHour) => {
  const date = dayjs(openingHour.date)
  const json = {
    date: date.format('YYYY-MM-DD'),
    day: date.day(),
    open: null,
    close: null,
    nonstop: openingHour.nonstop,
    closed: !openingHour.open,
  }

  if (openingHour.open) {
    const timestamps = []

    openingHour.openingHour
      .filter((element) => element.open)
      .forEach((element) => {
        timestamps.push(dayjs(element.from).valueOf())
        timestamps.push(dayjs(element.to).valueOf())
      })

    json.open = dayjs(Math.min(...timestamps)).format('HH:mm')
    json.close = dayjs(Math.max(...timestamps)).format('HH:mm')

    // Breaks
    json.breaks = openingHour.openingHour
      .filter((element) => !element.open)
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => (a.from > b.from ? 1 : (a.from < b.from ? -1 : 0)))
      .map((item) => ({
        from: dayjs(item.from).format('HH:mm'),
        to: dayjs(item.to).format('HH:mm'),
        note: item.note,
      }))

    if (json.breaks.length) {
      // Open intervals
      json.intervals = []
      let from = json.open
      json.breaks.forEach((b) => {
        if (b.from > from) {
          json.intervals.push([from, b.from])
          from = b.to
        }
      })
      json.intervals.push([from, json.close])
    }
  }

  return json
})

export const isOpen = (openingHours) => {
  const opened = openingHours.filter((openingHour) => openingHour.open)
  return !!opened.length
}

export const pharmacyIsOpened = (pharmacy) => {
  const now = dayjs()

  if (pharmacy.openingDate && pharmacy.closureDate) {
    const openingDateTime = dayjs(pharmacy.openingDate)
    const closureDateTime = dayjs(pharmacy.closureDate)

    if (!now.isBetween(openingDateTime, closureDateTime)) {
      return false
    }
  }

  if (pharmacy.openingDate) {
    const openingDateTime = dayjs(pharmacy.openingDate)
    if (now.isBefore(openingDateTime)) {
      return false
    }
  }

  if (pharmacy.openingDate) {
    const closureDateTime = dayjs(pharmacy.closureDate)
    if (now.isAfter(closureDateTime)) {
      return false
    }
  }

  return isOpen(pharmacy.openingHours)
}
