/* eslint-disable camelcase */
import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import cartPrices from '../fragments/cartPrices.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import cartAvailableShippingMethods from '../fragments/cartShippingAddressesShippingMethodsOnly.js'
import cartItemsModified from '../fragments/cartItemsModified.js'
import cartAvailablePaymentMethods from '../fragments/cartAvailablePaymentMethods.js'
import cartShippingAddress from '../fragments/cartShippingAddress.js'
import cartAppliedPaymentRestrictions from '../fragments/cartAppliedPaymentRestrictions.js'

export default () => Mutation.named('setOrderTypeOnCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    order_type: variable('orderType', REQUIRED_STRING),
  })
  .fields([
    field('cart', [
      ...cartItemsModified,
      cartPrices,
      cartAvailableShippingMethods,
      cartAvailablePaymentMethods,
      cartSelectedPaymentMethod,
      cartShippingAddress,
      cartAppliedPaymentRestrictions,
      field('order_type'),
    ]),
  ])
