import { Query, variable, field } from '@nsf/core/GraphQL.js'

export default () => Query.named('cmsBlocks')
  .where('identifiers', variable('identifiers', '[String]'))
  .fields([
    field('items', [
      field('content'),
      field('identifier'),
      field('title'),
    ]),
  ])
  .shouldBeCached(true)
