/* eslint-disable camelcase */
import { Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import cartGiftCardsModified from '../fragments/cartGiftCardsModified.js'

export default () => Mutation.named('removeGiftCardFromCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    gift_card_code: variable('giftCardCode', REQUIRED_STRING),
  })
  .fields(cartGiftCardsModified)
