export const getDefaultShippingMethod = () => ({
  carrierCode: '',
  carrierTitle: '',
  methodTitle: '',
  methodCode: '',
  pickupPlaceTypes: [],
})

export const getDefaultAppliedRestriction = () => ({
  code: '',
  title: '',
  reason: '',
})
