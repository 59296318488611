import { field } from '@nsf/core/GraphQL.js'

export default field('features', [
  field('gift_cards', [
    field('visible'),
    field('warning_message'),
  ]),
  field('coupons', [
    field('visible'),
    field('warning_message'),
  ]),
])
