/* eslint-disable camelcase */
import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING, REQUIRED_STRING_ARRAY } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('addThankYouPageProductsToOrder')
  .where('input', {
    order_id: variable('orderId', REQUIRED_STRING),
    items: variable('items', REQUIRED_STRING_ARRAY),
  })
  .fields([
    field('order_added_products', [
      field('order_id'),
      field('added_products'),
    ]),
  ])
