import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { isObject } from '@nsf/core/Utils.js';
import { useAppConfig as $useAppConfig } from '@nsf/use/composables/useAppConfig.js';
var _$useAppConfig = $useAppConfig(),
  regionSelectEnabled = _$useAppConfig.rootConfig.global.regionSelectEnabled;
export default function (address) {
  var _address$city, _address$streetNumber, _address$street;
  if (!address || !isObject(address)) {
    return null;
  }
  return _objectSpread({
    city: (_address$city = address.city) !== null && _address$city !== void 0 ? _address$city : '',
    streetNumber: (_address$streetNumber = address.streetNumber) !== null && _address$streetNumber !== void 0 ? _address$streetNumber : '',
    postcode: address.zip || address.postcode || '',
    street: (_address$street = address.street) !== null && _address$street !== void 0 ? _address$street : '',
    country: useRuntimeConfig().public.countryCode
  }, regionSelectEnabled ? {
    regionCode: address.regionCode
  } : {});
}