import dayjs from 'dayjs'

export const mapDataToCoupon = (data) => data.map((item) => ({
  couponCode: item.couponCode,
  descriptionText: item.promoText,
  discountLabel: item.actionLabels.find((it) => it.type === 'TEXT')?.label ?? '',
  discountText: item.actionLabels.find((it) => it.type.startsWith('ICON_'))?.label ?? '',
  discountType: item.actionLabels.find((it) => it.type.startsWith('ICON_'))?.type ?? 'ICON_NONE',
  discountValue: item?.discountAmount ?? 0,
  discountValueApply: item?.discountType ?? 'NOMINAL',
  eanCode: item.coupon.ean,
  image: item.coupon.image,
  legalDescriptionText: item.promoLegal,
  name: item.salesRuleName,
  sku: item.coupon.productSku ?? null,
  specialEventUrl: item.coupon.specialEventUrl ?? null,
  validFrom: item.validFrom,
  validIn: item.coupon?.validIn?.split(','),
  validTo: item.validTo,
  order: item?.coupon.sequenceNumber ?? 1,
}))

export const filterCoupons = (data) => {
  return data
    .filter((item) => dayjs().isBefore(item.validTo, 'day') || dayjs().isSame(item.validTo, 'day'))
    .filter((item) => item.validIn?.includes('eshop'))
}
