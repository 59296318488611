export const state = () => ({
  filters: [],
})

export const getters = {
  getFilters: (state) => state.filters,
}

export const actions = {
  initFilters({ commit }, filters) {
    commit('clearFilters')
    for (const filter of filters) {
      commit('addFilter', filter)
    }
  },
}

export const mutations = {
  addFilter(state, filter) {
    state.filters.push(filter)
  },

  removeFilter(state, id) {
    const index = state.filters.find((filter) => filter.id === id)
    state.filters.splice(index, 1)
  },

  clearFilters(state) {
    state.filters = []
  },
}
