import { setState } from '@nsf/core/Store.js'
import { isEmpty } from '@nsf/core/Utils.js'
import { getPharmacies } from '../../repositories/PharmacyRepository.js'

export const state = () => ({
  /** Place objects. To be shown on a map. Created based on points (simpler objects). */
  places: [],

  /** Pharmacies fetched from the API, cached until page refresh */
  pharmaciesCache: {},
})

export const actions = {
  /**
   * Do not call directly!
   */
  initPlaces: async({
    commit, _, rootGetters, state,
  }, { mode, queryParams = '' }) => {
    const cached = state.pharmaciesCache[mode]
    const isCacheAvailable = cached && !isEmpty(cached)
    const graphqlConfig = rootGetters['_base/config/config']
    let places
    if (isCacheAvailable) {
      places = cached
    } else {
      places = await getPharmacies(graphqlConfig, queryParams)
      places = places.map((item) => ({ ...item, type: 'pharmacy-express' }))
      commit('setPharmaciesCache', { mode, places })
    }

    commit('setState', { places })
  },

}

export const mutations = {
  setState,

  setPlaces: (state, payload) => {
    state.places = payload
  },

  clearPlaces: (state) => {
    state.places = []
  },

  setPharmaciesCache: (state, { mode, places }) => {
    state.pharmaciesCache[mode] = places
  },
}
