import { mapDataToModel } from '@nsf/core/Mapper.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import mapDataToItem from './ItemMapper.js'
import { mapDepositFeePrice } from './DepositFeeMapper.js'
import mapDataToItemGroups from './CartGroupsMapper.js'

const {
  checkout: {
    features: {
      boundProductsEnabled,
    },
  },
} = useAppConfig()

export const mapDataToSalesRule = (
  data, translations = {},
) => mapDataToModel(
  data,
  { ...translations },
)
  .map((rule) => ({
    ...rule,
    items: rule.items?.map((item) => ({
      ...item,
      counter: item.quantity,
      touched: false,
      touchedForQuantity: false,
    })) ?? [],
    causedBy: rule.causedBy?.map((id) => String(id)),
  }))

export const mapCartDataToSalesRules = (cart) => {
  const items = mapDataToItem(cart.items ?? [])
  const appliedSalesRules = cart.applied_sales_rules ? mapDataToSalesRule(cart.applied_sales_rules) : []
  const groups = mapDataToItemGroups(
    items,
    appliedSalesRules,
  )
  const itemGroups = boundProductsEnabled
    ? mapDepositFeePrice(
      groups,
      cart.bound_products,
    )
    : groups
  const freeShippingData = cart.free_shipping ? mapDataToModel(cart.free_shipping) : {}
  const freeShipping = {
    ...freeShippingData,
    salesRule: cart.applied_sales_rules?.find((rule) => rule.freeShipping),
  }

  return {
    appliedSalesRules,
    itemGroups,
    freeShipping,
  }
}
