import { defineNuxtConfig } from '@nsf/use/composables/defineNuxtConfig.js'

export default defineNuxtConfig({
  runtimeConfig: {
    public: {
      algoliaIndexName: 'stage_super_index',
      searchLuigisboxTrackerId: '',
    },
  },
})
