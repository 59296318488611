export const TOAST_INFO = 0
export const TOAST_SUCCESS = 1
export const TOAST_WARNING = 2
export const TOAST_DANGER = 3
export const TOAST_SECONDARY = 4

/**
 * @typedef ToastMessage {{
 *   id: any,
 *   message: string,
 *   level: number,
 *   options: {
 *     disableClose: boolean,
 *     isJson: boolean,
 *     isAnnouncement: boolean,
 *   },
 * }}
 */

export const state = () => ({
  /**
   * @type { ToastMessage[] }
   */
  messages: [],
})

export const mutations = {
  /**
   * Push raw toast message directly. Id will be generated and included into the toast message.
   * @param state {state} Vuex state
   * @param toast {ToastMessage} Message object.
   */
  push(
    state, toast,
  ) {
    toast.id = Date.now()
    const includesMessage = state.messages.some(({ options }) => options?.isAnnouncement)

    if (!includesMessage) {
      state.messages.push(toast)
    }
  },

  /**
   * Clears toast messages queue.
   * @param state {state} Vuex state
   */
  clear(state) {
    state.messages = []
  },

  /**
   * Deletes an item from queue by its id.
   * @param state
   * @param id
   */
  delete(
    state, id,
  ) {
    state.messages = state.messages.filter((item) => item.id !== id)
  },
}

export const actions = {
  /**
   * Commit a toast message push.
   * @param context
   * @param level {number} Toast message level.
   * @param message {string} Message text.
   * @param options { ToastMessage.options }
   */
  push(
    { commit }, {
      level, message, options = {},
    },
  ) {
    options.isJson = typeof message === 'object'
    const toast = {
      level,
      message,
      options,
    }
    commit(
      'push',
      toast,
    )
    return toast
  },

  /**
   * Push info toast message.
   * @param context
   * @param message {string} Message text.
   * @param options { ToastMessage.options }
   */
  info(
    { dispatch }, {
      message, options,
    },
  ) {
    return dispatch(
      'push',
      {
        level: TOAST_INFO,
        message,
        options,
      },
    )
  },

  /**
   * Push success toast message.
   * @param context
   * @param message {string} Message text.
   * @param options { ToastMessage.options }
   */
  success(
    { dispatch }, {
      message, options,
    },
  ) {
    return dispatch(
      'push',
      {
        level: TOAST_SUCCESS,
        message,
        options,
      },
    )
  },

  /**
   * Push warning toast message.
   * @param context
   * @param message {string} Message text.
   * @param options { ToastMessage.options }
   */
  warning(
    { dispatch }, {
      message, options,
    },
  ) {
    return dispatch(
      'push',
      {
        level: TOAST_WARNING,
        message,
        options,
      },
    )
  },

  /**
   * Push danger toast message.
   * @param context
   * @param message {string} Message text.
   * @param options { ToastMessage.options }
   */
  danger(
    { dispatch }, {
      message, options,
    },
  ) {
    return dispatch(
      'push',
      {
        level: TOAST_DANGER,
        message,
        options,
      },
    )
  },

  /**
   * Push danger toast message.
   * @param context
   * @param message {string} Message text.
   * @param options { ToastMessage.options }
   */
  secondary(
    { dispatch }, {
      message, options,
    },
  ) {
    return dispatch(
      'push',
      {
        level: TOAST_SECONDARY,
        message,
        options,
      },
    )
  },

  /**
   * Clears toast messages queue.
   */
  clear({ commit }) {
    return commit('delete')
  },

  /**
   * Deletes an item from queue by its id.
   * @param commit
   * @param id { any }
   */
  delete(
    { commit }, id,
  ) {
    return commit(
      'delete',
      id,
    )
  },
}

export const getters = {
  getMessages: (state) => state.messages || [],
}
