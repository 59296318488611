import { Query, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Query.named('tagPageByUrl')
  .where('url', variable('url', REQUIRED_STRING))
  .fields([
    field('tag_id'),
    field('full_content'),
  ])
  .shouldBeCached(true)
