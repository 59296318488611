import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    features: {
      pickupPlaceAddressEnabled,
      pickupPlaceCoordinatesEnabled,
      pickupPlaceExtensionAttributesEnabled,
      pickupPlaceOpeningHoursEnabled,
    },
  },
} = useAppConfig()

export default field('drmax_pickup_ids', [
  field('pickup_place_id'),
  field('pickup_place_title'),
  ...pickupPlaceCoordinatesEnabled
    ? [
        field('pickup_place_latitude'),
        field('pickup_place_longtitude'), // typo in API
      ]
    : [],
  ...pickupPlaceAddressEnabled
    ? [field('pickup_place_address', [
        field('street'),
        field('city'),
        field('zip').alias('postcode'),
      ])]
    : [],
  ...pickupPlaceOpeningHoursEnabled
    ? [field('pickup_place_opening_hours', [
        field('day'),
        field('open'),
        field('close'),
        field('closed'),
      ])]
    : [],
  ...pickupPlaceExtensionAttributesEnabled
    ? [field('extension_attributes', [
        field('attribute_code'),
        field('value'),
      ])]
    : [],
])
