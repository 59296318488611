import { setStateByPath } from '../utils/StoreUtils.js'

/**
 * Module for integration of new Click&Collect (pickup-select v2)
 */
export const state = () => ({
  selectedPickupPlace: null,
  isProcessingNewPickupPlace: false,
})

export const mutations = {
  setPickupStateGeneric: setStateByPath,
}
