import { defineNuxtConfig } from '@nsf/use/composables/defineNuxtConfig.js'

export default defineNuxtConfig({
  runtimeConfig: {
    public: {
      checkoutRedirectUrl: '', // redirect to Apteka checkout
      checkoutStockStatusApiUrl: '',
      marketplaceEnabled: false,
      giftCardsEnabled: false,
    },
  },
})
