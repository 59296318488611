/* eslint-disable sort-keys */
import { customerGetGroupCodeQuery } from '@ioc/graphql/queries.js'
import { withDefault } from '@nsf/core/Mapper.js'
import logger from '@nsf/my-account/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import myAccountServiceClient from '../clients/MyAccountServiceClient.js'
import {
  mapAdvancedAgreements,
  mapDataToUser,
  mapMissingPersonalDetailsToData,
  mapUserToData,
} from '../mappers/UserMapper.js'
import { removeTokens } from '../utils/auth.js'
import { withAuth } from '../utils/authDecorator.js'
import { mapAgreements } from '../mappers/AgreementsMapper.js'

const {
  rootConfig: {
    global: {
      crnEnabled, phoneCodeEnabled, skDicEnabled, vatIdEnabled,
    },
  },
} = useAppConfig()

export const getDefaultAddress = () => ({
  street: '',
  city: '',
  regionCode: '',
  zip: '',
  country: '',
  companyName: '',
  ...(crnEnabled && { companyRegistrationNumber: '' }),
  ...(skDicEnabled && { skDic: '' }),
  ...(vatIdEnabled && { vatId: '' }),
  phoneNumber: '',
})

export const getDefaultUser = () => ({
  clientId: 0,
  firstName: '',
  lastName: '',
  email: '',
  loginEmail: '',
  correspondenceEmail: '',
  ...(phoneCodeEnabled && { phoneCode: '' }),
  phoneNumber: '',
  birthDate: '',
  sex: '',
  addresses: [],
  registrationCode: '',
  agreements: [],
  additionalParams: {},
  billingAddress: getDefaultAddress(),
  deliveryAddress: getDefaultAddress(),
  registrationAddress: getDefaultAddress(),
})

export const updateCurrentUser = async (user) => {
  try {
    const payload = mapUserToData(user)
    const { data, status } = await withAuth(myAccountServiceClient.put)('/clients/me', payload)

    return {
      data,
      ok: String(status).startsWith('2'),
      status,
    }
  } catch (e) {
    logger.error('updateCurrentUser(%o) failed: %s', user, e.message)

    return {
      data: {},
      ok: false,
      status: 500,
    }
  }
}

export const updateCurrentUserAgreements = async (
  agreements,
) => {
  try {
    const payload = {
      agreements: mapAdvancedAgreements(agreements),
    }
    const { data, status } = await withAuth(myAccountServiceClient.put)(
      '/clients/me/agreements',
      payload,
    )

    return {
      data,
      ok: true,
      status,
    }
  } catch (e) {
    logger.error(
      'updateCurrentUserAgreements(%o) failed: %s',
      agreements,
      e.message,
    )

    return {
      data: {},
      ok: false,
      status: 500,
    }
  }
}

export const updateSorpCurrentUserAgreements = async (
  user,
) => {
  try {
    const payload = {
      agreements: mapAgreements(user),
    }

    const {
      data,
      status,
    } = await withAuth(myAccountServiceClient.put)(
      '/clients/me/agreements',
      payload,
    )

    return {
      data,
      ok: true,
      status,
    }
  } catch (e) {
    logger.error(
      'updateCurrentUserAgreements(%o) failed: %s',
      user,
      e.message,
    )

    return {
      data: {},
      ok: false,
      status: 500,
    }
  }
}

export const setMissingPersonalDetails = async (personalDetails) => {
  try {
    const payload = mapMissingPersonalDetailsToData(personalDetails)

    const { clientId } = personalDetails
    const { data, ok } = await myAccountServiceClient.put(
      `/clients/${clientId}/missing-personal-details`,
      payload,
    )

    // CEOS does not return correct error codes
    if (!ok && JSON.parse(JSON.parse(data?.detail)?.detail)?.err_code === 10) {
      return {
        ok: true,
        doi: data.doi,
        userAlreadyExists: true,
        status: 200,
      }
    }

    return {
      ok: true,
      doi: data.doi,
      status: 200,
    }
  } catch (e) {
    logger.error(
      'setMissingPersonalDetails(%s) failed: %s',
      personalDetails,
      e.message,
    )

    return {
      ok: false,
      doi: null,
      status: 500,
    }
  }
}

export const getMissingPersonalDetails = async (clientId) => {
  try {
    const { data, status } = await myAccountServiceClient.get(
      `/clients/${clientId}/missing-personal-details`,
    )

    return {
      ok: true,
      status,
      data,
    }
  } catch (e) {
    logger.error(
      'getMissingPersonalDetails(%s) failed: %s',
      clientId,
      e.message,
    )

    return {
      ok: false,
      status: 500,
    }
  }
}

export const removeUser = async () => {
  try {
    const { data, status } = await withAuth(myAccountServiceClient.delete)('/clients/me')

    return {
      ok: true,
      status,
      data,
    }
  } catch (e) {
    logger.error('removeUser failed: %s', e.message)

    return {
      ok: false,
      status: 500,
      data: null,
    }
  }
}

export const getCurrentUser = async () => {
  try {
    const { data, status, ok } = await withAuth(myAccountServiceClient.get)('/clients/me')

    const user = ok
      ? withDefault(getDefaultUser, await mapDataToUser(data))
      : getDefaultUser()

    if (!user.email) {
      removeTokens()
      return {
        ok: false,
        status: 403,
        user: getDefaultUser(),
      }
    }

    return {
      ok: true,
      status,
      user,
    }
  } catch (e) {
    logger.error('getCurrentUser() failed: %s', e.message)

    return {
      ok: false,
      status: 500,
      user: getDefaultUser(),
    }
  }
}

export const getUserById = async (clientId) => {
  try {
    const { data, status, ok } = await myAccountServiceClient.post('/clients/search', {
      clientId,
    })

    const user = ok
      ? withDefault(getDefaultUser, mapDataToUser(data))
      : getDefaultUser()

    return {
      ok,
      status,
      user,
    }
  } catch (e) {
    logger.error('getUserById(%s) failed: %s', clientId, e.message)

    return {
      ok: false,
      status: 500,
      user: getDefaultUser(),
    }
  }
}

export const getCustomerGroupId = async (accountType) => {
  try {
    if (!accountType) {
      return {
        customerGroupId: null,
      }
    }

    const response = await customerGetGroupCodeQuery()
      .bind({ accountType })
      .get()

    return {
      customerGroupId: response.customer_group.id || null,
    }
  } catch (e) {
    logger.error('getCustomerGroupId(%s) failed: %s', accountType, e.message)

    return {
      customerGroupId: null,
    }
  }
}

export const signIntoLoyalty = async (clientId) => {
  try {
    const {
      status,
      data,
    } = await withAuth(myAccountServiceClient.post)('/cards/assign-loyalty-card', { clientId })

    const errorCode = status !== 200 && data.detail && JSON.parse(JSON.parse(data.detail).detail).err_code

    // Most likely it won't happen, do we need it ?
    if (errorCode === 10) {
      return {
        ok: false,
        status,
        errMessage: 'Customer record does not exist in database',
      }
    }

    // Most likely it won't happen, do we need it ?
    if (errorCode === 11) {
      return {
        ok: false,
        status,
        errMessage: 'Client id is missing',
      }
    }

    if (errorCode === 64) {
      return {
        ok: false,
        status,
        errMessage: 'Customer already has activated card for own account',
      }
    }

    if (errorCode === 65) {
      return {
        ok: false,
        status,
        errMessage: 'Upgrade of the account could not be proceeded because of required data that are missing in the form',
      }
    }

    if (errorCode === 66) {
      return {
        ok: false,
        status,
        errMessage: 'Assigning loyalty card could not be proceeded now',
      }
    }

    return {
      ok: String(status).startsWith('2'),
      status: 200,
      errMessage: '',
    }
  } catch (e) {
    logger.error('signIntoLoyalty(%o) failed: %s', clientId, e.message)

    return {
      ok: false,
      status: 500,
      errMessage: 'Something goes wrong with saving account informations',
    }
  }
}
