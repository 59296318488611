import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

export default defineAppConfig({
  cityMaxLength: 150,
  companyNameMaxLength: 150,
  emailMaxLength: 150,
  firstNameMaxLength: 150,
  lastNameMaxLength: 150,
  streetMaxLength: 150,

  forbiddenWords: ['Apteka', 'Szpital', 'Przychodnia', 'Hurtownia'],

  cityAllowedCharacters: () => true,
  cityContainsLetter: (value) => /[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/.test(value),
  customerNotesAllowedCharacters: () => true,
  firstLastNameAllowedCharacters: (value) => /^[a-zA-Z-'\s\u00C0-\u024F\u1E00-\u1EFF]*$/.test(value),
  streetAllowedCharacters: () => true,

  crnValid: () => true,
  crnNumbersOnly: (value) => /^[0-9]+$/.test(value),
  crnLengthValid: (value) => value.length === 10,
  crnLength: 10,

  passwordForbiddenKeywords: ['haslo'],

  postCodeValid: (value) => /^[0-9]{2}-[0-9]{3}$/.test(value),

  streetValid: () => true,
  streetNumberValid: (value) => /^\b(?!.*?\s{2})[0-9a-z\s-/]+$/i.test(value),
  streetNumberContainsNumber: (value) => /\d/.test(value),

  validByFirstThreeDigits: () => true,
  validByForbiddenCode: () => true,

  vatIdLengthValid: (value) => value.length === 10,
  vatIdValid: (value) => /^[0-9]{10}$/.test(value),
  vatIdForbiddenWords: () => true,
  vatIdStartsWith: () => true,
  vatIdLength: 10,
})
