/* eslint-disable camelcase */
import { Mutation, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_FLOAT, REQUIRED_INT, REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('setOrderOtcForm')
  .where('input', {
    allergens: variable('allergens', REQUIRED_STRING),
    birthdate: variable('birthdate', REQUIRED_STRING),
    cart_id: variable('cartId', REQUIRED_STRING),
    consent: variable('consent', REQUIRED_INT),
    current_treatment: variable('treatment', REQUIRED_STRING),
    gender: variable('gender', REQUIRED_STRING),
    height: variable('height', REQUIRED_FLOAT),
    lactation: variable('lactancy', REQUIRED_INT),
    pregnancy_status: variable('pregnancy', REQUIRED_INT),
    weight: variable('weight', REQUIRED_FLOAT),
  })
  .fields([
    field('cart_id'),
  ])
