import logger from '@nsf/i18n/logger.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  appUrlInternal,
  public: {
    appUrl,
  },
} = useRuntimeConfig()

export default async (
  context,
  locale,
) => {
  let translation = {}
  try {
    const data = await fetch(`${appUrlInternal || appUrl}/i18n/${locale}`)
    translation = await data.json()
  } catch (e) {
    logger.error(e)
  }
  return translation
}
