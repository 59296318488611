/**
 * Documentation: https://mydrmax.atlassian.net/wiki/pages/resumedraft.action?draftId=1549041672
 */

import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'
import { useDrmaxPackage } from '@nsf/use/composables/useDrmaxPackage.js'

const { version } = useDrmaxPackage()

const pushEventVirtualPageview = async (pageType, metaTitle = '', data = {}) => {
  const event = {
    event: 'virtualPageview',
    pageType,
    pageUrl: document.location.href,
    pageTitle: metaTitle || document.title,
    releaseVersion: version,
    ...data, // Country specific data
  }

  usePushEvent(event)
}

/**
 * Used for article pages
 *
 * @param {Object} article
 * @param {String} metaTitle
 */
export const pushEventVirtualPageviewArticle = (article, metaTitle) => {
  const data = {}

  // CZ only - article
  if (article) {
    data.article = {
      id: article.id,
      category: article.category,
    }
  }

  pushEventVirtualPageview('article', metaTitle, data)
}

export const pushEventVirtualPageviewCategory = (metaTitle, data) => {
  pushEventVirtualPageview('category', metaTitle, data)
}

export const pushEventVirtualPageviewError = (metaTitle, code) => {
  pushEventVirtualPageview(`error_${code}`, metaTitle)
}

export const pushEventVirtualPageviewCart = (metaTitle) => {
  pushEventVirtualPageview('cart', metaTitle)
}

export const pushEventVirtualPageviewHomepage = (metaTitle) => {
  pushEventVirtualPageview('homepage', metaTitle)
}

export const pushEventVirtualPageviewOrderRecapitulation = (metaTitle) => {
  pushEventVirtualPageview('order_recapitulation', metaTitle)
}

export const pushEventVirtualPageviewOther = (metaTitle) => {
  pushEventVirtualPageview('other', metaTitle)
}

export const pushEventVirtualPageviewProductDetail = (metaTitle) => {
  pushEventVirtualPageview('product_detail', metaTitle)
}

export const pushEventVirtualPageviewSupercategory = (metaTitle, data) => {
  pushEventVirtualPageview('supercategory', metaTitle, data)
}

export const pushEventVirtualPageviewSearch = (metaTitle) => {
  pushEventVirtualPageview('search', metaTitle)
}

export const pushEventVirtualPageviewRegistration = (metaTitle) => {
  pushEventVirtualPageview('registration', metaTitle)
}

export const pushEventVirtualPageviewBrand = (metaTitle) => {
  pushEventVirtualPageview('brand', metaTitle)
}

export const pushEventVirtualPageviewLoyalty = (metaTitle) => {
  pushEventVirtualPageview('loyalty_club', metaTitle)
}

export const pushEventVirtualPageviewMyAccount = (metaTitle) => {
  pushEventVirtualPageview('my_account', metaTitle)
}

export const pushEventVirtualPageviewCmsPage = (metaTitle) => {
  pushEventVirtualPageview('cms_page', metaTitle)
}

export const pushEventVirtualPageviewPharmacy = (metaTitle) => {
  pushEventVirtualPageview('pharmacy', metaTitle)
}

export const pushEventVirtualPageviewDermocenter = (metaTitle) => {
  pushEventVirtualPageview('dermocenter', metaTitle)
}

export const pushEventVirtualPageviewPromo = () => {
  pushEventVirtualPageview('promo')
}
