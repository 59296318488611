/**
 * Documentation: https://mydrmax.atlassian.net/wiki/spaces/WAD/pages/1549140037/User+Data+notification
 */

import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'

/**
 * @param {Object} user
 * @param {Object} agreements
 */
export const pushEventUserData = (
  user, { agreements } = {},
) => {
  // Common

  let userObj
  if (user) {
    userObj = {
      id: user?.id || null,
      firstname: user?.firstname || null,
      surname: user?.surname || null,
      email: user?.email || null,
    }
  }
  const event = {
    event: 'userData',
    user: userObj,
  }

  // Agreements
  if (agreements) {
    event.agreements = {
      emailEshop: agreements?.emailEshop || null,
      postEshop: agreements?.postEshop || null,
      smsEshop: agreements?.smsEshop || null,
    }
  }

  usePushEvent(event)
}
