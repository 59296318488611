import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { field } from '@nsf/core/GraphQL.js'
import loyaltyProgram from './loyaltyProgram.js'
import cartAppliedSalesRules from './cartAppliedSalesRules.js'
import cartAppliedCoupons from './cartAppliedCoupons.js'
import cartAppliedGiftCards from './cartAppliedGiftCards.js'
import cartFreeShipping from './cartFreeShipping.js'
import cartItems from './cartItems.js'

const {
  checkout: {
    features: {
      couponsEnabled,
      freeShippingBoxEnabled,
      salesRulesEnabled,
    },
  },
  rootConfig: {
    global: {
      loyaltyCardEnabled,
      loyaltySavingsEnabled,
    },
  },
} = useAppConfig()

const {
  public: {
    giftCardsEnabled,
  },
} = useRuntimeConfig()

export default [
  ...salesRulesEnabled ? [cartAppliedSalesRules] : [],
  ...loyaltyCardEnabled && !loyaltySavingsEnabled ? [loyaltyProgram] : [],
  ...couponsEnabled ? [cartAppliedCoupons] : [],
  ...giftCardsEnabled ? [cartAppliedGiftCards] : [],
  ...freeShippingBoxEnabled ? [cartFreeShipping] : [],
  cartItems,
  field('order_type'),
]
