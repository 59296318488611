import {
  field, Query,
} from '@nsf/core/GraphQL.js'

export default () => Query.named('authors')
  .fields([
    field('count'),
    field(
      'items',
      [
        field('author_id'),
        field('name'),
        field('photo'),
        field('description'),
        field('url_key'),
        field(
          'posts',
          [field('count')],
        ),
      ],
    ),
  ])
  .shouldBeCached(true)
