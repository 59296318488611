import Vue from 'vue'

Vue.prototype.$pushQuery = function(query, hash = this.$route.hash) {
  this.$router.push({
    path: this.$route.path,
    query: {
      ...this.$route.query,
      ...query,
    },
    hash,
  })
}
