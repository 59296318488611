import logger from '@nsf/apotheka-web/logger.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import mapWithPlacesApiMapper from '../mappers/PlacesApiMapper.js'

const {
  public: {
    appUrl,
    pickupPlacesUrl,
  },
} = useRuntimeConfig()

const getPath = (endpoint) => {
  let path = pickupPlacesUrl
  if (path.indexOf('/') === 0) {
    path = appUrl + path
  }

  if (endpoint) {
    path += endpoint
  }

  return path
}

export const getPlaces = async () => {
  try {
    const path = getPath('/public/pickup-places')
    const response = await fetch(`${path}?pickupPlaceType=DRMAX_BOX&size=200`)
    if (response.status !== 200) {
      return []
    }

    const json = await response.json()

    return mapWithPlacesApiMapper(json)
  } catch (e) {
    logger.withTag('Places API').error(e)

    return []
  }
}

export const getPlace = async (guid) => {
  try {
    const path = getPath('/public/pickup-places')
    const response = await fetch(`${path}/${guid}`)
    if (response.status !== 200) {
      return null
    }

    const json = await response.json()
    if (json.errors) {
      return null
    }

    const places = mapWithPlacesApiMapper({
      data: [json],
    })

    return places[0]
  } catch (e) {
    logger.withTag('Places API').error(e)

    return null
  }
}
