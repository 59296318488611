export const roundUp = (num) => {
  if (!num || isNaN(num) || num < 0) {
    return 0
  }
  return Math.ceil(num)
}

export const round = (num) => {
  if (!num || isNaN(num) || num < 0) {
    return 0
  }
  return Math.round(num)
}
