import {
  field, Query,
} from '@nsf/core/GraphQL.js'
import { urlField } from '@nsf/articles/gql/fragments/urlField.js'

export default () => Query.named('catInfo')
  .fields([
    field('category_id'),
    field('name'),
    field(
      'children',
      [
        field('sort_order'),
        field('category_id'),
        field('name'),
        field('status'),
        urlField,
        field(
          'children',
          [
            field('sort_order'),
            field('category_id'),
            field('name'),
            field('status'),
            urlField,
          ],
        ),
      ],
    ),
  ])
  .shouldBeCached(true)
