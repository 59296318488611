import { field } from '@nsf/core/GraphQL.js'
import { urlField } from './urlField.js'
import { author } from './author.js'

export const postFields = [
  field('post_id'),
  field('post_thumbnail'),
  field('list_thumbnail'),
  field('published_at'),
  field('title'),
  urlField,
  author,
]
