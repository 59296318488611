import { withDefault } from '@nsf/core/Mapper.js'
import logger from '@nsf/my-account/logger.js'
import { withAuth } from '../utils/authDecorator.js'

import {
  mapDataToPurchase,
  mapDataToPurchaseHistoryList,
} from '../mappers/PurchaseHistoryMapper.js'
import myAccountServiceClient from '../clients/MyAccountServiceClient.js'
import customerServiceClient from '../clients/CustomerServiceClient.js'

const getDefaultPurchaseHistory = () => ({
  data: [],
})

const getDefaultPurchase = () => ({
  purchaseHistoryTransactionItems: [],
  addresses: [],
  billingAddress: null,
  deliveryAddress: null,
})

export const getPurchaseHistory = async () => {
  try {
    const {
      data, status,
    } = await withAuth(myAccountServiceClient.get)('/clients/me/purchase-history')

    const purchaseHistory = withDefault(
      getDefaultPurchaseHistory,
      mapDataToPurchaseHistoryList(data),
    )

    return {
      purchaseHistory,
      ok: true,
      status,
    }
  } catch (e) {
    logger.error(
      'getPurchaseHistory() failed: %s',
      e.message,
    )

    return {
      purchaseHistory: getDefaultPurchaseHistory(),
      ok: false,
      status: 500,
    }
  }
}

export const getPurchase = async (orderId) => {
  try {
    const {
      data, status,
    } = await withAuth(myAccountServiceClient.get)(`/clients/me/purchase-history/${orderId}`)

    const purchase = withDefault(
      getDefaultPurchase,
      mapDataToPurchase(data),
    )

    return {
      purchase,
      ok: true,
      status,
    }
  } catch (e) {
    logger.error(
      'getPurchase(%s) failed: %s',
      orderId,
      e.message,
    )

    return {
      purchase: getDefaultPurchase(),
      ok: false,
      status: 500,
    }
  }
}

export const getInvoiceListForOrder = async (orderId) => {
  try {
    const { data } = await withAuth(customerServiceClient.get)(`/order/invoices/${orderId}`)

    return {
      invoices: data.invoices || [],
      ok: true,
    }
  } catch (e) {
    logger.error(
      'getInvoiceListForOrder(%s) failed: %s',
      orderId,
      e.message,
    )

    return {
      invoices: [],
      ok: false,
    }
  }
}

export const getInvoicePdf = async (
  invoiceId,
) => {
  try {
    const { data } = await withAuth(customerServiceClient.get)(
      `/invoice/pdf/${invoiceId}`,
      { responseType: 'blob' },
    )

    return {
      data: data.invoice,
      ok: true,
    }
  } catch (e) {
    logger.error(
      'getInvoicePdf(%s) failed: %s',
      invoiceId,
      e.message,
    )

    return {
      data: null,
      ok: false,
    }
  }
}
