import { isObject, isArray } from '@nsf/core/Utils.js'

export class FetchError extends Error {
  constructor(message, response, { isTimeout = false } = {}) {
    super(message)
    this.response = response
    this._isTimeout = isTimeout
  }

  get isTimeout() {
    return this._isTimeout
  }

  get isFetchError() {
    return true
  }
}

export const fetchWithTimeout = (url, options, timeout = 5000, retry = false) => new Promise((resolve, reject) => {
  const controller = new AbortController()

  const timeoutTimer = setTimeout(() => {
    controller.abort()
  }, timeout)

  const { headers, body } = options

  const fetchOptions = {
    ...options,
    headers: headers['Content-Type'] || headers['content-type'] ? headers : { 'Content-Type': 'application/json', ...headers },
    body: (isObject(body) || isArray(body)) ? JSON.stringify(body) : body,
    signal: controller.signal,
  }

  fetch(url, fetchOptions)
    .then((response) => {
      resolve(response)
    })
    .catch(async(err) => {
      clearTimeout(timeoutTimer)

      if (err.name !== 'AbortError') {
        throw new FetchError('Request failed', err)
      }

      // Request was aborted by controller.abort()
      if (retry) {
        try {
          const response = await fetchWithTimeout(url, options, timeout, false)

          resolve(response)
        } catch (e) {
          reject(e)
        }
      } else {
        reject(new FetchError('Request failed', err, { isTimeout: true }))
      }
    })
})
