export default function () {
  return {
    serveUnsupportedOnNoUA: false,
    groups: {
      unsupported: {
        chrome: ['46', '<'],
        safari: ['10.2', '<'],
        firefox: ['52', '<'],
        samsung: ['10', '<'],
        edge: ['16', '<='],
        ie: ['11', '<='],
        opera: ['37', '<'],
      },
      partial: {
        chrome: ['71', '<'],
        safari: ['12', '<'],
        firefox: ['68', '<'],
        samsung: false,
        edge: ['17', '<'],
        ie: false,
        opera: ['57', '<'],
      },
    },
  }
}
