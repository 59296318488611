import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { field, Query, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import cartItemsModified from '../fragments/cartItemsModified.js'
import cartPrices from '../fragments/cartPrices.js'
import cartBoundProducts from '../fragments/cartBoundProducts.js'
import marketplaceQuoteTotals from '../fragments/marketplaceQuoteTotals.js'

const {
  checkout: {
    features: {
      boundProductsEnabled,
    },
  },
} = useAppConfig()

const {
  public: { marketplaceEnabled },
} = useRuntimeConfig()

export default () => Query.named('cart')
  .where('cart_id', variable('cartId', REQUIRED_STRING))
  .fields([
    ...cartItemsModified,
    cartPrices,
    ...boundProductsEnabled ? [cartBoundProducts] : [],

    field('shipping_addresses', [
      field('available_shipping_methods', [
        field('carrier_code'),
        field('method_code'),
        field('integration_id'),
      ]),
    ]),
    ...marketplaceEnabled ? [field('drmax_quote_type'), field('marketplace_quote', [marketplaceQuoteTotals])] : [],
  ])
