import { registerPageResolver } from '@nsf/core/PageResolver.js'
import { getArticleById } from '@nsf/articles/repositories/ArticleRepository.js'
import { getCategoryById } from '@nsf/articles/repositories/CategoryRepository.js'

export default function() {
  registerPageResolver(
    'AMASTY_BLOG_POST',
    getArticleById,
    () => import('@nsf/articles/dynamicPages/ArticlePage.vue'),
  )
  registerPageResolver(
    'AMASTY_BLOG_CATEGORY',
    getCategoryById,
    () => import('@nsf/articles/dynamicPages/CategoryPage.vue'),
  )
}
