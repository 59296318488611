export const getBreadcrumbs = (category) => [
  {
    link: '/',
    trans: 'global.breadcrumbs.Home',
  },

  ...(sortCategories(category.parent)
    .map((cat) => ({
      link: cat.urlKey,
      title: cat.title,
    }))
  ),

  {
    link: category.urlKey,
    title: category.title,
  },
]

export const hasSubcategories = (category) => !!category.children.length

export const sortCategories = (categories) => (categories
  ? [...categories].sort((
      cat1, cat2,
    ) => (cat1.level - cat2.level))
  : [])

export const getEnabledSubcategories = (children) => {
  if (!children?.length) {
    return []
  }
  return children.filter((cat) => cat.status)
}
