/* eslint-disable camelcase */
import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import cartShippingAddress from '../fragments/cartShippingAddress.js'
import cartAvailablePaymentMethods from '../fragments/cartAvailablePaymentMethods.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import cartAppliedPaymentRestrictions from '../fragments/cartAppliedPaymentRestrictions.js'
import cartPrices from '../fragments/cartPrices.js'

export default () => Mutation.named('setPostcodeOnShippingCartAddress')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    postcode: variable('postcode', REQUIRED_STRING),
  })
  .fields([
    field('cart', [
      cartShippingAddress,
      cartAvailablePaymentMethods,
      cartAppliedPaymentRestrictions,
      cartSelectedPaymentMethod,
      cartPrices,
    ]),
  ])
