import { Mutation, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('createSubscriber')
  .where('input', {
    subscriber_email: variable('email', REQUIRED_STRING),
    token: variable('token', REQUIRED_STRING),
  })
  .fields([
    field('status'),
  ])
