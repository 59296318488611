import { Query, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_INT } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Query.named('cmsPage')
  .where('id', variable('id', REQUIRED_INT))
  .fields([
    field('content'),
    field('meta_description'),
    field('meta_title'),
    field('meta_keywords'),
    field('title'),
    field('url_key'),
    field('custom_json'),
    field('seo_noindex'),
    field('seo_nofollow'),
    field('page_layout'),
  ])
  .shouldBeCached(true)
