import { Query, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_INT, REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Query.named('userOnlyContent')
  .where('content_ids', variable('content_ids', '[String]'))
  .where('entity_type', variable('entity_type', REQUIRED_STRING))
  .where('entity_id', variable('entity_id', REQUIRED_INT))
  .fields([
    field('content'),
    field('content_id'),
  ])
  .shouldBeCached(false)
  .preserveCookies(true)
