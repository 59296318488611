export const state = () => ({
  countMedicalProd: 0,
  countVetProd: 0,
})

export const mutations = {
  addProduct: (state, attributeSetType) => {
    state[attributeSetType]++
  },

  removeProduct: (state, attributeSetType) => {
    --state[attributeSetType]
  },
}

export const getters = {
  getMedicalProdCount: (state) => state.countMedicalProd,
  getVetProdCount: (state) => state.countVetProd,
}
