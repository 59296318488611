import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'

const pushEventLoginError = () => (
  clientId, errorType,
) => {
  let error = ''
  switch (errorType) {
    case 'missingConfirmation':
      error = 'Unverified account'
      break
    case 'unauthorized':
      error = 'Wrong password or username'
      break
    default:
      error = 'Other'
  }
  const event = {
    event: 'loginError',
    user: {
      id: clientId,
    },
    errorType: error,
  }
  usePushEvent(event)
}

export default pushEventLoginError
