import { getEnv } from '@nsf/use/utils/getEnv.js'
import { isObject } from '@nsf/use/utils/isObject.js'

export const overrideConfig = (obj, parentKey = '') => {
  for (const key in obj) {
    const subKey = parentKey ? `${parentKey}_${key}` : key
    const envValue = getEnv(subKey)
    if (isObject(obj[key])) {
      if (isObject(envValue)) {
        // eslint-disable-next-line no-param-reassign
        obj[key] = { ...obj[key], ...envValue }
      }
      overrideConfig(obj[key], subKey)
    } else {
      // eslint-disable-next-line no-param-reassign
      obj[key] = envValue ?? obj[key]
    }
  }
}

export default overrideConfig
