
export default {
  name: 'BackButton',
  props: {
    link: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    trans: {
      type: String,
      default: null,
    },
    defaultClass: {
      type: String,
      default: 'section mt-3 order-9',
    },
  },
}
