import { setState } from '@nsf/core/Store.js'

export const state = () => ({
  entityType: null,
  entityId: null,
  blogCategoryTipIcon: '',
  tocAnchors: [],
})

export const mutations = {
  setState,
}

export const getters = {
  // eslint-disable-next-line no-shadow
  entityType: (state) => state.entityType,
  // eslint-disable-next-line no-shadow
  entityId: (state) => state.entityId,
  // eslint-disable-next-line no-shadow
  blogCategoryTipIcon: (state) => state.blogCategoryTipIcon,
}
