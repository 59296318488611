const pickupInstructions = [
  {
    icon: 'pharmacy',
    fragments: [
      { text: 'Darmowy odbiór w' },
      {
        text: 'Aptece.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Jak tylko Twoja rezerwacja będzie gotowa do odbioru, skontaktujemy się z Tobą przez e-mail.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Możesz odebrać rezerwację' },
      {
        text: 'w ciągu 7 dni od momentu otrzymania powiadomienia o gotowości do odbioru.',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: 'Dostawa do apteky',
  reservationLabel: 'Rezerwacja w aptece',
  unavailablePickupLabel: 'Odbiór w aptece jest niedostępny',
  selectedPlaceLabel: 'Wybrana apteka',
  confirmButtonLabel: 'Wybierz aptekę',
  getTitle() {
    return this.title
  },
  // getSubtitle(place) {
  //   return [place.location.street, place.location.city].join(', ')
  // },
  getSubtitle() {
    return ''
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-pharmacy-enabled.svg',
    disabled: 'pin-pharmacy-disabled.svg',
    selected: 'pin-pharmacy-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/cac.svg',
}
