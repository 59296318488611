import { field } from '@nsf/core/GraphQL.js'

export default [
  field('grand_total', [
    field('currency'),
    field('value'),
  ]),
  field('subtotal_excluding_tax', [
    field('currency'),
    field('value'),
  ]),
  field('subtotal_including_tax', [
    field('currency'),
    field('value'),
  ]),
  field('subtotal_with_discount_excluding_tax', [
    field('currency'),
    field('value'),
  ]),
  field('subtotal_with_discount_including_tax', [
    field('currency'),
    field('value'),
  ]),
]
