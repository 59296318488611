import debounce from 'lodash/debounce.js'
import { setState } from '@nsf/core/Store.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import placementHeroLoader from '@nsf/product-relationship-loader-placement/loaders/productData/placementHeroLoader.js'
import { pushEventMegamenuView } from '@nsf/gtm/events/megamenu.js'
import { getMegamenu } from '../repositories/MegamenuRepository.js'

const {
  public: {
    appUrl,
    placementGenericEnabled,
  },
} = useRuntimeConfig()

// eslint-disable-next-line no-underscore-dangle
const _showItem = debounce(({ commit, state }, item) => {
  const oldId = state.activeItem?.id
  commit('setState', { activeItem: item })
  if (oldId !== item?.id) {
    pushEventMegamenuView(item.title)
  }
}, 250)

// eslint-disable-next-line no-underscore-dangle
const _hideItem = debounce(({ commit }) => {
  _showItem.cancel()
  commit('setState', { activeItem: null })
}, 50)

export const state = () => ({
  mainMenu: null,
  headerMenu: null,
  bottomMenu: null,
  footerMenu: null,
  activeMenu: null,
  activeItem: null,
  swallows: 0,
  storedMenus: {},
})

const getHeroProducts = async (categories) => {
  const categoriesConfig = categories.map((category) => ({
    config: {
      categoryId: category.entityId || category.id,
      categoryPath: category.link.replace(/^\//, ''),
      higherCategoryId: category.parentId,
      limit: 1,
    },
    ctx: this,
  }))
  const heroPromises = categoriesConfig.map((category) => placementHeroLoader.bind(category))
  const heroProducts = await Promise.all(heroPromises.map((promise) => promise()))
  categories.forEach((category, index) => {
    category.heroProduct = heroProducts[index].products.length ? heroProducts[index].products[0] : {}
  })
}

export const actions = {
  async fetch({ dispatch }) {
    const { menus } = await getMegamenu()

    if (menus) {
      if (menus.footerMenu) {
        dispatch('setFooterMenu', menus.footerMenu)
      }
      if (menus.bottomMenu) {
        dispatch('setBottomMenu', menus.bottomMenu)
      }
      if (menus.headerMenu) {
        // Grab Eshop item from header menu and set it as main menu to store
        let eshopItem = menus.headerMenu?.items.find((item) => item.link.match(`(^(${appUrl})(/)?$)|(^/$)`))
        if (eshopItem?.items?.length) {
          eshopItem.key = 'main-menu'
          if (placementGenericEnabled) {
            await getHeroProducts(eshopItem.items)
          }
          dispatch('setMainMenu', eshopItem)
        } else if (menus.mainMenu) {
          // CZ feature - items from mainMenu we also set as items of Eshop item from header menu
          eshopItem = menus.mainMenu
          if (placementGenericEnabled) {
            await getHeroProducts(eshopItem.items)
          }
          dispatch('setMainMenu', eshopItem)
        }

        dispatch('setHeaderMenu', menus.headerMenu)
      }
    }
  },

  showActiveItem(context, payload) {
    _hideItem.cancel()
    _showItem(context, payload)
  },

  // eslint-disable-next-line consistent-return,sort-keys
  hideActiveItem(context) {
    if (context.state.swallows) {
      return context.commit('removeSwallow')
    }

    _hideItem(context)
  },

  setMainMenu({ commit }, payload) {
    commit('setState', { mainMenu: payload.key })
    commit('addToStoredMenus', payload)
  },

  // eslint-disable-next-line sort-keys
  setHeaderMenu({ commit }, payload) {
    commit('setState', { headerMenu: payload.key })
    commit('addToStoredMenus', payload)
  },

  // eslint-disable-next-line sort-keys
  setBottomMenu({ commit }, payload) {
    commit('setState', { bottomMenu: payload.key })
    commit('addToStoredMenus', payload)
  },

  setFooterMenu({ commit }, payload) {
    commit('setState', { footerMenu: payload.key })
    commit('addToStoredMenus', payload)
  },

  showItem(context, payload) {
    _hideItem.cancel()
    _showItem(context, payload)
  },

  // eslint-disable-next-line consistent-return,sort-keys
  hideItem(context) {
    if (context.state.swallows) {
      return context.commit('removeSwallow')
    }

    _hideItem(context)
  },

  setActiveMenu({ commit }, menu) {
    commit('setActiveMenu', menu)
  },
}

export const mutations = {
  setState,

  // eslint-disable-next-line no-shadow
  addSwallow(state) {
    state.swallows += 1
  },

  // eslint-disable-next-line no-shadow
  removeSwallow(state) {
    state.swallows -= 1
  },

  // eslint-disable-next-line no-shadow
  addToStoredMenus(state, payload) {
    state.storedMenus[payload.key] = payload
  },

  // eslint-disable-next-line no-shadow
  setActiveMenu(state, payload) {
    state.activeMenu = payload
  },
}

export const getters = {
  // eslint-disable-next-line no-shadow
  mainMenu: (state) => state.storedMenus[state.mainMenu],
  // eslint-disable-next-line no-shadow
  headerMenu: (state) => state.storedMenus[state.headerMenu],
  // eslint-disable-next-line no-shadow
  bottomMenu: (state) => state.storedMenus[state.bottomMenu],
  // eslint-disable-next-line no-shadow
  footerMenu: (state) => state.storedMenus[state.footerMenu],
  // eslint-disable-next-line no-shadow
  activeItem: (state) => state.activeItem,
  // eslint-disable-next-line no-shadow
  storedMenus: (state) => state.storedMenus,
}
