import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import cartItemsModified from '../fragments/cartItemsModified.js'
import cartPrices from '../fragments/cartPrices.js'
import marketplaceQuoteTotals from '../fragments/marketplaceQuoteTotals.js'

const {
  public: { marketplaceEnabled },
} = useRuntimeConfig()

export default () => Mutation.named('mergeCarts')
  .where('source_cart_id', variable('sourceCartId', REQUIRED_STRING))
  .where('destination_cart_id', variable('destinationCartId', REQUIRED_STRING))
  .fields([
    ...cartItemsModified,
    cartPrices,
    ...marketplaceEnabled ? [field('drmax_quote_type'), field('marketplace_quote', [marketplaceQuoteTotals])] : [],
    field('messages', [
      field('text'),
      field('type'),
    ]),
  ])
