import logger from '@nsf/base/logger.js'
import { withDefault } from '@nsf/core/Mapper.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { configQuery } from '@ioc/graphql/queries.js'
import { mapDataToConfig } from '../mappers/ConfigMapper.js'

const {
  base: {
    announcementBarEnabled,
    generalStoreConfigEnabled,
  },
  rootConfig: {
    global: {
      loggedPriceInfoEnabled,
      stockStatusQuantityCheckEnabled,
      watchdog,
    },
  },
} = useAppConfig()

/**
 * default footer info when not fetched from graphql
 * @returns {{drmaxNsfYoutube: string, drmaxNsfVerify: string, drmaxNsfMobileApp_2: string, drmaxNsfMobileApp_1: string, drmaxNsfFacebook: string, transEmailIdentCustom1Email: string, generalStoreInformationPhone: string, drmaxNsfInstagram: string}}
 */
export const getDefaultConfig = () => (
  {
    ...(generalStoreConfigEnabled
      ? {
          generalStoreInformationPhone: '',
          drmaxNsfMobileApp1: '',
          drmaxNsfMobileApp2: '',
          drmaxNsfFacebook: '',
          drmaxNsfInstagram: '',
          drmaxNsfYoutube: '',
          drmaxNsfVerify: '',
          transEmailIdentCustom1Email: '',
          drmaxCaptchaSiteKey: '',
          drmaxNsfLoyalityRedirect: '#',
          drmaxNsfPharmacyJsonFile: '',
          drmaxNsfFaqJsonList: '',
          drmaxNsfSentryDateTill: '',
          drmaxPriceNotificationAllow: watchdog.priceAlertEnabled,
          drmaxBackInStockNotificationAllow: watchdog.stockAlertEnabled,
          drmaxNsfMyAccountEnable: true,
          drmaxNsfHeaderImage: null,
          drmaxNsfHeaderImageMobile: null,
          drmaxNsfImageBeforeFooter: null,
          drmaxNsfImageBeforeFooterMobile: null,
          drmaxNsfContentBabyCalendarWidget: '',
          drmaxWarning1st3pProduct: '',
          warningUnableToApplyGiftcard3p: '',
          cmsPageDataSource: '',
          paypalClientId: '',
          checkoutCommonMapDeliveryTimesEnabled: false,
        }
      : {}),
    ...(announcementBarEnabled
      ? {
          drmaxAnnouncementBarEnabled: false,
          drmaxAnnouncementBarType: '',
          drmaxAnnouncementBarContent: '',
          drmaxAnnouncementBarEnabledFrom: 0,
          drmaxAnnouncementBarEnabledTo: 0,
          drmaxAnnouncementBarExpiration: 0,
          drmaxAnnouncementBarCanClose: true,
        }
      : {}),
    ...(stockStatusQuantityCheckEnabled
      ? {
          shippingMethodStockMapping: [],
        }
      : {}),
    ...(loggedPriceInfoEnabled
      ? {
          drmaxPremiumPriceTogglerEnabled: false,
          drmaxSorpPromoPriceBoxEnabled: false,
        }
      : {}),
  })

/**
 * @returns {Promise<{footer: *}>}
 */
export const getConfig = async () => {
  try {
    const response = await configQuery().get()
    const config = withDefault(getDefaultConfig, mapDataToConfig(response))
    return { config }
  } catch (e) {
    logger.withTag('graphql').error('getConfig() failed: %s', e.message)

    return { config: getDefaultConfig() }
  }
}
