export function isCancelled(purchase) {
  return purchase.purchaseHistoryTransactionStatus === 'canceled'
}

export function isCancelledReturned(purchase) {
  return purchase.purchaseHistoryTransactionStatus === 'canceled_returned'
}

export function isCancelledExpired(purchase) {
  return purchase.purchaseHistoryTransactionStatus === 'canceled_expired'
}

export function isPickedUp(purchase) {
  return purchase.purchaseHistoryTransactionStatus === 'picked_up'
}

export function isCompleted(purchase) {
  return purchase.purchaseHistoryTransactionStatus === 'completed'
}

export function isDelivered(purchase) {
  return purchase.purchaseHistoryTransactionStatus === 'delivered' || !!purchase.orderDelivered
}

export function isInProgress(purchase) {
  return purchase.purchaseHistoryTransactionStatus === 'inprogress'
}

export function isReservation(purchase) {
  return purchase.isReservation
}

export function isOngoing(purchase) {
  return !isCompleted(purchase)
    && !isDelivered(purchase)
    && !isCancelled(purchase)
    && !isCancelledReturned(purchase)
    && !isCancelledExpired(purchase)
    && !isPickedUp(purchase)
}
