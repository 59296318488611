/* eslint-disable no-shadow */
import Vue from 'vue'

export const state = () => ({
  groups: {},
  groupOrder: [],
})

export const mutations = {
  removeGroup(state, { group }) {
    delete state.groups[group]
  },
  createGroup(state, { group }) {
    if (!state.groups[group]) {
      Vue.set(state.groups, group, {
        activeFilters: {},
        activeSpecialFilters: {},
        activeRangeFilters: {},
        activeSearch: undefined,
        initialFilters: {},
      })
      state.groupOrder.push(group)
      if (state.groupOrder.length > 1) {
        delete state.groups[state.groupOrder.shift()]
      }
    }
  },
  addSearchFilter(state, { group, search }) {
    if (!state.groups[group]) {
      this.commit('filtersV2/createGroup', { key: group })
    }
    Vue.set(state.groups[group], 'activeSearch', search)
  },
  removeSearchFilter(state, { group }) {
    if (!state.groups[group]) {
      return
    }
    Vue.delete(state.groups[group], 'activeSearch')
  },
  /**
   *
   * @param {string} group - identifier of the group listing (in case of multiple listings per page)
   * @param {string} type - type of filter (special, range, regular) - regular is default
   * @param {string} attribute - attribute name
   * @param {Object} option - attribute option object
   */
  addFilter(state, {
    group, type, attribute, option,
  }) {
    let filterType = 'activeFilters'
    switch (type) {
      case 'special':
        filterType = 'activeSpecialFilters'
        break
      case 'range':
        filterType = 'activeRangeFilters'
        break
      case 'initial':
        filterType = 'initialFilters'
        break
      default:
        break
    }
    if (!state.groups[group]) {
      this.commit('filtersV2/createGroup', { key: group })
    }
    if (!state.groups[group][filterType][attribute]) {
      Vue.set(state.groups[group], filterType, {
        ...state.groups[group][filterType],
        [attribute]: [],
      })
    }
    if (type === 'range') {
      if (!state.groups[group][filterType][attribute].some((f) => f.value === option.value)) {
        Vue.set(state.groups[group], filterType, {
          ...state.groups[group][filterType],
          [attribute]: [option],
        })
      }
      return
    }
    if (!state.groups[group][filterType][attribute].some((f) => f.value === option.value)) {
      Vue.set(state.groups[group], filterType, {
        ...state.groups[group][filterType],
        [attribute]: [...state.groups[group][filterType][attribute], option],
      })
    }
  },
  /**
   *
   * @param {string} group - identifier of the group listing (in case of multiple listings per page)
   * @param {string} type - type of filter (special, range, regular) - regular is default - make sure its the same as the one used in addFilter
   * @param {string} attribute - attribute name
   * @param {Object} option - attribute option object to remove
   */
  removeFilter(state, {
    group, type, attribute, option,
  }) {
    let filterType = 'activeFilters'
    switch (type) {
      case 'special':
        filterType = 'activeSpecialFilters'
        break
      case 'range':
        filterType = 'activeRangeFilters'
        break
      case 'initial':
        filterType = 'initialFilters'
        break
      default:
        break
    }
    if (!state.groups[group]) {
      return
    }
    if (type === 'range') {
      Vue.delete(state.groups[group][filterType], attribute)
    } else if (state.groups[group][filterType][attribute].some((f) => f.value === option.value)) {
      Vue.set(state.groups[group], filterType, {
        ...state.groups[group][filterType],
        [attribute]: state.groups[group][filterType][attribute]
          .filter((f) => f.value !== option.value),
      })
    }
    if (Object.keys(state.groups[group][filterType][attribute] ?? {}).length === 0) {
      Vue.delete(state.groups[group][filterType], attribute)
    }
  },
  revertGtmFilters(state, { group }) {
    if (!state.groups[group]) {
      return
    }
  },
  clearFilters(state, { group }) {
    if (!state.groups[group]) {
      return
    }
    Vue.set(state.groups, group, {
      activeFilters: {},
      activeSpecialFilters: {},
      activeRangeFilters: {},
      activeSearch: undefined,
      initialFilters: {},
    })
  },
}

export const getters = {
  getAllFilters: (state) => (group) => ({
    ...state.groups[group]?.activeFilters ?? {},
    ...state.groups[group]?.activeSpecialFilters ?? {},
    ...state.groups[group]?.activeRangeFilters ?? {},
  }),
  getRegularFilters: (state) => (group) => ({
    ...state.groups[group]?.activeFilters ?? {},
    ...state.groups[group]?.activeSpecialFilters ?? {},
  }),
  getActiveFilters: (state) => (group) => (
    state.groups[group]?.activeFilters ?? {}
  ),
  getActiveSpecialFilters: (state) => (group) => (
    state.groups[group]?.activeSpecialFilters ?? {}
  ),
  getActiveRangeFilters: (state) => (group) => (
    state.groups[group]?.activeRangeFilters ?? {}
  ),
  getActiveSearch: (state) => (group) => (
    state.groups[group]?.activeSearch ?? undefined
  ),
  hasAppliedFilters: (state, getters) => (group) => (
    !!Object.keys(getters.getInitialFilters(group)).length || !!getters.getActiveSearch(group)
  ),
  getGtmFilters: (state, getters) => (group) => {
    const filtersActive = getters.getAllFilters(group)
    const initialFilters = getters.getInitialFilters(group)

    const getDiff = function (obj1, obj2) {
      const diff = {}
      for (const key in obj1) {
        diff[key] = []
        if (key in obj2) {
          obj1[key].forEach((item) => {
            if (!obj2[key].some((i) => i.value === item.value)) {
              diff[key].push({ ...item })
            }
          })
        } else {
          obj1[key].forEach((item) => {
            diff[key].push({ ...item })
          })
        }
      }
      for (const key in diff) {
        if (diff[key].length === 0) {
          delete diff[key]
        }
      }
      return diff
    }
    const filtersAdded = getDiff(filtersActive, initialFilters)
    const filtersRemoved = getDiff(initialFilters, filtersActive)

    return {
      filtersActive,
      filtersAdded,
      filtersRemoved,
    }
  },
  getInitialFilters: (state) => (group) => (
    state.groups[group]?.initialFilters ?? {}
  ),
}
