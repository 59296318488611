import { mapDataToModel } from '@nsf/core/Mapper.js'

const mapDataToBillingAddressCompany = (data, translations = {}) => {
  const {
    company,
    companyRegistrationNumber,
    drmaxVatId,
    skDic,
    itPec = '',
    itSdi = '',
  } = mapDataToModel(data, { ...translations })

  return {
    companyName: company,
    companyRegistrationNumber,
    vatId: drmaxVatId,
    skDic,
    itPec,
    itSdi,
  }
}

export default mapDataToBillingAddressCompany
