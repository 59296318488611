import {
  isArray, isFunction, isNullish, isServer,
} from '@nsf/core/Utils.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const { public: { cookieDomain } } = useRuntimeConfig()

/**
 * Creates cookie. Or rewrites it if it exists.
 *
 * @param {string} name
 * @param {string} value
 * @param format
 * @param {number} maxAge
 * @param expires
 */
export const setCookie = (name, value, { format = String, maxAge = 2592000, expires } = {}) => {
  if (isServer()) {
    return
  }

  if (format === JSON) {
    format = JSON.stringify
  }

  document.cookie = expires
    ? document.cookie = `${name}=${format(value)}; expires=${expires}; domain=${cookieDomain}; path=/`
    : document.cookie = `${name}=${format(value)}; max-age=${maxAge}; domain=${cookieDomain}; path=/`
}

/**
 * Reads cookie value.
 *
 * @param {string} name
 * @param {*} format `String` or `JSON`.
 * @param {number|undefined} maxAge
 * @param {*} def
 * @returns {string|null|*}
 */
export const getCookie = (
  name,
  { format = String, maxAge = undefined, def = null } = {},
) => {
  if (isServer()) {
    return isFunction(def) ? def() : def
  }

  const cookies = document.cookie
  const cookieItem = getCookieItem(name, cookies, { format, def })
  if (cookieItem) {
    return cookieItem
  }

  if (!isNullish(def)) {
    const value = isFunction(def) ? def() : def
    setCookie(name, value, { format, maxAge })
    return value
  }

  return null
}

export const getCookieItem = (itemName = '', cookies = '', { format = String, def = null } = {}) => {
  const match = cookies.match(new RegExp(`(^| )${itemName}=([^;]+)`))

  if (isArray(match)) {
    if (format === JSON) {
      format = JSON.parse
    }
    return format(match[2])
  }

  return null
}

export const getCookiesByPrefix = (prefix, cookies = '') => {
  const PREFIX_WITH_SLASH = `${prefix}/`

  return cookies.split(';').filter((c) => c.trim().startsWith(PREFIX_WITH_SLASH))
    .reduce((obj, c) => {
      const [key, value] = c.trim().split('=')
      const keyWithoutPrefix = key.replace(PREFIX_WITH_SLASH, '')
      let parsedValue

      try {
        parsedValue = JSON.parse(value)
      } catch {
        parsedValue = value
      }

      return { ...obj, [keyWithoutPrefix]: parsedValue }
    }, {})
}

/**
 * Remove cookie by setting its expiration time to the past.
 *
 * @param {string} name
 */
export const delCookie = (name) => {
  if (isServer()) {
    return
  }

  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${cookieDomain};`
}
