/**
 * Documentation: https://mydrmax.atlassian.net/wiki/spaces/WAD/pages/2663972865/Cart+Id+notification
 */

import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'
import { getCartId } from '../utils/nuxtState.js'

/**
 * @param {String} cartId      Cart ID (optional, due to usege in add-to-cart/remove-from-cart)
 */
export const pushEventCartId = async(cartId) => {
  const event = {
    event: 'cartId',
    cartId: cartId || await getCartId(),
  }

  if (event.cartId) {
    usePushEvent(event)
  }
}
