/**
 * Module for integration of new Click&Collect (pickup-select v2)
 */
export const state = () => ({
  lastPreselectedPayment: null,
  lastPreselectedShipping: null,
  isShippingPreselectedFromFavorites: false,
  isPaymentPreselectedFromFavorites: false,
})

export const mutations = {
  setLastPreselectedShippingMethod: (state, shippingMethod) => {
    state.lastPreselectedShipping = shippingMethod
  },

  setLastPreselectedPaymentMethod: (state, paymentMethod) => {
    state.lastPreselectedPayment = paymentMethod
  },

  setShippingPreselectedFromFavorites: (state, value) => {
    state.isShippingPreselectedFromFavorites = value
  },

  setPaymentPreselectedFromFavorites: (state, value) => {
    state.isPaymentPreselectedFromFavorites = value
  },

  resetPaymentPreselection: (state) => {
    state.isPaymentPreselectedFromFavorites = false
    state.lastPreselectedPayment = null
  },

  resetShippingPreselection: (state) => {
    state.isShippingPreselectedFromFavorites = false
    state.lastPreselectedShipping = null
  },
}

export const getters = {
  getLastPreselectedShippingMethod: (state) => state.lastPreselectedShipping,
  getLastPreselectedPaymentMethod: (state) => state.lastPreselectedPayment,
  getIsShippingPreselectedFromFavorites: (state) => state.isShippingPreselectedFromFavorites,
  getIsPaymentPreselectedFromFavorites: (state) => state.isPaymentPreselectedFromFavorites,
}
