import svg4everybody from 'svg4everybody'
import { isServer, loadScript } from '@nsf/core/Utils.js'
import { hashCode } from '@nsf/utils/StringUtils.js'

if (typeof window !== 'undefined') {
  svg4everybody()
}

export default function() {
  if (isServer()) {
    return
  }

  const hash = hashCode(navigator.userAgent)

  loadScript(`/_polyfill.js?hash=${hash}`)

  if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function(callback, thisArg) {
      thisArg = thisArg || window
      for (let i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this)
      }
    }
  }
}
