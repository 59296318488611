import Vue from 'vue'

function touchX(event) {
  if (event.type.includes('mouse')) {
    return event.clientX
  }

  return event.touches[0].clientX
}

function touchY(event) {
  if (event.type.includes('mouse')) {
    return event.clientY
  }

  return event.touches[0].clientY
}

const vueTouchEvents = {
  install(Vue, options) {
    options = {
      disableClick: false,
      tapTolerance: 10,
      swipeTolerance: 100,
      longTapTimeInterval: 400,
      touchClass: '',
      ...options || {},
    }

    function touchStartEvent(event) {
      const $this = this.$touchObj
      const isTouchEvent = event.type.includes('touch')
      const isMouseEvent = event.type.includes('mouse')

      if (isTouchEvent) {
        $this.lastTouchStartTime = event.timeStamp
      }

      if (isMouseEvent && $this.lastTouchStartTime && event.timeStamp - $this.lastTouchStartTime < 350) {
        return
      }

      if ($this.touchStarted) {
        return
      }

      $this.touchStarted = true

      $this.touchMoved = false
      $this.swipeOutBounded = false

      $this.startX = touchX(event)
      $this.startY = touchY(event)

      $this.currentX = 0
      $this.currentY = 0

      $this.touchStartTime = event.timeStamp

      triggerEvent(event, this, 'start')
    }

    function touchMoveEvent(event) {
      const $this = this.$touchObj

      $this.currentX = touchX(event)
      $this.currentY = touchY(event)

      if (!$this.touchMoved) {
        const { tapTolerance } = options

        $this.touchMoved = Math.abs($this.startX - $this.currentX) > tapTolerance
          || Math.abs($this.startY - $this.currentY) > tapTolerance

        if ($this.touchMoved) {
          triggerEvent(event, this, 'moved')
        }
      } else if (!$this.swipeOutBounded) {
        const swipeOutBounded = options.swipeTolerance

        $this.swipeOutBounded = Math.abs($this.startY - $this.currentY) > swipeOutBounded
      }

      if ($this.touchMoved) {
        triggerEvent(event, this, 'moving')
      }
    }

    function touchCancelEvent() {
      const $this = this.$touchObj

      $this.touchStarted = $this.touchMoved = false
      $this.startX = $this.startY = 0
    }

    function touchEndEvent(event) {
      const $this = this.$touchObj
      const isTouchEvent = event.type.includes('touch')
      const isMouseEvent = event.type.includes('mouse')

      if (isTouchEvent) {
        $this.lastTouchEndTime = event.timeStamp
      }

      if (isMouseEvent && $this.lastTouchEndTime && event.timeStamp - $this.lastTouchEndTime < 350) {
        return
      }

      $this.touchStarted = false

      triggerEvent(event, this, 'end')

      if (!$this.touchMoved) {
        if ($this.callbacks.longtap && event.timeStamp - $this.touchStartTime > options.longTapTimeInterval) {
          event.preventDefault()
          triggerEvent(event, this, 'longtap')
        } else {
          triggerEvent(event, this, 'tap')
        }
      } else if (!$this.swipeOutBounded) {
        const swipeOutBounded = options.swipeTolerance; let direction

        if (Math.abs($this.startX - $this.currentX) < swipeOutBounded) {
          direction = $this.startY > $this.currentY ? 'top' : 'bottom'
        } else {
          direction = $this.startX > $this.currentX ? 'left' : 'right'
        }

        if ($this.callbacks[`swipe.${direction}`]) {
          triggerEvent(event, this, `swipe.${direction}`, direction)
        } else {
          triggerEvent(event, this, 'swipe', direction)
        }
      }
    }

    function triggerEvent(e, $el, eventType, param) {
      const $this = $el.$touchObj

      const callbacks = $this.callbacks[eventType] || []

      for (const binding of callbacks) {
        if (binding.modifiers.stop) {
          e.stopPropagation()
        }
        if (binding.modifiers.prevent) {
          e.preventDefault()
        }

        if (binding.modifiers.self && e.target !== e.currentTarget) {
          continue
        }

        if (typeof binding.value === 'function') {
          if (param) {
            binding.value(param, e)
          } else {
            binding.value(e)
          }
        }
      }
    }

    Vue.directive('touch', {
      bind($el, binding) {
        $el.$touchObj = $el.$touchObj || {
          callbacks: {},
          hasBindTouchEvents: false,
        }

        const eventType = binding.arg || 'tap'
        switch (eventType) {
          case 'swipe': {
            const _m = binding.modifiers
            if (_m.left || _m.right || _m.top || _m.bottom) {
              for (const i in binding.modifiers) {
                if (['left', 'right', 'top', 'bottom'].includes(i)) {
                  const _e = `swipe.${i}`
                  $el.$touchObj.callbacks[_e] = $el.$touchObj.callbacks[_e] || []
                  $el.$touchObj.callbacks[_e].push(binding)
                }
              }
            } else {
              $el.$touchObj.callbacks.swipe = $el.$touchObj.callbacks.swipe || []
              $el.$touchObj.callbacks.swipe.push(binding)
            }
            break
          }
          default:
            $el.$touchObj.callbacks[eventType] = $el.$touchObj.callbacks[eventType] || []
            $el.$touchObj.callbacks[eventType].push(binding)
        }

        if ($el.$touchObj.hasBindTouchEvents) {
          return
        }

        $el.addEventListener('touchstart', touchStartEvent, { passive: true })
        $el.addEventListener('touchmove', touchMoveEvent, { passive: true })
        $el.addEventListener('touchcancel', touchCancelEvent, { passive: true })
        $el.addEventListener('touchend', touchEndEvent, { passive: true })

        $el.$touchObj.hasBindTouchEvents = true
      },

      unbind($el) {
        $el.removeEventListener('touchstart', touchStartEvent)
        $el.removeEventListener('touchmove', touchMoveEvent)
        $el.removeEventListener('touchcancel', touchCancelEvent)
        $el.removeEventListener('touchend', touchEndEvent)

        delete $el.$touchObj
      },
    })
  },
}

export default function() {
  Vue.use(vueTouchEvents)
}
