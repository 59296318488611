import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: 'f96290670134010f27242c50a10560ce',
  analyticsApiKey: '21ac671f4b024f6a2278b1408bdd305c',
  ...config,
})
