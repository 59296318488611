import { Query, variable, field } from '@nsf/core/GraphQL.js'
import { INT, REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Query.named('banners')
  .where('image_type', variable('imageType', REQUIRED_STRING))
  .where('page_number', variable('page', INT, 1))
  .fields([
    field('background_color'),
    field('banner_id'),
    field('image_type'),
    field('description'),
    field('identifier'),
    field('image_name'),
    field('image_name_mobile'),
    field('name'),
    field('priority'),
    field('size'),
    field('url_link'),
  ])
  .shouldBeCached(true)
