import logger from '@nsf/base/logger.js'
import { withDefault } from '@nsf/core/Mapper.js'
import { cmsBlocksQuery } from '@ioc/graphql/queries.js'
import { mapDataToCmsBlock } from '../mappers/CmsBlockMapper.js'

export const getDefaultCmsBlock = () => ({
  content: {},
  identifier: '',
  title: '',
})

export const getCmsBlockByIdentifier = async (
  identifier, { isErrorMessageDisabled = true } = {},
) => {
  try {
    const { items } = await cmsBlocksQuery()
      .bind({ identifiers: identifier })
      .get()

    if (isErrorMessageDisabled && !items) {
      return { cmsBlock: getDefaultCmsBlock() }
    }

    const cmsBlock = withDefault(
      getDefaultCmsBlock,
      mapDataToCmsBlock(items[0]),
    )

    return { cmsBlock }
  } catch (e) {
    if (!isErrorMessageDisabled) {
      logger.error(
        'getCmsBlockByIdentifier(%o) failed: %s',
        identifier,
        e.message,
      )
    }

    return { cmsBlock: getDefaultCmsBlock() }
  }
}

export const getCmsBlocksByIdentifiers = async (
  identifiers, { isErrorMessageDisabled = true } = {},
) => {
  try {
    const { items } = await cmsBlocksQuery()
      .bind({ identifiers })
      .get()

    if (isErrorMessageDisabled && !items) {
      return { cmsBlocks: [] }
    }

    const cmsBlocks = withDefault(
      getDefaultCmsBlock,
      mapDataToCmsBlock(items),
    )

    return { cmsBlocks }
  } catch (e) {
    if (!isErrorMessageDisabled) {
      logger.error(
        'getCmsBlocksByIdentifiers(%o) failed: %s',
        identifiers,
        e.message,
      )
    }

    return { cmsBlocks: [] }
  }
}
