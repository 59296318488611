import { setStateByPath } from '../utils/StoreUtils.js'

export const state = () => ({
  arePickupIdsLoaded: false,
  areBoxesLoaded: false,
  arePharmaciesLoaded: false,
  preselectedPharmacyId: null,

  /**
   * List of places per carrier code.
   *
   * Properties of this object are carrier codes. Each property value is an array of objects, pickup places.
   * It is not just ids (as the title might indicate) but whole objects.
   *
   * @type {Object.<string, Object[]>}
   */
  pickupIds: {},
})

export const mutations = {
  setPickupIdsStateGeneric: setStateByPath,
}
