import {
  envInt, envBool,
} from '@nsf/utils/EnvUtils.js'

import googleMaps from './googleMaps.mjs'
import image from './image.mjs'
import megamenu from './megamenu.mjs'

export default {
  ...googleMaps,
  ...image,
  ...megamenu,

  allowCorsOrigins: (process.env.BASE_CORS_ALLOWED_ORIGINS || '*').split('|'),
  announcementBarEnabled: envBool(
    process.env.BASE_ANNOUNCEMENT_BAR_ENABLED,
    false,
  ),
  carousel: {
    carouselFullSliderMaxCount: envInt(process.env.BASE_CAROUSEL_FULL_SLIDER_MAX_COUNT, 24),
  },
  corsEnabled: process.env.BASE_CORS_ENABLED === 'true',
  currency: process.env.BASE_CURRENCY || 'CZK',
  currencyDecimal: process.env.BASE_CURRENCY_DECIMAL || ',',
  currencyLocale: {
    RON: 'ro-RO',
    CZK: 'cs-CZ',
    EUR: 'sk-SK',
    PLN: 'pl-PL',
  },
  currencySuffix: process.env.BASE_CURRENCY_SUFFIX || 'Kč',
  currencyThousands: process.env.BASE_CURRENCY_THOUSANDS || ' ',
  datePickerEndYear: envInt(
    process.env.BASE_DATEPICKER_END_YEAR,
    -1,
  ),
  datePickerStartYear: envInt(
    process.env.BASE_DATEPICKER_START_YEAR,
    -130,
  ),
  drmaxCaptchaSiteKeyEnabled: envBool(process.env.BASE_DRMAX_CAPTCHA_SITE_KEY_ENABLED, true),
  footerFlagsEnabled: envBool(
    process.env.BASE_FOOTER_FLAGS_ENABLED,
    false,
  ),
  generalStoreConfigEnabled: envBool(
    process.env.BASE_GENERAL_STORE_CONFIG_ENABLED,
    true,
  ),
  homepageTopProductsLimit: envInt(
    process.env.HOMEPAGE_TOP_PRODUCTS_LIMIT,
    10,
  ),
  keywordsOnHomepageOnly: envBool(
    process.env.APP_KEYWORDS_ON_HOMEPAGE_ONLY,
    false,
  ),
  paginationParamsToRemove: [
    'refresh',
    'utm',
    'fbclid',
    'y_source',
    'gbraid',
    'cjdata',
    'ppcbee',
    'network',
    'gclid',
    'amp',
    'amp;refresh',
    'fresh',
    'p',
    'rating',
    'refreshing',
  ],
  persooEnabled: envBool(
    process.env.PERSOO_ENABLED,
    false,
  ),
  searchVisibleOnScrollEnabled: envBool(
    process.env.BASE_SEARCH_VISIBLE_ON_SCROLL_ENABLED,
    false,
  ),
  twitterWidgetsUrl: 'https://platform.twitter.com/widgets.js',
  facebookSdkUrl: 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2',
}
