import { field } from '@nsf/core/GraphQL.js'
import marketplaceQuoteTotals from './marketplaceQuoteTotals.js'
import marketplaceQuoteAddress from './marketplaceQuoteAddress.js'

export default [
  field('drmax_quote_type'),
  field('marketplace_quote', [
    field('marketplace_payment_method_code'),
    field('marketplace_quote_address', [
      field('selected_shipping_method', [
        field('carrier_code'),
        field('method_code'),
      ]),
      field('available_shipping_methods', [
        field('carrier_code'),
        field('carrier_title'),
        field('method_title'),
        field('method_code'),
        field('simple_delivery_time'),
        field('simple_delivery_time_info'),
        field('integration_id'),
        field('icon'),
        field('price_incl_tax', [
          field('currency'),
          field('value'),
        ]),
      ]),
      field('available_payment_methods', [
        field('code'),
        field('title'),
        field('tooltip'),
      ]),
      marketplaceQuoteAddress,
    ]),
    marketplaceQuoteTotals,
  ]),
]
