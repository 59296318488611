import { getCountryData } from '@nsf/base/repositories/CountryRepository.js'
import { isNumeric } from '@nsf/core/Utils.js'

/**
 * This patch provides backward compatibility for addresses saved in older RO accounts (addresses having regionId stored as regionCode)
 */
export const withRegionCodesPatched = async (addresses) => {
  let regions = null

  for (const address of addresses) {
    if (isNumeric(address.regionCode)) {
      const regionId = parseInt(address.regionCode)
      regions = regions ?? (await getCountryData('RO'))?.availableRegions ?? []
      address.regionCode = regions.find((region) => region.id === regionId)?.value ?? ''
    }
  }

  return addresses
}
