import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  base: {
    robots: {
      content,
      disabledCrawling,
    },
  },
  rootConfig: {
    app: {
      noIndexParams,
    },
  },
} = useAppConfig()

const {
  public: {
    environmentName,
  },
} = useRuntimeConfig()

export const getSeoRobots = (routeQuery) => {
  if (disabledCrawling || routeQuery['ppcbee-adtext-variant']) {
    return content.other
  }

  const queryParams = Object.keys(routeQuery)
  const hasNoIndexParameters = (queryParams.length)
    ? queryParams.filter((param) => noIndexParams.includes(param)).length > 0
    : false

  if (hasNoIndexParameters) {
    return 'noindex, follow'
  }

  return content[environmentName]
    ?? content.other
}

export default {
  computed: {
    seoRobots() {
      return getSeoRobots(this.$route.query)
    },
  },
}
