import { registerPageResolver } from '@nsf/core/PageResolver.js'
import ProductLineBuilder from '@nsf/brands/builders/ProductLineBuilder.js'

export default function () {
  registerPageResolver(
    'PRODUCT_LINE',
    (id, url) => new ProductLineBuilder(id, url).build(),
    () => import('@nsf/brands/dynamicPages/ProductLineDetail.vue'),
  )
}
