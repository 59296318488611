/* eslint-disable no-shadow */
export const state = () => ({
  viewId: null,
})

export const mutations = {
  setViewId(state, viewId) {
    state.viewId = viewId
  },
}

export const getters = {
  viewId: (state) => state.viewId,
}
