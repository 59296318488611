import { isObject, isString } from '@nsf/core/Utils.js'
import { ensureArray } from '@nsf/utils/ArrayUtils.js'

const GLOBAL_NESTED_FILTERS = ['drmax_labels_aggregated']

export const queryRange = (query, range) => {
  ensureArray(range).forEach((r) => {
    const [field, ...rangeBorders] = r.split(',')
    const groupDataField = `drmax_product_group_data.${field}`
    if (rangeBorders.length !== 2) {
      return
    }

    const min = +rangeBorders[0]
    const max = +rangeBorders[1]

    if (!Number.isNaN(min) && !Number.isNaN(max)) {
      query.named(field, (q) => q.must((f) => f.should(
        (e) => e.inRange(field, min, max),
      )
        .should(
          (e) => e.inRange(groupDataField, min, max),
        )))
    } else if (!Number.isNaN(min) && Number.isNaN(max)) {
      query.named(field, (q) => q.must((f) => f.should(
        (e) => e.greaterThanEqual(field, min),
      )
        .should(
          (e) => e.greaterThanEqual(groupDataField, min),
        )))
    } else if (Number.isNaN(min) && !Number.isNaN(max)) {
      query.named(field, (q) => q.must((f) => f.should(
        (e) => e.lessThanEqual(field, max),
      )
        .should(
          (e) => e.lessThanEqual(groupDataField, max),
        )))
    }
  })
}

export const querySearch = (query, search, searchFields) => {
  if (isString(search)) {
    query.must((q) => {
      for (const field of ensureArray(searchFields)) {
        q.should((e) => e.match(field, search))
      }
    })
  }
}

const nestedFilter = (nestedField, values) => {
  const path = [...nestedField.split('.')]
  path.pop()

  return {
    nested: {
      path,
      query: {
        bool: {
          must: {
            terms: {
              [nestedField]: Array.isArray(values) ? values : [values],
            },
          },
        },
      },
    },
  }
}

export const queryFilter = (query, filter) => {
  ensureArray(filter).forEach((f) => {
    // eslint-disable-next-line prefer-const
    let [field, ...values] = f.split(',')

    field = GLOBAL_NESTED_FILTERS.includes(field) ? `${field}.option_value` : field

    const isNested = field.split('.').length > 1
    const groupDataField = `drmax_product_group_data.${field}`

    if (!isNested) {
      query.named(field, (q) => q.must((i) => i.should(
        (e) => e.whereIn(field, values),
      )
        .should(
          (e) => e.whereIn(groupDataField, values),
        )))
    } else {
      query.named(field, (queryNested) => {
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        queryNested._must = [nestedFilter(field, values)]
      })
    }
  })
}

export const queryWithout = (query, without, attribute = 'id') => {
  if (isObject(without)) {
    for (const [key, value] of Object.entries(without)) {
      query.named(key, (q) => q.whereNotIn(attribute, value))
    }
  }
}
