import { Query, field } from '@nsf/core/GraphQL.js'

export default () => Query.multi([
  field('menuItems').alias('footer')
    .where('group_id', 4)
    .fields([
      field('item_id'),
      field('link'),
      field('path'),
      field('sort_order'),
      field('title'),
    ]),
  field('menuItems').alias('bottom')
    .where('group_id', 5)
    .fields([
      field('item_id'),
      field('link'),
      field('path'),
      field('sort_order'),
      field('title'),
    ]),
]).shouldBeCached(true)
