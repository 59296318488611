import { Query, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_INT, REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import baseFields from '../fragments/baseFields.js'
import tags from '../fragments/tags.js'

export default () => Query.named('faqQuestionsByTag')
  .where('tag_name', variable('tag', REQUIRED_STRING))
  .where('page_number', variable('page', REQUIRED_INT))
  .fields([
    field('count'),
    field('items', [
      ...baseFields,
      tags,
    ]),
  ])
  .shouldBeCached(true)
