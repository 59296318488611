import logger from '@nsf/my-account/logger.js'
import customerServiceClient from '../clients/CustomerServiceClient.js'
import {
  mapDataToFavoritePaymentMethod,
  mapDataToFavoritePaymentMethods,
  mapDataToFavoriteShippingMethod,
  mapDataToFavoriteShippingMethods,
  mapFavoritePaymentMethodToData,
  mapFavoriteShippingMethodToData,
} from '../mappers/FavoriteMethodsMapper.js'

export const getFavoritePaymentMethods = async () => {
  try {
    const { ok, data } = await customerServiceClient.post(
      '/favourite/payment-methods',
      { active: true },
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
      paymentMethods: mapDataToFavoritePaymentMethods(data),
    }
  } catch (e) {
    logger.error('getFavoritePaymentMethods failed: %s', e.message)
    return {
      ok: false,
      paymentMethods: [],
    }
  }
}

export const getFavoriteShippingMethods = async () => {
  try {
    const { ok, data } = await customerServiceClient.post(
      '/favourite/shipping-methods',
      { active: true },
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
      shippingMethods: mapDataToFavoriteShippingMethods(data),
    }
  } catch (e) {
    logger.error('getFavoriteShippingMethods failed: %s', e.message)
    return {
      ok: false,
      shippingMethods: [],
    }
  }
}

export const setFavoritePaymentMethod = async (payload, clientId) => {
  try {
    const { ok } = await customerServiceClient.put(
      `/favourite/payment-method/${clientId}`,
      mapFavoritePaymentMethodToData(payload),
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
    }
  } catch (e) {
    logger.error('setFavoritePaymentMethod failed: %s', e.message)

    return {
      ok: false,
    }
  }
}

export const setFavoriteShippingMethod = async (payload, clientId) => {
  try {
    const { ok } = await customerServiceClient.put(
      `/favourite/shipping-method/${clientId}`,
      mapFavoriteShippingMethodToData(payload),
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
    }
  } catch (e) {
    logger.error('setFavoriteShippingMethod failed: %s', e.message)

    return {
      ok: false,
    }
  }
}

export const getFavoritePaymentMethod = async (clientId) => {
  try {
    const { ok, data } = await customerServiceClient.get(
      `/favourite/payment-method/${clientId}`,
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
      paymentMethod: mapDataToFavoritePaymentMethod(data?.payment),
    }
  } catch (e) {
    logger.error('getFavoritePaymentMethod failed: %s', e.message)

    return {
      ok: false,
      paymentMethod: null,
    }
  }
}

export const getFavoriteShippingMethod = async (clientId) => {
  try {
    const { ok, data } = await customerServiceClient.get(
    `/favourite/shipping-method/${clientId}`,
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
      shippingMethod: mapDataToFavoriteShippingMethod(data?.shipping),
    }
  } catch (e) {
    logger.error('getFavoriteShippingMethod failed: %s', e.message)

    return {
      ok: false,
      shippingMethod: null,
    }
  }
}
