/* eslint-disable camelcase */
import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import cartPrices from '../fragments/cartPrices.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import cartAvailableShippingMethods from '../fragments/cartShippingAddressesShippingMethodsOnly.js'
import cartItemsModified from '../fragments/cartItemsModified.js'
import marketplaceQuoteTotals from '../fragments/marketplaceQuoteTotals.js'

const {
  public: {
    marketplaceEnabled,
  },
} = useRuntimeConfig()

export default () => Mutation.named('setPaymentMethodOnCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    payment_method: {
      code: variable('method', REQUIRED_STRING),
    },
  })
  .fields([
    field('cart', [
      ...cartItemsModified,
      cartSelectedPaymentMethod,
      cartAvailableShippingMethods,
      cartPrices,
      ...marketplaceEnabled ? [field('drmax_quote_type'), field('marketplace_quote', [marketplaceQuoteTotals])] : [],
    ]),
  ])
