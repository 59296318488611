import { isArray, isObject } from './Utils.js'

export class RestClient {
  constructor(config = {}) {
    this.baseURL = config.baseURL || ''
    this.headers = {
      accept: 'application/json, text/plain, */*',
      ...config.headers,
    }
    this.credentials = config.credentials || 'include'

    // due to function decorators - to not loose context (this)
    this.get = this.get.bind(this)
    this.post = this.post.bind(this)
    this.put = this.put.bind(this)
    this.patch = this.patch.bind(this)
    this.delete = this.delete.bind(this)
    this.fetch = this.fetch.bind(this)
  }

  /**
   * @param {String} urlPathName
   * @param {String|Array|Object} params
   * @returns {Object}
   */
  _buildURL(urlPathName, params) {
    const query = params ? `?${new URLSearchParams(params)}` : ''
    return `${this.baseURL}${urlPathName}${query}`
  }

  /**
   * @param {Object} config
   * @returns {RequestInit}
   */
  _buildRequestConfig(config = {}) {
    const {
      headers, credentials, body: rawBody, ...otherConfig
    } = config
    const computedHeaders = {}
    let body = rawBody

    if (typeof FormData !== 'undefined' && !(rawBody instanceof FormData) && (isObject(rawBody) || isArray(rawBody))) {
      computedHeaders['Content-Type'] = 'application/json;charset=UTF-8'
      body = JSON.stringify(rawBody)
    }

    return {
      ...otherConfig,
      credentials: credentials || this.credentials,
      headers: {
        ...computedHeaders,
        ...this.headers,
        ...headers,
      },
      body,
    }
  }

  /**
   * @param {Response} response
   * @returns {Promise<Object|String>}
   */
  _parseResponseBody(response) {
    try {
      const contentType = response.headers.get('Content-Type')
      if (contentType) {
        if (contentType.includes('application/json')) {
          return response.json()
        }
        if (contentType.includes('multipart/form-data')) {
          return response.formData()
        }
        if (contentType.includes('application/pdf')) {
          return response.blob()
        }
      }
      return response.text()
    } catch {
      return null
    }
  }

  /**
   * @param {Response} response
   * @returns {Promise<Object>}
   */
  async mapResponse(response) {
    return {
      ok: response.ok,
      status: response.status,
      headers: response.headers,
      data: await this._parseResponseBody(response),
    }
  }

  /**
   * @param {String} urlPathName
   * @param {RequestInit} config
   * @param {String|Array|Object} config.params
   * @returns {Promise<Object>}
   */
  get(urlPathName, config = {}) {
    return this.fetch(urlPathName, {
      ...config,
      method: 'GET',
    })
  }

  /**
   * @param {String} urlPathName
   * @param {Object} payload
   * @param {RequestInit} config
   * @param {String|Array|Object} config.params
   * @returns {Promise<Object>}
   */
  post(urlPathName, payload, config = {}) {
    return this.fetch(urlPathName, {
      ...config,
      method: 'POST',
      body: payload,
    })
  }

  /**
   * @param {String} urlPathName
   * @param {Object} payload
   * @param {RequestInit} config
   * @param {String|Array|Object} config.params
   * @returns {Promise<Object>}
   */
  put(urlPathName, payload, config = {}) {
    return this.fetch(urlPathName, {
      ...config,
      method: 'PUT',
      body: payload,
    })
  }

  /**
   * @param {String} urlPathName
   * @param {Object} payload
   * @param {RequestInit} config
   * @param {String|Array|Object} config.params
   * @returns {Promise<Object>}
   */
  patch(urlPathName, payload, config = {}) {
    return this.fetch(urlPathName, {
      ...config,
      method: 'PATCH',
      body: payload,
    })
  }

  /**
   * @param {String} urlPathName
   * @param {RequestInit} config
   * @param {String|Array|Object} config.params
   * @returns {Promise<Object>}
   */
  delete(urlPathName, config = {}) {
    return this.fetch(urlPathName, {
      ...config,
      method: 'DELETE',
    })
  }

  /**
   * @param {String} urlPathName
   * @param {RequestInit} config extended fetch params
   * @param {String|Array|Object} config.params
   * @returns {Promise<Object>}
   */
  async fetch(urlPathName, config = {}) {
    const rc = this._buildRequestConfig(config)
    const response = await globalThis.fetch(this._buildURL(urlPathName, config.params), rc)
    return await this.mapResponse(response)
  }
}

export const createRestClient = (config) => new RestClient(config)

export default new RestClient()
