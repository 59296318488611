import { createRestClient } from '@nsf/core/RestClient.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  rootConfig: {
    global: {
      xTenant,
    },
  },
} = useAppConfig()

const {
  public: {
    reviewServiceUrl,
  },
} = useRuntimeConfig()

const headers = {
  'X-Tenant': xTenant,
  'Content-Type': 'application/json',
}

const reviewServiceClient = createRestClient({
  baseURL: reviewServiceUrl,
  headers,
  credentials: 'same-origin',
})

export default reviewServiceClient
