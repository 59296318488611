import { field } from '@nsf/core/GraphQL.js'

export default field('applied_gift_cards', [
  field('applied_balance', [
    field('value'),
    field('currency'),
  ]),
  field('code'),
  field('current_balance', [
    field('value'),
    field('currency'),
  ]),
  field('expiration_date'),
])
