import logger from '@nsf/base/logger.js'
import { withDefault } from '@nsf/core/Mapper.js'
import { cmsPageQuery } from '@ioc/graphql/queries.js'
import { sliceSlashFromUrl } from '@nsf/utils/UrlUtils.js'
import { urlResolverQuery } from '@nsf/core/gql/queries/urlResolver.js'
import { Exception } from '@nsf/core/exceptions/Exception.js'
import { mapDataToCmsPage } from '../mappers/CmsPageMapper.js'

export const getDefaultCmsPage = () => ({
  content: {},
  seo: {
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
  },
  title: '',
})

export const getCmsPageById = async (id) => {
  try {
    const response = await cmsPageQuery().bind({ id })
      .get()

    const cmsPage = withDefault(getDefaultCmsPage, mapDataToCmsPage(response))
    cmsPage.id = id

    if (cmsPage.errors?.length > 0 && !cmsPage.title) {
      throw new Exception('CMS page is disabled.')
    }

    return { cmsPage }
  } catch (e) {
    logger.withTag('graphql').error('getCmsPageById(%o) failed: %s', id, e.message)

    return {
      cmsPage: getDefaultCmsPage(),
      statusCode: 404,
    }
  }
}

export const getCmsPageByUrl = async (url) => {
  try {
    const cleanUrl = sliceSlashFromUrl(url)
    const urlResolverResponse = await urlResolverQuery.bind({ url: cleanUrl }).get()
    const { type, id } = urlResolverResponse

    if (type !== 'CMS_PAGE') {
      throw new Error(`Page with URL '${url}' is not a cms page`)
    }

    return await getCmsPageById(id)
  } catch (e) {
    logger.withTag('graphql').error('getCmsPageByUrl(%o) failed: %s', url, e.message)

    return {
      cmsPage: getDefaultCmsPage(),
      statusCode: 404,
    }
  }
}
