import { setState } from '@nsf/core/Store.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getUserLocation } from '../repositories/LocationRepository.js'

const {
  rootConfig: {
    global: {
      maps: {
        defaultLocation: {
          latitude,
          longitude,
        },
      },
    },
  },
  pickupSelect: {
    maps: {
      zoomLevels: {
        establishment,
        geolocation,
      },
    },
  },
} = useAppConfig()

export const state = () => ({
  // User location
  location: {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
  },
  // Override for auto-panning to selected place
  locationOverride: null,
  // Map bounds
  mapBounds: null,
  // Map zoom
  mapZoom: geolocation,
  // Override for auto-zooming on selected place
  mapZoomOverride: false,
  // True if user interaction is to be ignored
  ignoreUserInteraction: null,
})

export const getters = {
  location: (state) => state.location,
  locationOverride: (state) => state.locationOverride,
  locationWithOverride: (state) => state.locationOverride ?? state.location,
  mapBounds: (state) => state.mapBounds,
  mapZoom: (state) => (state.mapZoomOverride ? establishment : state.mapZoom),
}

export const actions = {
  initUserLocation: async({ commit }) => {
    const location = await getUserLocation()

    location && commit('setState', {
      mapZoom: geolocation,
      location,
    })
  },
}

export const mutations = {
  setState,

  setIgnoreUserInteraction: (state, payload) => {
    state.ignoreUserInteraction = payload
  },

  setLocation: (state, payload) => {
    state.location = payload
  },

  setMapBounds: (state, payload) => {
    state.mapBounds = payload
  },

  setMapZoom: (state, payload) => {
    state.mapZoom = payload
  },
}
