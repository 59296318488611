import { getRuntimeConfig } from '@nsf/use/utils/getRuntimeConfig.js'
import { useDeepFreeze } from '@nsf/use/utils/useDeepFreeze.js'

let runtimeConfig = null

if (!runtimeConfig) {
  runtimeConfig = { ...getRuntimeConfig() }
  runtimeConfig = useDeepFreeze(runtimeConfig)
}

export default runtimeConfig
