import { field, variable, Mutation } from '@nsf/core/GraphQL.js'
import { REQUIRED_INT, REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('stockAlertUnsubscribe')
  .where('input', {
    email: variable('userEmail', REQUIRED_STRING),
    product_id: variable('productId', REQUIRED_INT),
  })
  .fields([
    field('message'),
  ])
