import nuxtConfig from 'drmax/.nsf/nuxt.config.mjs'
import { overrideConfig } from '@nsf/use/utils/overrideConfig.js'

/**
 * ⚠️ Use this function only if you know what you are doing!!!
 * Otherwise, you will cause worse performance results!
 * It is recommended to load this function only when starting the application!
 * Inside modules, plugins and other application parts use useRuntimeConfig()
 * */
export const getRuntimeConfig = () => {
  const config = { ...nuxtConfig.runtimeConfig }
  overrideConfig(config)
  return config
}

export default getRuntimeConfig
