export const getDataFromNuxtStore = (getterName, nuxtGlobalObject = globalThis?.$nuxt) => nuxtGlobalObject?.context?.store?.getters?.[getterName]

// Returns cartId as string when user has something in the cart
export const getCartId = (getData = getDataFromNuxtStore) => {
  const INT_DELAY = 2000
  let INT_MAX_ATTEMPTS = 15

  return new Promise((resolve) => {
    function getCartId() {
      INT_MAX_ATTEMPTS--
      if (getData('checkout/cart/getIsInitialized')) {
        const cartId = getData('checkout/cart/getId')
        const cartItems = getData('checkout/cart/getItems')
        resolve((cartItems?.length && cartId) ? cartId : null)

        return
      }
      if (INT_MAX_ATTEMPTS === 0) {
        resolve(null)

        return
      }
      setTimeout(getCartId, INT_DELAY)
    }
    getCartId()
  })
}
