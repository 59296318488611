
import BackButton from '@nsf/base/components/BackButton.vue'
import HeadSEO from '@nsf/base/mixins/HeadSEO.js'
import Sentry from '@nsf/base/mixins/Sentry.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useCountryFlags } from '@nsf/use/composables/useCountryFlags.js'
import { pushEventVirtualPageviewError } from '@nsf/gtm/events/virtualPageview.js'
import WindowDimensionProvider from '@nsf/base/mixins/WindowDimensionProvider.js'

const {
  rootConfig: {
    seo: {
      title: seoTitle,
    },
  },
  base: {
    persooEnabled,
  },
} = useAppConfig()

const { isDrogeria } = useCountryFlags()

export default {
  name: 'BaseErrorLayout',

  components: {
    'PlacementViewProvider': () => import('@nsf/gtm/providers/PlacementViewProvider.js'),
    'v-back-button': BackButton,
    'v-product-relationship-not-found': () => import('@nsf/product-relationship/components/loaders/ProductRelationshipNotFound.vue'),
  },

  mixins: [
    HeadSEO,
    Sentry,
    WindowDimensionProvider,
  ],

  props: {
    error: {
      type: Object,
      default: null,
    },
  },

  computed: {
    statusCode() {
      return String(this.error?.statusCode || 404)
    },

    statusMessage() {
      // eslint-disable-next-line no-nested-ternary
      return this.statusCode === '404'
        ? this.$t('base.errorPage.Not Found')
        : this.$te(`base.errorPage.${this.error?.statusMessage}`)
          ? this.$t(`base.errorPage.${this.error?.statusMessage}`)
          : 'Internal Server Error'
    },

    seoTitle() {
      return [this.statusMessage, seoTitle].join(' | ')
    },

    imgSrc() {
      switch (this.statusCode) {
        case '404':
          return `/images/error-pages/404-page@500.${isDrogeria ? 'jpeg' : 'png'}`
        case '504':
          return '/images/error-pages/504-page@500.png'
        default:
          return '/images/error-pages/500-page@500.png'
      }
    },
  },

  created() {
    this.persooEnabled = persooEnabled
  },

  mounted() {
    pushEventVirtualPageviewError(this.seoTitle, this.statusCode)
  },
}
