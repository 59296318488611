import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'

export const pushEventUserLogin = (user) => {
  const event = {
    event: 'login',
    accountType: user?.accountType === 'E' ? 'limited_account' : 'full_account',
    user: {
      id: user?.clientId,
      firstname: user?.firstName,
      surname: user?.lastName,
      email: user?.email,
      phone: user?.phoneCode ? (user?.phoneCode + user?.phoneNumber) : user?.phoneNumber,
      birthDate: user?.birthDate,
      gender: user?.sex,
    },
    agreements: {
      emailEshop: !!user?.agreements.find(({ channel, topic, value }) => channel === 'EMAIL' && topic === 'ESHOP' && value === 'Y'),
      postEshop: !!user?.agreements.find(({ channel, topic, value }) => channel === 'POST' && topic === 'ESHOP' && value === 'Y'),
      smsEshop: !!user?.agreements.find(({ channel, topic, value }) => channel === 'SMS' && topic === 'ESHOP' && value === 'Y'),
    },
  }
  usePushEvent(event)
}

export const pushEventLoginError = async () => {
  const event = {
    event: 'loginError',
    errorType: 'Missing password',
  }
  usePushEvent(event)
}
