import dayjs from '../plugins/dayjsSetup.js'

export const localizeDate = (date) => {
  if (!date) {
    return {
      day: undefined,
      month: undefined,
      year: undefined,
    }
  }

  const dateObject = dayjs(date).tz()

  const month = dateObject.month() + 1
  const day = dateObject.date()
  const dayOfWeek = dateObject.day()

  return {
    day: day < 10 ? `0${day}` : day,
    month: month < 10 ? `0${month}` : month,
    year: dateObject.year(),
    dayOfWeek,
  }
}

export const toOpeningHours = (openingHours) => openingHours.map((openingHour) => {
  const {
    day, month, year, dayOfWeek,
  } = localizeDate(openingHour.date)

  const json = {
    date: `${year}-${month}-${day}`,
    day: dayOfWeek,
    open: null,
    close: null,
    openTimestamp: null,
    closeTimestamp: null,
    nonstop: openingHour.nonstop,
    closed: !openingHour.open,
  }

  if (openingHour.open) {
    const timestamps = []

    openingHour.openingHour
      .filter((element) => element.open)
      .forEach((element) => {
        timestamps.push(new Date(element.from).valueOf())
        timestamps.push(new Date(element.to).valueOf())
      })

    json.openTimestamp = Math.min(...timestamps)
    json.closeTimestamp = Math.max(...timestamps)

    json.open = dayjs(json.openTimestamp).tz()
      .format('H:mm')
    json.close = dayjs(json.closeTimestamp).tz()
      .format('H:mm')

    json.breaks = openingHour.openingHour
      .filter((element) => !element.open)
      .sort((a, b) => (a.from > b.from ? 1 : -1))
      .map((item) => ({
        from: dayjs(item.from).tz()
          .format('H:mm'),
        to: dayjs(item.to).tz()
          .format('H:mm'),
        note: item.note,
      }))

    if (json.breaks.length) {
      // Open intervals
      json.intervals = []
      let from = json.open
      json.breaks.forEach((b) => {
        json.intervals.push([from, b.from])
        from = b.to
      })
      json.intervals.push([from, json.close])
    }
  } else {
    json.open = false
  }

  return json
})
