import { field, variable, Mutation } from '@nsf/core/GraphQL.js'
import { BOOLEAN, REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('priceAlertUnsubscribeHash')
  .where('input', {
    hash: variable('hash', REQUIRED_STRING),
    getClientData: variable('getClientData', BOOLEAN),
  })
  .fields([
    field('message'),
    field('clientUid'),
    field('clientEmail'),
  ])
