import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import features from './features.mjs'
import maps from './maps.mjs'
import pharmacySelect from './pharmacySelect.mjs'

export default defineAppConfig({
  ...features,
  ...maps,
  ...pharmacySelect,
})
