import { POINT_TYPES } from '../utils/PlaceUtils.js'
import { toOpeningHours } from './DateTimeMappers.js'

/**
 * Used for Pharmacy API
 *
 * @param {Object[]} responseJson
 *
 * @returns {Object[]}
 */
export default function(responseJson) {
  if (!responseJson?.data) {
    return []
  }

  return responseJson.data
    .map((pharmacy) => ({
      additionalParams: pharmacy.additionalParams,
      callcentrumAvailable: false,
      city: pharmacy.location?.city || pharmacy.city,
      district: pharmacy.additionalParams?.district || null,
      hidden: pharmacy.additionalParams?.displayToWeb === false,
      pickupPlaceId: String(pharmacy.location?.id || pharmacy.id),
      pickupPlaceTitle: pharmacy.additionalParams?.pharmacyPublicNameWeb || pharmacy.pharmacyPublicName,
      region: pharmacy.location?.region || pharmacy.region,
      regionId: pharmacy.additionalParams?.regionId || null,
      street: pharmacy.location?.street || pharmacy.street,
      // eslint-disable-next-line max-len
      reservationAvailable: (pharmacy.otcReservation?.enabled && (pharmacy.otcReservation?.limits?.[0]?.enabled ?? true)) || false,
      limitReservation: pharmacy.otcReservation?.limits || null,
      reservationStatus: pharmacy.otcReservation?.enabled ? 'OK' : 'disabled',
      orderAvailable: (pharmacy.order?.enabled && (pharmacy.order?.limits?.[0]?.enabled ?? true)) || false,
      limitOrder: pharmacy.order?.limit || null,
      pharmacyStatus: pharmacy.order?.enabled || pharmacy.otcReservation?.enabled ? 'OPEN' : 'disabled',
      reservationRXAvailable: pharmacy.rxReservation?.enabled || false,
      limitRXReservation: pharmacy.rxReservation?.limits || null,
      reservationOTCAvailable: pharmacy.otcReservation?.enabled || false,
      limitOTCReservation: pharmacy.otcReservation?.limits || null,
      latitude: Number(pharmacy.location?.latitude || pharmacy.latitude),
      longitude: Number(pharmacy.location?.longitude || pharmacy.longitude),
      priority: 1,
      phone: pharmacy.phoneNumbers?.[0]?.number || null,
      note: pharmacy.note,
      openingDate: pharmacy.openingDate,
      closureDate: pharmacy.closureDate,
      pharmacistName: pharmacy.manager,
      pharmacistImageUrl: pharmacy.managerImage,
      pharmacistChiefName: null,
      pharmacistChiefImageUrl: null,
      pharmacistExpertName: pharmacy.manager,
      pharmacistExpertImageUrl: pharmacy.managerImage,
      pharmacyImageUrl: pharmacy.pharmacyImage,
      shoppingMall: pharmacy.location?.description || pharmacy.description,
      destinationId: pharmacy.additionalParams?.destinationId || null,
      htmlId: `pharmacy${pharmacy.location?.id || pharmacy.id}`,
      leadTimeReservationRX: pharmacy.rxReservation?.leadTime || null,
      leadTimeReservationOTC: pharmacy.otcReservation?.leadTime || null,
      leadTimeOrders: pharmacy.order?.leadTime || null,
      open: pharmacy.order?.enabled || pharmacy.otcReservation?.enabled || false,
      openHours: toOpeningHours(pharmacy.openingHours),
      // eslint-disable-next-line camelcase
      pharmacy_type: pharmacy.pharmacyType,
      title: pharmacy.additionalParams?.pharmacyPublicNameWeb || pharmacy.pharmacyPublicName,
      type: POINT_TYPES.PHARMACY,
      urlKey: pharmacy.urlKey,
      zip: pharmacy.location?.zipCode || pharmacy.zipCode,
    }))
}
