import { mapDataToModel } from '@nsf/core/Mapper.js'

export const mapPricesModel = (data, translations = {}) => mapDataToModel(data, { ...translations })

export const mapSellersPrices = (marketplaceQuote, quoteType) => {
  const { sellers: sourceSellersPrices } = marketplaceQuote?.marketplace_quote_totals ?? []

  if (sourceSellersPrices?.length) {
    const sellersPrices = sourceSellersPrices?.map((prices) => mapPricesModel(prices)) ?? []

    if (quoteType === 'mixed') {
      const drmaxPrices = {
        sellerId: null,
        prices: mapPricesModel(marketplaceQuote?.marketplace_quote_totals?.one_p_quote_prices ?? {}),
      }

      return [drmaxPrices, ...sellersPrices]
    }
    return [...sellersPrices]
  }
  return []
}
