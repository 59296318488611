import { setState } from '@nsf/core/Store.js'

export const state = () => ({
  /** Place objects. To be shown on a map. Created based on points (simpler objects). */
  places: [],
})

export const actions = {
  initPlaces: ({ commit }, { points }) => {
    commit('setState', { places: points })
  },
}

export const mutations = {
  setState,

  setPlaces: (state, payload) => {
    state.places = payload
  },

  clearPlaces: (state) => {
    state.places = []
  },
}
