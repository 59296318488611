import { setState } from '@nsf/core/Store.js'

export const state = () => ({
  resolverType: null,
  resolverId: null,
  resolverTitle: null,
})

export const mutations = {
  setState,
}

export const getters = {
  resolverType: (state) => state.resolverType,
  resolverId: (state) => state.resolverId,
  resolverTitle: (state) => state.resolverTitle,
}
