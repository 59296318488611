/* eslint-disable no-param-reassign */
import {
  getDefaultBillingMeta,
  getDefaultAddress,
  getDefaultCompany,
  getDefaultPersonalDetails,
  getDefaultContact,
} from '../utils/addressHelpers.js'
import { setStateByPath } from '../utils/StoreUtils.js'

export const state = () => ({
  address: getDefaultAddress(),
  billingAddressEntitySaved: false,
  company: getDefaultCompany(),
  contact: getDefaultContact(),
  email: '',
  emailSaved: false,
  enums: {
    regions: {},
  },
  meta: getDefaultBillingMeta(),
  personalDetails: getDefaultPersonalDetails(),
  savingBillingAddress: false,
  savingEmail: false,
})

export const mutations = {
  setBillingStateGeneric: setStateByPath,

  resetEmail: (moduleState) => {
    moduleState.email = ''
    moduleState.emailSaved = false
  },

  resetBillingState: (moduleState) => {
    moduleState.contact = getDefaultContact()
    moduleState.address = getDefaultAddress()
    moduleState.company = getDefaultCompany()
    moduleState.personalDetails = getDefaultPersonalDetails()
    moduleState.meta = getDefaultBillingMeta()
    moduleState.billingAddressEntitySaved = false
  },

  setCompany(moduleState, newCompany) {
    moduleState.company = {
      ...getDefaultCompany(),

      ...newCompany,
    }
  },
}
