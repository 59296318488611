import {
  field, Query,
} from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { tags } from '../fragments/tags.js'
import { postFields } from '../fragments/postFields.js'
import categoryFields from '../fragments/categoryFields.js'

const {
  articles: {
    readingTimeEnabled,
  },
} = useAppConfig()

export default () => Query.named('articleBlock')
  .fields([
    field('count'),
    field(
      'articles',
      [
        field('short_content'),
        ...postFields,
        ...readingTimeEnabled ? [field('reading_time_in_minutes')] : [],
        tags,
        field(
          'main_category',
          [
            ...categoryFields,
            field(
              'parent',
              [...categoryFields],
            ),
          ],
        ),
      ],
    ),
  ])
  .shouldBeCached(true)
