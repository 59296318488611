import { isNumeric, isString } from '@nsf/core/Utils.js'

/**
 * Returns hash code of a string
 *
 * @see https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 * @param {string} string
 */
export const hashCode = (string) => {
  let hash = 0

  if (!isString(string) || string.length === 0) {
    return hash
  }

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i)

    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + char
    // eslint-disable-next-line no-bitwise
    hash |= 0 // Convert to 32bit integer
  }

  return hash
}

/**
 * Cuts a string after word at given position.
 * @param text {string} The text to be shortened.
 * @param limit {number} Reference position to be cut at.
 */
export function getPreviewText(text, limit) {
  const suffix = '...'

  // eslint-disable-next-line no-param-reassign
  limit += suffix.length

  if (text && limit < text.length) {
    const reference = text.lastIndexOf(' ', limit)
    return text.substring(0, reference) + suffix
  }
  return text
}

export const normalizeDiacritics = (name) => name.normalize('NFD').replace(/[\u0300-\u036F]/g, '')

export const firstLine = (string) => {
  if (!isString(string)) {
    return ''
  }
  return string.split(/\n/)[0]
}

export const deleteCharacterAtPosition = (string, index) => {
  if (isString(string) && isNumeric(index) && index >= 0 && index < string.length) {
    return string.slice(0, index) + string.slice(index + 1)
  }
  return string
}

/**
 * Splits string by whitespace characters
 * @param string
 * @returns {Array[string]} array of tokens
 */
export const tokenized = (string) => string.split(/\s+/)

/**
 * Returns random UUID string
 * @returns {string} uuid string
 */
export const uuid = () => {
  if (typeof crypto !== 'undefined' && crypto.randomUUID) {
    return crypto.randomUUID()
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0
    // eslint-disable-next-line
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export const splitLastOccurrence = (str, substring) => {
  const lastIndex = str.lastIndexOf(substring)

  const before = str.slice(0, lastIndex)

  const after = str.slice(lastIndex + 1)

  return [before, after]
}

export const removeHtmlTags = (str) => str.replace(/<[^>]*>/g, '')
