/* eslint-disable camelcase */
import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import marketplaceQuoteTotals from '../fragments/marketplaceQuoteTotals.js'

export default () => Mutation.named('setMarketplacePaymentMethodOnCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    code: variable('method', REQUIRED_STRING),
  })
  .fields([
    field('cart', [
      field('drmax_quote_type'),
      field('marketplace_quote', [
        field('marketplace_payment_method_code'),
        marketplaceQuoteTotals,
      ]),
    ]),
  ])
