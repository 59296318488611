import _appConfig from 'drmax/.nsf/app.config.mjs'
import { useDeepFreeze } from '@nsf/use/utils/useDeepFreeze.js'

// Freezing of loaded app.config data
const appConfig = useDeepFreeze(_appConfig)

/**
 * Function that returns the current application configuration.
 *
 * Temporary function until migration on Nuxt bridge/3
 *
 * @returns {Object}
 * */
export const useAppConfig = () => appConfig

export default useAppConfig
