import { field } from '@nsf/core/GraphQL.js'
import cartPrices from './cartPrices.js'
import marketplaceCartPrices from './marketplaceCartPrices.js'

export default field('marketplace_quote_totals', [
  field('sellers', [
    field('seller_id'),
    cartPrices,
  ]),
  field('marketplace_quote_prices', [
    marketplaceCartPrices,
  ]),
  field('one_p_quote_prices', [
    marketplaceCartPrices,
  ]),
  field('payment_summary', [
    field('online_total', [
      field('value'),
    ]),
    field('offline_total', [
      field('value'),
    ]),
  ]),
])
