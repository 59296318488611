import { field } from '@nsf/core/GraphQL.js'

export default field('free_shipping', [
  field('enabled'),
  field('free_shipping_text'),
  field('progress_bar'),
  field('threshold', [
    field('value'),
    field('currency'),
  ]),
  field('remaining', [
    field('value'),
    field('currency'),
  ]),
])
