export const state = () => ({
  addedFilters: [],
  removedFilters: [],
  rangeFilters: {},
})

export const mutations = {
  addCatalogFilter(state, filter) {
    state.addedFilters.push(filter)
  },
  removeCatalogFilter(state, filter) {
    state.removedFilters.push(filter)
  },
  addRangeFilter(state, { key, value }) {
    state.rangeFilters[key] = value
  },
  removeRangeFilter(state, key) {
    delete state.rangeFilters[key]
  },
  clearCatalogFilters(state) {
    state.addedFilters = []
    state.removedFilters = []
  },
}
