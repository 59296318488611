import { Mutation, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_INT, REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('createFaqQuestion')
  .where('input', {
    category_id: variable('categoryId', REQUIRED_INT),
    full_text: variable('description', REQUIRED_STRING),
    email: variable('email', REQUIRED_STRING),
  })
  .fields([
    field('status'),
  ])
