export const state = () => ({
  pageType: null,
  detail: null,
  detailId: null,
  name: null,
  errorMessages: [],
})

export const mutations = {
  setGtmError(state, { message, type }) {
    state.errorMessages.push({ message, type })
  },
  clearGtmErrors(state) {
    state.errorMessages = []
  },
  setPageInfo(state, pageInfo) {
    state.pageType = pageInfo.pageType
    state.detail = pageInfo.detail
    state.detailId = pageInfo.detailId
    state.name = pageInfo.name
  },
}

export const actions = {
  setGtmError({ commit }, error) {
    commit('setGtmError', error)
  },
  clearGtmErrors({ commit }) {
    commit('clearGtmErrors')
  },
}

export const getters = {
  getPageType: (state) => state.pageType,
  getDetail: (state) => state.detail,
  getDetailId: (state) => state.detailId,
  getName: (state) => state.name,
  getErrorMessages: (state) => state.errorMessages,
}
