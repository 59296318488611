/**
 * Documentation: https://mydrmax.atlassian.net/wiki/spaces/WAD/pages/2673213507/MyAccount+-+Client+zone
 */

import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'

/**
 * @param {number} userId
 * @param {string} status
 */
export const pushEventUserPasswordChange = (
  userId, status,
) => {
  const event = {
    event: 'userPasswordChange',
    user: {
      id: userId,
    },
    status,
  }

  usePushEvent(event)
}

/**
 * @param {number} userId
 */
export const pushEventMyAccountDetail = (userId) => {
  const event = {
    event: 'myAccountDetail',
    user: {
      id: userId,
    },
  }

  usePushEvent(event)
}

/**
 * @param {Object} user
 * @param {Array} dataKeys
 */
export const pushEventUserDataChanged = (
  user, dataKeys,
) => {
  let userData = {
    firstname: user.firstName,
    surname: user.lastName,
    email: user.email,
    phone: user.phoneNumber,
    birthDate: user.birthDate,
    gender: user.sex,
  }

  if (user.weight) {
    userData.weight = user.weight
  }

  if (user.height) {
    userData.height = user.height
  }

  const event = {
    event: 'userDataChanged',
    user: {
      id: user.clientId,
    },
    changeData: dataKeys,
    userData,
  }

  usePushEvent(event)
}

/**
 * @param {number} userId
 * @param {Array} dataKeys
 * @param {Object} addressData
 */
export const pushEventUserAddressChanged = (
  userId, dataKeys, addressData, addressType,
) => {
  const event = {
    event: 'userAddressChanged',
    user: {
      id: userId,
    },
    changeData: dataKeys,
    addressType,
    userData: {
      city: addressData.city,
      street: addressData.street,
      streetNumber: addressData.streetNumber,
      zipCode: addressData.zip,
    },
  }

  usePushEvent(event)
}
