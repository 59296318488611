import logger from '@nsf/apotheka-web/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import mapWithPharmacyApiMapper from '../mappers/PharmacyApiMapper.js'

const {
  apothekaWeb: {
    pharmacySelect: {
      usePharmacyApi,
    },
  },
} = useAppConfig()

const {
  public: {
    appUrl,
    pickupSelectPharmacyApiUrl,
  },
} = useRuntimeConfig()

const getPath = (endpoint) => {
  let path = pickupSelectPharmacyApiUrl
  if (path.indexOf('/') === 0) {
    path = appUrl + path
  }

  if (endpoint) {
    path += endpoint
  }

  return path
}

/**
 * Fetch JSON with pharmacy data specified by pharmacy slug (urlKey)
 *
 * @param {String} urlKey - pharmacy slug
 *
 * @returns {Promise<Object|null>}
 */
export const getPharmacy = async (urlKey) => {
  try {
    let pharmacy = null

    if (usePharmacyApi) {
      const path = getPath('/pharmacies')
      const response = await fetch(`${path}/${urlKey}`)
      if (response.status !== 200) {
        return null
      }

      const json = await response.json()

      const pharmacies = mapWithPharmacyApiMapper({
        data: [json],
      })

      pharmacy = pharmacies.length > 0 && pharmacies.shift()
    }

    return pharmacy
  } catch (e) {
    logger.error(
      'getPharmacy() failed: %s',
      e.message,
    )

    return null
  }
}

/**
 * Fetch JSON with all pharmacies data
 *
 * @param {String} queryParams - filters for pharmacy query
 *
 * @returns {Promise<Object[]>}
 */
export const getPharmacies = async (queryParams = '') => {
  try {
    let pharmacies = []

    if (usePharmacyApi) {
      const path = getPath('/pharmacies')

      // Download data
      const response = await fetch(path)
      if (response.status !== 200) {
        return []
      }

      const json = await response.json()

      // Normalize pharmacies
      pharmacies = mapWithPharmacyApiMapper(json, queryParams === 'onlyDermatologyConsulting=true')

      // Initial sort - by city name (alphabetically), then by location (from SE to NW)
      pharmacies.sort((
        a, b,
      ) => {
        if (a.city === b.city) {
          return (a.latitude + a.longitude < b.latitude + b.longitude) ? -1 : 0
        }
        return a.city < b.city ? -1 : 1
      })
    }

    return pharmacies
  } catch (e) {
    logger.error(
      'getPharmacies() failed: %s',
      e.message,
    )

    return []
  }
}

/**
 * Fetch JSON with all pharmacies data
 *
 * @param {Object} options with queryParams -> filters for pharmacy query
 *
 * @returns {Object} with pharmacies
 */
export const getPharmaciesSimple = async ({ queryParams = '' } = {}) => {
  try {
    const pharmacies = await getPharmacies(queryParams)
    return { pharmacies }
  } catch (e) {
    logger.error(
      'getPharmaciesSimple failed: %s',
      e.message,
    )

    return { pharmacies: [] }
  }
}
