import {
  withLeadingSlash,
  withoutTrailingSlash,
} from 'ufo'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import Robots from './Robots.js'

const {
  base: {
    keywordsOnHomepageOnly,
  },
  rootConfig: {
    app: {
      noIndexParams,
      marketingParams,
    },
    global: {
      langIso,
    },
    seo: {
      image: seoImage,
    },
  },
} = useAppConfig()

const {
  public: {
    appUrl,
  },
} = useRuntimeConfig()

export const getCanonicalQueryParams = (route) => {
  const params = []
  const { query } = route
  const queryParams = Object.keys(query)

  if (query.page) {
    params.push(`page=${query.page}`)
  }

  const noIndexParameters = queryParams.filter((param) => noIndexParams.includes(param))
  const marketingParameters = queryParams.filter((param) => marketingParams.includes(param) || /^utm/.test(param))

  if (noIndexParameters.length) {
    const appendParams = (paramList) => {
      paramList.forEach((param) => {
        const encodedParam = param.replace(/;/g, '%3B')
        params.push(`${encodedParam}=${query[param]}`)
      })
    }

    appendParams(noIndexParameters)
    appendParams(marketingParameters)
  }

  params.sort((a, b) => {
    const aKey = a.split('=')[0]
    const bKey = b.split('=')[0]
    return queryParams.indexOf(aKey) - queryParams.indexOf(bKey)
  })

  return params
}

export const getCanonicalUrl = (route, baseUrl) => {
  let canonicalUrl = baseUrl ?? `${appUrl}${route.path}`
  const canonicalQueryParams = getCanonicalQueryParams(route)
  if (canonicalQueryParams.length) {
    canonicalUrl += `?${canonicalQueryParams.join('&')}`
  }
  return canonicalUrl
}

export default {
  mixins: [Robots],

  computed: {
    seo() {
      return {
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
        structuredData: null,
      }
    },

    seoTitle() {
      return this.seoMetaTitle
    },

    seoSiteName() {
      return '' || this.$t('global.Default_meta_site_name')
    },

    seoMobileWebAppTitle() {
      return '' || this.$t('global.Default_meta_title')
    },

    seoCanonical() {
      return appUrl + this.$route.path
    },

    seoMetaTitle() {
      return this.seo?.metaTitle || this.$t('global.Default_meta_title')
    },

    seoMetaDescription() {
      return this.seo?.metaDescription || this.$t('global.Default_meta_description')
    },

    seoMetaKeywords() {
      if (!keywordsOnHomepageOnly || (keywordsOnHomepageOnly && this.cmsPage?.urlKey === 'home')) {
        return this.seo?.metaKeywords || this.$t('global.Default_meta_keywords')
      }
      return ''
    },

    ogImage() {
      return `${withoutTrailingSlash(appUrl)}${withLeadingSlash(seoImage)}`
    },
    productCanonicalUrl() {
      return this.product?.drmaxProductCanonicalUrl ? withoutTrailingSlash(appUrl) + withLeadingSlash(this.product.drmaxProductCanonicalUrl) : ''
    },
  },

  head() {
    const meta = [
      { name: 'description', content: this.seoMetaDescription, hid: 'description' },
      { property: 'og:description', content: this.seoMetaDescription, hid: 'og:description' },
      { property: 'og:url', content: this.seoCanonical, hid: 'og:url' },
      { property: 'og:site_name', content: this.seoSiteName, hid: 'og:site_name' },
      { property: 'apple-mobile-web-app-title', content: this.seoMobileWebAppTitle, hid: 'apple-mobile-web-app-title' },
      { property: 'og:title', content: this.seoTitle, hid: 'og:title' },
      { name: 'keywords', content: this.seoMetaKeywords, hid: 'keywords' },
    ]

    meta.push({
      name: 'robots',
      content: this.seoRobots,
      hid: 'robots',
    })

    if (this.ogImage) {
      meta.push({
        property: 'og:image',
        content: this.ogImage,
        hid: 'og:image',
      })
    }

    return {
      title: this.seoTitle,
      meta,
      link: [{
        rel: 'canonical',
        href: this.productCanonicalUrl || getCanonicalUrl(this.$route, this.seoCanonical),
        hid: 'canonical',
      }],
      ...(this.seo.structuredData && {
        __dangerouslyDisableSanitizersByTagID: {
          'ldjson-schema': ['innerHTML'],
        },
        script: [{
          hid: 'ldjson-schema',
          innerHTML: JSON.stringify(this.structuredData),
          type: 'application/ld+json',
        }],
      }),
      htmlAttrs: {
        lang: langIso,
      },
    }
  },
  methods: {
    paginateMetaTitle(metaTitle) {
      let richMetaTitle = metaTitle
      const queryPage = parseInt(this.$route.query.page) || 0
      const titleParts = metaTitle.split('|')

      if (titleParts.length === 1) {
        richMetaTitle = queryPage ? `${metaTitle} - ${this.$t('catalog.pagination.page count', { count: queryPage })}` : metaTitle
      } else {
        richMetaTitle = titleParts.reduce((accumulator, part, index) => {
          let acc = accumulator
          if (index === titleParts.length - 1 && queryPage) {
            acc += `- ${this.$t('catalog.pagination.page count', { count: queryPage })}`
            return acc
          }
          acc += part
          return acc
        }, '')
      }
      return this.appendMetaTitleSuffix(richMetaTitle)
    },

    appendMetaTitleSuffix(title) {
      return `${title.trim()} | ${this.$t('catalog.page suffix')}`
    },
  },
}
