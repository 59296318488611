import {
  field, Query, variable,
} from '@nsf/core/GraphQL.js'
import { REQUIRED_INT } from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { postFields } from '../fragments/postFields.js'

const {
  articles: {
    authorDescriptionLongEnabled,
    authorPostsEnabled,
  },
} = useAppConfig()

export default () => Query.named('author')
  .fields([
    field('author_id'),
    ...authorDescriptionLongEnabled ? [field('description_long')] : [],
    field('name'),
    field('description'),
    field('photo'),
    field('url_key'),
    field('meta_description'),
    field('meta_tags'),
    field('meta_title'),
    field('author_page_visible'),
    ...authorPostsEnabled
      ? [
          field(
            'posts',
            [
              field('count'),
              field(
                'items',
                [
                  ...postFields,
                  field('short_content'),
                ],
              ),
            ],
          )
            .where(
              'page',
              variable(
                'page',
                REQUIRED_INT,
              ),
            ),
        ]
      : [],
  ])
  .shouldBeCached(true)
