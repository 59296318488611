import logger from '@nsf/brands/logger.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { mapDataToBrand } from '@nsf/brands/mappers/BrandMapper.js'

export const getBrandByProductLineId = async (id) => {
  try {
    const brand = await Query.brands()
      .where('product_line.id', id)
      .first()

    return {
      brand: mapDataToBrand(brand),
    }
  } catch (e) {
    logger.withTag('catalog')
      .error('getBrandByProductLineId(%o) failed: %s', id, e.message)

    return {
      brand: {},
    }
  }
}
