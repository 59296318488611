import { setState } from '@nsf/core/Store.js'
import { getRating } from '../repositories/RatingRepository.js'

export const state = () => ({
  rating: null,
})

export const actions = {
  async fetch({ commit }) {
    const rating = await getRating()
    commit('setState', { rating })
  },
}

export const mutations = {
  setState,
}

export const getters = {
  // eslint-disable-next-line no-shadow
  rating: (state) => state.rating,
}
