export default `<!DOCTYPE html>
<html lang="pl" class="no-js">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimal-ui, viewport-fit=cover" />
    <link rel="stylesheet" media="all" href="/css/global.min.css" id="global-styles" />
    <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
    <link rel="manifest" href="/favicons/site.webmanifest" />
    <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#288c00" />
    <link rel="shortcut icon" href="/favicons/favicon.ico" />
    <meta name="msapplication-TileColor" content="#288c00" />
    <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
    <meta name="theme-color" content="#288c00" />
    <title>Dr. Max eshop - Nieobsługiwana przeglądarka</title>
    <meta content="noindex,nofollow" name="robots" />
    <meta name="description" content="popis vaseho webu" />
  </head>
  <body>
    <div class="page" id="js-page">
      <main class="page__content">
        <div class="container">
          <section class="error-page error-page--500">
            <img src="/images/error-pages/500-page@1000.png" alt="" />
            <h1>Zaktualizuj przeglądarkę</h1>
            <p class="m-0">
              Witryna Dr.Max jest zbudowana w nowoczesnych technologiach, które zapewniają większe bezpieczeństwo zakupów, szybkość,
              komfort i lepsze wrażenia użytkownika.
            </p>
            <p>
				      Niestety używasz bardzo starej przeglądarki, która nie jest już obsługiwana.
            </p>
            <p>
              Intensywnie pracujemy nad wsparciem dla <strong>Internet Explorer 11</strong>.
              Przepraszamy za niedogodności.
            </p>
            <p class="text-success">
              <strong>
                Aby kontynuować, zainstaluj dowolną z przeglądarek, które są bezpłatne i dostępne po polsku.
              </strong>
            </p>
            <div id="pc" class="d-none">
              <a class="btn btn-outline-primary" href="https://www.google.com/chrome/">
                Chrome
              </a>
              <a class="btn btn-outline-primary" href="https://www.mozilla.org/pl/firefox/new/">
                Firefox
              </a>
            </div>
            <div id="mobile" class="d-none">
              <a
                class="btn btn-outline-primary"
                href="https://play.google.com/store/apps/details?id=com.android.chrome"
              >
                Chrome
              </a>
              <a
                class="btn btn-outline-primary"
                href="https://play.google.com/store/apps/details?id=org.mozilla.firefox"
              >
                Firefox
              </a>
            </div>
          </section>
        </div>
      </main>
    </div>

    <script type="text/javascript">
      (function(window, document) {
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)
        ) {
          document.getElementById('mobile').className = 'd-block'
        } else {
          document.getElementById('pc').className = 'd-block'
        }
      })(window, document)
    </script>
  </body>
</html>
`