import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  myAccount: {
    features: {
      myAccountSorpCardCodeToOtpModeEnabled,
    },
  },
} = useAppConfig()

export const mapAgreements = (data) => {
  const agreements = []
  if (data.agreements?.ppdAgreement) {
    pushIntoAgreements(agreements, 'EMAIL', 'ESHOP', 'N')
    pushIntoAgreements(agreements, 'SMS', 'ESHOP', 'N')
  }
  if (data.agreements?.mktAgreement) {
    pushIntoAgreements(agreements, 'EMAIL', 'ESHOP', 'Y')
    pushIntoAgreements(agreements, 'SMS', 'ESHOP', 'N')
  }
  if (myAccountSorpCardCodeToOtpModeEnabled) {
    pushIntoAgreements(agreements, '3RD', 'CLIENT', data.agreements?.thirdPartyMarketingAgreement ? 'Y' : 'N')
  }
  if (data.agreements?.loyaltyAgreement) {
    pushIntoAgreements(agreements, 'EMAIL', 'ESHOP', 'Y')
    pushIntoAgreements(agreements, 'SMS', 'ESHOP', 'Y')
    pushIntoAgreements(agreements, 'EMAIL', 'CP', 'Y')
    pushIntoAgreements(agreements, 'SMS', 'CP', 'Y')
    pushIntoAgreements(agreements, 'POST', 'CP', 'Y')
    if (myAccountSorpCardCodeToOtpModeEnabled) {
      pushIntoAgreements(agreements, '3RD', 'CLIENT', data.agreements?.thirdPartyMarketingAgreement ? 'Y' : 'N')
      pushIntoAgreements(agreements, 'EBLOK', 'CLIENT', data.agreements?.eblokAgreement ? 'Y' : 'N')
    }
  }
  return agreements
}

const generateAgreement = (channel, topic, value) => ({ channel, topic, value })

function pushIntoAgreements(agreements, channel, topic, value) {
  const agreement = agreements.find(
    (agreementItem) => agreementItem.channel === channel && agreementItem.topic === topic,
  )
  if (agreement) {
    agreement.value = value
  } else {
    agreements.push(generateAgreement(channel, topic, value))
  }
}
