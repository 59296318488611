import { isArray } from './Utils.js'

// available storages [localStorage, sessionStorage]

export const get = (key, defaultValue = null, storage = 'localStorage') => {
  if (typeof window === 'undefined') {
    return defaultValue
  }

  const item = window[storage].getItem(key)

  if (item === null || item === 'undefined') {
    return defaultValue
  }

  return JSON.parse(item) ?? defaultValue
}

export const set = (key, value, storage = 'localStorage') => {
  if (typeof window === 'undefined') {
    return
  }

  window[storage].setItem(key, JSON.stringify(value))
}

export const remove = (key, storage = 'localStorage') => {
  if (typeof window === 'undefined') {
    return
  }

  window[storage].removeItem(key)
}

export function getMultiple(domain, fields, storage = 'localStorage') {
  const response = {}

  fields.forEach((field) => {
    if (isArray(field)) {
      const [name, value] = field

      response[name] = get(`${domain}/${name}`, value, storage)
    } else {
      response[field] = get(`${domain}/${field}`, '', storage)
    }
  })

  return response
}
