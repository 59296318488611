import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  tags: {
    tagPageEnabled,
  },
} = useAppConfig()

export const tagPage = tagPageEnabled ? [field('page_is_enabled')] : []
