import { field, variable, Mutation } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('stockAlertUnsubscribeAll')
  .where('input', {
    email: variable('userEmail', REQUIRED_STRING),
  })
  .fields([
    field('message'),
  ])
