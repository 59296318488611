export const listingRequiredFields = [
  'adv_price_is_reservation_allowed',
  'attribute_set_id',
  'attribute_set_label',
  'backorders',
  'brand_name',
  'category',
  'category_ids',
  'drmax_abcd',
  'drmax_bound_products_price',
  'drmax_crossdock_product',
  'drmax_is_marketplace_product',
  'drmax_marketplace_seller_id',
  'drmax_marketplace_seller_name',
  'drmax_marketplace_master_category',
  'drmax_label_discount_amount',
  'drmax_label_discount_percent',
  'drmax_label_discount_type',
  'drmax_labels_aggregated',
  'drmax_main_category_name',
  'drmax_main_category_path',
  'drmax_main_category_url_path',
  'drmax_pim_status',
  'drmax_product_group_data',
  'drmax_product_variant_image',
  'drmax_show_in_listing',
  'drmax_stock_availability_text',
  'drmax_stock_availability_text_value',
  'drmax_stock_promo_legal',
  'drmax_stock_salable_qty',
  'drmax_teaser_description',
  'drmax_title_row1',
  'drmax_title_row2',
  'drmax_manufacter',
  'drmax_otc',
  'drmax_lowest_price_30',
  'drmax_lowest_price_30_manual',
  'special_from_date',
  'special_from_date_manual',
  'final_price',
  'final_price_logged',
  'final_price_limited',
  'final_price_full',
  'final_price_excl_vat',
  'final_price_excl_vat_logged',
  'final_price_excl_vat_limited',
  'final_price_excl_vat_full',
  'id',
  'image_label',
  'max_sale_qty',
  'name',
  'product_id',
  'rating',
  'regular_price',
  'regular_price_excl_vat',
  'sales_rules',
  'short_description',
  'sku',
  'stock',
  'thumbnail',
  'thumbnail_label',
  'url_path',
  'url_key',
  'custom_options',
  'drmax_rx',
]

export const listingRequiredFieldsProductVariant = [
  'backorders',
  'custom_options',
  'drmax_pim_status',
  'drmax_product_variant_image',
  'drmax_show_in_listing',
  'drmax_stock_salable_qty',
  'final_price',
  'id',
  'image_label',
  'max_sale_qty',
  'name',
  'product_id',
  'rating',
  'regular_price',
  'short_description',
  'sku',
  'thumbnail',
  'url_path',
]

export const omitProductDetailFields = [
  'drmax_product_line_slug',
  'drmax_size_height',
  'drmax_size_length',
  'drmax_size_width',
  'small_image',
  'small_image_label',
]

export const searchRequiredFields = [
  'id',
  'category',
  'stock',
  'thumbnail',
  'drmax_title_row1',
  'drmax_title_row2',
  'brand_name',
  'final_price',
  'final_price_logged',
  'final_price_limited',
  'final_price_full',
  'regular_price',
  'drmax_abcd',
  'regular_price_excl_vat',
  'drmax_pim_status',
  'url_path',
  'backorders',
  'drmax_main_category_name',
  'drmax_main_category_path',
  'drmax_main_category_url_path',
  'drmax_stock_availability_text_value',
  'drmax_stock_salable_qty',
  'drmax_lowest_price_30',
  'special_from_date',
  'drmax_otc',
  'custom_options',
  'drmax_rx',
]
