import { getReviewsByProductId } from '@nsf/catalog/repositories/ReviewRepository.js'
import { getReviews } from '@nsf/catalog/repositories/ReviewServiceRepository.js'
import { setReviewServicePayload } from '@nsf/catalog/mappers/ReviewMapper.js'
import { setState } from '@nsf/core/Store.js'

export const state = () => ({
  reviewData: {
    sku: null,
    total: 0,
    paginationFrom: 0,
    reviews: [],
  },
})

export const mutations = {
  setState,

  addSkuReviews(stateObj, sku) {
    // eslint-disable-next-line no-param-reassign
    stateObj.reviewData.sku = sku
  },

  setReviews(stateObj, reviews) {
    // eslint-disable-next-line no-param-reassign
    stateObj.reviewData.reviews = reviews
  },

  addReviews(stateObj, reviews) {
    stateObj.reviewData.reviews.push(...reviews)
  },
}

export const actions = {

  async loadReviews({ commit, state: stateObj }, {
    id, sku, paginationFrom, sort, range, searchKeyword, params, add,
  }) {
    commit('addSkuReviews', sku)

    const queryParams = {
      from: paginationFrom || 0,
      sort,
      match: searchKeyword,
      range,
      ...params,
    }

    if (this.$config.public.reviewServiceEnabled) {
      const payload = setReviewServicePayload(
        sku,
        paginationFrom,
        sort,
        range,
      )

      const { reviews: allReviews } = await getReviews(payload)

      commit(add ? 'addReviews' : 'setReviews', allReviews)

      return
    }

    const { total, reviews } = await getReviewsByProductId(id, queryParams)

    commit('setState', {
      reviewData: {
        sku,
        reviews: add ? [...stateObj.reviewData.reviews, ...reviews] : reviews,
        total,
        paginationFrom,
      },
    })
  },
}
