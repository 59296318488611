import logger from '@nsf/i18n/logger.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  public: {
    environmentName,
  },
} = useRuntimeConfig()

export default (ctx) => {
  try {
    ctx.app.i18n.missing = (locale, key) => {
      if (environmentName !== 'live' && environmentName !== 'local') {
        logger.warn(`Missing translation for key "${key}" in locale "${locale}"!`)
      }
    }
  } catch (e) {
    logger.error(`Missing i18n... Cannot initialize "missing" handler!`)
  }
}
