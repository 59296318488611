import { field, Query, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import drmaxPickupIds from '../fragments/drmaxPickupIds.js'

export default () => Query.named('cart')
  .where('cart_id', variable('cartId', REQUIRED_STRING))
  .fields([
    field('shipping_addresses', [
      field('available_shipping_methods', [
        field('carrier_code'),
        field('method_code'),
        field('pickup_place_types'),
        drmaxPickupIds,
      ]),
    ]),
  ])
