import { mapDataToModel } from '@nsf/core/Mapper.js'
import { extract } from '@nsf/core/Utils.js'
import { mapDataToArticle } from './ArticleMapper.js'
import { getEnabledSubcategories } from '../models/Category.js'
import { mapDataToPharmacist } from './PharmacistMapper.js'

export const mapDataToCategory = (
  data, translations = {}, seoSideEffects = false,
) => {
  const model = mapDataToModel(
    data,
    {
      category_id: [
        'id',
        Number,
      ],
      name: 'title',
      drmax_category_title: 'altTitle',
      posts: mapDataToArticle,
      level: Number,
      url_key: String,
      post_quantity: [
        'postsCount',
        Number,
      ],
      children: mapDataToCategory,
      parent: mapDataToCategory,
      parent_id: Number,
      status: Number,
      relative_url: [
        'urlKey',
        String,
      ],
      icon: String,
      block_content: [
        'content',
        JSON.parse,
      ],
      display_mode: Number,
      menu_root: mapDataToMenu,
      theme_class: String,
      sort_order: Number,
      ...translations,
    },
  )

  // seo side effects used only for category page
  if (model.id && seoSideEffects) {
    model.metaTitle = model.metaTitle || model.title
    model.seo = extract(
      model,
      'metaTitle',
      'metaDescription',
    )
  }

  if (model.children && model.children.length) {
    model.children = getEnabledSubcategories(model.children)
  }

  return model
}

export const mapDataToMenu = (
  data, translations = {},
) => mapDataToModel(
  data,
  {
    menu_id: Number,
    group_id: Number,
    uses_menu: Boolean,
    parent_ids: [
      'parentIds',
      (ar) => ar,
    ],
    ...translations,
  },
)

export const mapDataToBlogCatlistCategory = (
  data, translations = {},
) => mapDataToModel(
  data,
  {
    category_id: [
      'id',
      Number,
    ],
    title: String,
    name: [
      'title',
      String,
    ],
    relative_url: [
      'urlKey',
      String,
    ],
    pharmacists: mapDataToPharmacist,
    ...translations,
  },
)
