import { slugify } from '@nsf/core/Utils.js'
import { normalizeSunday } from '../utils/DateUtils.js'

/**
 * Used for Apotheka API in CZ
 *
 * @param {Object[]} responseJson
 *
 * @returns {Object[]}
 *
 * @deprecated Will be removed when we stop using Apotheka API.
 */
export default function(responseJson) {
  if (!responseJson?.data) {
    return []
  }

  return responseJson.data
    .map((pharmacy) => pharmacy.attributes)
    .map((pharmacy) => ({
      ...pharmacy,
      pickupPlaceId: String(pharmacy.id),
      day: Number(pharmacy.day),
      latitude: Number(pharmacy.latitude),
      longitude: Number(pharmacy.longitude),
      urlKey: pharmacy.urlKey || slugify(pharmacy.title),
      disabled: !pharmacy.orderAvailable && !pharmacy.reservationOTCAvailable && !pharmacy.reservationRXAvailable,
      hidden: pharmacy.additionalParams?.displayToWeb === false,
      openHours: toOpeningHours(pharmacy.openHours),
      type: 'pharmacy',
    }))
}

const toOpeningHours = (hrs) => hrs.map((item) => ({
  ...item,
  day: normalizeSunday(item.day),
}))
