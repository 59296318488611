import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  articles: {
    authorEnabled,
  },
} = useAppConfig()

export const author = authorEnabled
  ? field(
    'author',
    [
      field('author_id'),
      field('name'),
      field('url_key'),
      field('description'),
      field('photo'),
      field('author_page_visible'),
    ],
  )
  : field('author_id')
