export const state = () => ({
  mappers: [],
})

export const getters = {
  getMappers: (state) => state.mappers,
}

export const actions = {
  initMappers({ commit }, mappers) {
    commit('clearMappers')

    for (const mapper of mappers) {
      commit('addMapper', mapper)
    }
  },
}

export const mutations = {
  addMapper(state, filter) {
    state.mappers.push(filter)
  },

  removeMapper(state, id) {
    const index = state.mappers.find((filter) => filter.id === id)
    state.mappers.splice(index, 1)
  },

  clearMappers(state) {
    state.mappers = []
  },
}
