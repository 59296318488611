import { getDistance } from './MapUtils.js'

export const POINT_TYPES = {
  PHARMACY: 'pharmacy',
  POST_OFFICE: 'post-office',
  PACKETA: 'packeta',
  PHARMACY_EXPRESS: 'pharmacy-express',
}

export const filteredByAll = (filters) => (places) => places.filter((place) => filters.every((filter) => filter(place)))

export const mappedByAll = (mappers) => (places) => {
  let mappedPlaces = places
  for (const mapper of mappers) {
    mappedPlaces = mappedPlaces.map(mapper)
  }
  return mappedPlaces
}

export const withinMapBounds = (mapBounds) => (places) => (mapBounds
  ? places.filter((place) => mapBounds.contains({ lat: place.latitude, lng: place.longitude }))
  : [])

export const distinctByDistanceFrom = (location) => (places) => places
  .map((place) => ({
    ...place,
    distance: getDistance(place, location),
  }))
  .sort((a, b) => a.distance - b.distance)
  .filter((place, index, sortedPlaces) => (index === 0 || place.pickupPlaceId !== sortedPlaces[index - 1].pickupPlaceId) ?? [])

export const nearestTo = (location) => (places) => {
  const distances = places.map((place) => getDistance(place, location))
  const nearestIndex = distances.indexOf(Math.min(...distances))
  return nearestIndex >= 0 ? places[nearestIndex] : null
}

export const separatePlacesByType = (places) => {
  const separated = {}
  for (const place of places) {
    separated[place.type] = separated[place.type] || []
    separated[place.type].push(place)
  }
  return separated
}
