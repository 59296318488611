import logger from '@nsf/articles/logger.js'
import {
  INT, INT_ARRAY, REQUIRED_INT, REQUIRED_STRING,
} from '@nsf/core/consts/GraphQLTypes.js'
import { variable } from '@nsf/core/GraphQL.js'
import { withDefault } from '@nsf/core/Mapper.js'
import { getPageNumber } from '@nsf/utils/UrlUtils.js'
import {
  catInfoQuery, catInfoSimpleQuery, blogCatlistQuery, catInfoWithChildrenQuery,
} from '@ioc/graphql/queries.js'
import {
  mapDataToCategory, mapDataToBlogCatlistCategory,
} from '../mappers/CategoryMapper.js'

export const getDefaultCategory = () => ({
  id: 0,
  title: '',
  altTitle: '',
  posts: [],
  postsCount: 0,
  urlKey: '',
  level: 0,
  children: [],
  seo: {
    metaTitle: '',
    metaDescription: '',
  },
  parentId: 0,
  status: 1,
  icon: '',
  displayMode: 0,
  themeClass: '',
})

export const getDefaultBlogCatlistCategory = () => ({
  id: 0,
  title: '',
  urlKey: '',
  icon: '',
})

export const getDefaultCategoryWithChildren = () => ({
  id: 0,
  title: '',
  urlKey: '',
  children: [],
  status: 1,
  sortOrder: 0,
})

export const getCategoryById = async (
  categoryId, url,
) => {
  const page = getPageNumber(url)
  try {
    const response = await catInfoQuery()
      .clone()
      .where(
        'category_id',
        variable(
          'categoryId',
          REQUIRED_INT,
        ),
      )
      .bind({
        categoryId,
        page,
      })
      .get()

    const category = withDefault(
      getDefaultCategory,
      mapDataToCategory(
        response,
        {},
        true,
      ),
    )

    // If page is out of range of available pages we temporary redirect page to base article category path
    if (page > 1 && !category.posts.length) {
      const path = url.split('?')
      return {
        redirect: path[0],
        statusCode: 302,
      }
    }

    return {
      category: {
        ...category,
        page,
      },
    }
  } catch (e) {
    logger.error(
      'getCategoryById(%o, %o) failed: %s',
      categoryId,
      page,
      e.message,
    )

    return { category: getDefaultCategory() }
  }
}

export const getCategoryWithChildren = async (categoryId) => {
  try {
    const response = await catInfoWithChildrenQuery()
      .clone()
      .where(
        'category_id',
        variable(
          'categoryId',
          REQUIRED_INT,
        ),
      )
      .bind({ categoryId })
      .get()
    const category = withDefault(
      getDefaultCategoryWithChildren,
      mapDataToCategory(
        response,
        {},
        false,
      ),
    )
    return {
      category,
    }
  } catch (e) {
    logger.error(
      'getCategoryWithChildren(%o, %o) failed: %s',
      categoryId,
      e.message,
    )

    return { category: getDefaultCategory() }
  }
}

export const getSimpleCategoryByUrl = async (
  urlKey, page = 1,
) => {
  try {
    const response = await catInfoSimpleQuery()
      .clone()
      .where(
        'url_key',
        variable(
          'urlKey',
          REQUIRED_STRING,
        ),
      )
      .bind({
        urlKey,
        page,
      })
      .get()

    const category = withDefault(
      getDefaultCategory,
      mapDataToCategory(response),
    )

    return { category }
  } catch (e) {
    logger.error(
      'getCategoryByUrl(%o, %o) failed: %s',
      urlKey,
      page,
      e.message,
    )

    return { category: getDefaultCategory() }
  }
}

export const getCategoryByUrl = async (
  urlKey, page = 1,
) => {
  try {
    const response = await catInfoQuery()
      .clone()
      .where(
        'url_key',
        variable(
          'urlKey',
          REQUIRED_STRING,
        ),
      )
      .bind({
        urlKey,
        page,
      })
      .get()

    const category = withDefault(
      getDefaultCategory,
      mapDataToCategory(response),
    )

    return { category }
  } catch (e) {
    logger.error(
      'getCategoryByUrl(%o, %o) failed: %s',
      urlKey,
      page,
      e.message,
    )

    return { category: getDefaultCategory() }
  }
}

export const getAdvisoryCategories = async () => {
  try {
    const response = await blogCatlistQuery()
      .where(
        'advisory_widget_show',
        variable(
          'advisoryWidgetShow',
          INT,
        ),
      )
      .bind({ advisoryWidgetShow: 1 })
      .get()
    const categories = withDefault(
      getDefaultBlogCatlistCategory,
      mapDataToBlogCatlistCategory(response),
    ) || []

    if (!categories.length) {
      logger.withTag('graphql')
        .error('categories for advisory not selected')
    }
    return { categories }
  } catch (e) {
    logger.error(
      'getAdvisoryCategories failed: %s',
      e.message,
    )

    return { categories: [] }
  }
}

export const getCategories = async ({
  levels = [], parentIds = [],
}) => {
  try {
    const res = blogCatlistQuery()

    if (levels?.length) {
      res.where(
        'level',
        variable(
          'level',
          INT_ARRAY,
        ),
      )
      res.bind({ level: levels })
    }
    if (parentIds?.length) {
      res.where(
        'parent_id',
        variable(
          'parent_id',
          INT_ARRAY,
        ),
      )
      res.bind({ parent_id: parentIds })
    }

    const response = await res.get()
    const categories = withDefault(
      getDefaultBlogCatlistCategory,
      mapDataToBlogCatlistCategory(response),
    )

    return { categories }
  } catch (e) {
    logger.error(
      'getCategories() failed: %s',
      e.message,
    )

    return { categories: [] }
  }
}
