import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  articles: {
    relativeUrlEnabled,
  },
} = useAppConfig()

export const urlField = relativeUrlEnabled ? field('relative_url') : field('url_key')
