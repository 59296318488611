import { getAttributeOptionByUrlPrefix } from '@nsf/catalog/repositories/AttributeRepository.js'
import { getProductsByBrandId } from '@nsf/catalog/repositories/ProductRepository.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getFilter } from '@nsf/catalog/utils/BuilderUtils.js'
import { Builder } from '@nsf/base/builders/Builder.js'
import { sliceHashPartFromUrl, sliceSlashFromUrl } from '@nsf/utils/UrlUtils.js'
import { getBrandByProductLineId } from '@nsf/brands/repositories/ProductLineRepository.js'
import {
  getCategoriesByProductLineId,
} from '@nsf/catalog/repositories/CategoryRepository.js'

const {
  rootConfig: {
    global: {
      pagination: {
        productsPerPage,
      },
    },
  },
} = useAppConfig()

export default class ProductLineBuilder extends Builder {
  async _fetchBrand() {
    const { brand } = await getBrandByProductLineId(this.id())

    return {
      ...brand,
      productLineId: this.id(),
      ...(!brand?.id && { statusCode: 404 }),
    }
  }

  async _fetchProducts({ optionId }) {
    const page = Number(this.query('page')) || 1
    const sort = this.query('sort')
    const filter = this._getFilter()
    const search = this.query('search')
    const range = this.query('range')
    const categoryId = this.query('a_category_id')

    const { products, total, query } = await getProductsByBrandId(optionId, {
      from: (page - 1) * productsPerPage,
      sort,
      filter,
      search,
      range,
      categoryId,
    })

    return {
      _products: products,
      _productsTotal: total,
      _productsQuery: query,
      _categoryId: categoryId,
    }
  }

  async _fetchCategories() {
    // eslint-disable-next-line max-len
    const productLine = this._data.productLine?.filter((line) => Number(line.id) === Number(this._data.productLineId))?.[0] ?? {}

    if (!this._isBrandCategoryFilterInDescription(productLine)) {
      return
    }

    const { categories } = await getCategoriesByProductLineId(productLine.optionId)
    // eslint-disable-next-line consistent-return
    return { categories }
  }

  async _fetchFilterPrefix() {
    const cleanUrl = sliceHashPartFromUrl(sliceSlashFromUrl(this._url))
    const urlPrefix = cleanUrl.split('/').length > 1 ? cleanUrl.split('/').pop() : ''
    let filterPrefix = {}
    if (urlPrefix) {
      const { option } = await getAttributeOptionByUrlPrefix(urlPrefix)
      filterPrefix = option
    }
    return { _filterPrefix: filterPrefix }
  }

  _isBrandCategoryFilterInDescription(productLine) {
    if (!productLine.layout) {
      return false
    }

    return !!(
      productLine?.dscTop?.includes('drmax_pagebuilder_brand_category_filter')
      || productLine?.dscTop?.includes('drmax_brand_category_filter')
      || productLine?.dscBottom?.includes('drmax_pagebuilder_brand_category_filter')
      || productLine?.dscBottom?.includes('drmax_brand_category_filter')
    )
  }

  _getFilter() {
    return getFilter(this.query('filter'), this._data)
  }

  async load() {
    await super.load([
      this._fetchBrand,
      this._fetchFilterPrefix,
    ])

    await super.load([
      this._fetchProducts,
      this._fetchCategories,
    ])

    // eslint-disable-next-line max-len
    const productLine = this._data.productLine?.filter((line) => Number(line.id) === Number(this._data.productLineId))?.[0] ?? {}

    // product line page is inactive
    if (productLine?.activeState === '2') {
      this._data.redirectLocaleName = 'brands'
      this._data.statusCode = 302
    }
  }
}
