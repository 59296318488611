import jwtDecode from 'jwt-decode'
import { set as lsSet, remove as lsRemove } from '@nsf/core/Storage.js'
import { delCookie, getCookie, setCookie } from '@nsf/utils/CookieUtils.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  public: {
    environmentName,
  },
} = useRuntimeConfig()
const isLocalEnv = ['local', 'docker'].includes(environmentName)
const LS_TOKEN_KEY = 'userBase/token'

export const AUTH_TOKEN_KEY = isLocalEnv ? 'stageToken' : `${environmentName}Token`
export const AUTH_REFRESH_TOKEN_KEY = isLocalEnv ? 'stageRefreshToken' : `${environmentName}RefreshToken`

const setCookieToken = (key, value, decodedToken) => {
  const tokenExpires = new Date(decodedToken.exp * 10000).toUTCString()
  setCookie(key, value, { expires: tokenExpires })
}

export const decodeAndSetTokens = (payload) => {
  lsSet(LS_TOKEN_KEY, payload.accessToken)
  setCookieToken(AUTH_TOKEN_KEY, payload.accessToken, jwtDecode(payload.accessToken))

  if (payload.refreshToken) {
    setCookieToken(AUTH_REFRESH_TOKEN_KEY, payload.refreshToken, jwtDecode(payload.refreshToken))
  }
}

export const removeTokens = () => {
  lsRemove(LS_TOKEN_KEY)
  delCookie(AUTH_TOKEN_KEY)
  delCookie(AUTH_REFRESH_TOKEN_KEY)
}

export const getAuthorization = () => `Bearer ${getToken()}`

export const getToken = () => getCookie(AUTH_TOKEN_KEY)
export const getRefreshToken = () => getCookie(AUTH_REFRESH_TOKEN_KEY)

export const hasAnyToken = () => !!getToken() || !!getRefreshToken()

export const isUnauthorizedStatus = (status) => [400, 401].includes(status)

export const isServerErrorStatus = (status) => [500].includes(status)

export const getLoginError = (status) => {
  if (status < 300) {
    return null
  }
  if (isUnauthorizedStatus(status)) {
    return 'unauthorized'
  }
  if (status === 403) {
    return 'missingConfirmation'
  }
  return 'other'
}

export const getLogoutError = (status) => {
  if (status < 300) {
    return null
  }
  if (isUnauthorizedStatus(status)) {
    return 'unauthorized'
  }
  return 'other'
}
