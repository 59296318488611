import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday.js'
import isTomorrow from 'dayjs/plugin/isTomorrow.js'
import isBetween from 'dayjs/plugin/isBetween.js'
import timezone from 'dayjs/plugin/timezone.js'
import utc from 'dayjs/plugin/utc.js'

const { pickupSelectV2: { timezone: envTimezone } } = useAppConfig()

dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isBetween)
dayjs.extend(timezone)
dayjs.extend(utc)

dayjs.tz.setDefault(envTimezone)

export default dayjs
