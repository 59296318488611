import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    features: {
      paymentFeeEnabled,
    },
  },
} = useAppConfig()

export default field('available_payment_methods', [
  field('code'),
  field('title'),
  field('tooltip'),
  ...paymentFeeEnabled
    ? [field('payment_fee', [
        field('fee'),
      ])]
    : [],
])
