import { set as setStorage } from '@nsf/core/Storage.js'
import { getSuggestSearchLength } from '../utils/SearchUtils.js'

const getDefaultSearchState = () => ({
  searchingKeyword: '',
  searchingProductIds: [],
  localRecentSearches: [],
  analyticsTopProducts: [],
  analyticsTopSearches: [],
  totalResults: null,
  totalProducts: 0,
  loadingTopProducts: false,
})

export const state = () => ({
  ...getDefaultSearchState(),
})

export const mutations = {
  setSearchingKeyword: (moduleState, keyword) => {
    moduleState.searchingKeyword = keyword
  },
  setSearchingProductIds: (moduleState, productIds) => {
    moduleState.searchingProductIds = productIds
  },
  setTotalResults: (moduleState, results) => {
    moduleState.totalResults = results
  },
  setTotalProducts: (moduleState, results) => {
    moduleState.totalProducts = results
  },
  setLoadingTopProducts: (moduleState, loading) => {
    moduleState.loadingTopProducts = loading
  },
  setAnalyticsTopSearches: (moduleState, analyticsTopSearches) => {
    setStorage('analyticsTopSearches', analyticsTopSearches, 'sessionStorage')
    moduleState.analyticsTopSearches = analyticsTopSearches
  },
  setAnalyticsTopProducts: (moduleState, analyticsTopProducts) => {
    setStorage('analyticsTopProducts', analyticsTopProducts, 'sessionStorage')
    moduleState.analyticsTopProducts = analyticsTopProducts
  },
  setLocalRecentSearches: (moduleState, recentSearches) => {
    setStorage('localRecentSearches', recentSearches)
    moduleState.localRecentSearches = recentSearches
  },
  addRecentSearch(moduleState, keyword) {
    const searches = moduleState.localRecentSearches
    const extendKeywordIndex = searches.findIndex((word) => keyword.includes(word))
    const subKeywordIndex = searches.findIndex((word) => word.includes(keyword))
    if (subKeywordIndex !== -1) {
      return
    }

    let newSearches = searches
    if (extendKeywordIndex === -1) {
      searches.unshift(keyword)
      newSearches = searches.slice(0, getSuggestSearchLength('USERSEARCHES', 3))
    } else {
      searches.splice(extendKeywordIndex, 1, keyword)
    }

    setStorage('localRecentSearches', newSearches)
    moduleState.localRecentSearches = newSearches
  },
}
