export default {
  pins: {
    PHARMACY: {
      enabled: '/img/map/pin-pharmacy-enabled.svg',
      disabled: '/img/map/pin-pharmacy-disabled.svg',
      selected: '/img/map/pin-pharmacy-selected.svg',
    },

    POST_OFFICE: {
      enabled: '/img/map/pin-post-office-sk-enabled.svg',
      disabled: '/img/map/pin-post-office-sk-disabled.svg',
      selected: '/img/map/pin-post-office-sk-selected.svg',
    },

    ZASILKOVNA: {
      enabled: '/img/map/pin-packeta-enabled.svg',
      disabled: '/img/map/pin-packeta-disabled.svg',
      selected: '/img/map/pin-packeta-selected.svg',
    },

    DRMAX_BOX: {
      enabled: '/img/map/pin-boxes-enabled.svg',
      disabled: '/img/map/pin-boxes-disabled.svg',
      selected: '/img/map/pin-boxes-selected.svg',
    },

    PICKUP_PLACE: {
      enabled: '/img/map/pin-boxes-enabled.svg',
      disabled: '/img/map/pin-boxes-disabled.svg',
      selected: '/img/map/pin-boxes-selected.svg',
    },

    PACZKOMAT: {
      enabled: '/img/map/pin-paczkomaty-enabled.svg',
      disabled: '/img/map/pin-paczkomaty-disabled.svg',
      selected: '/img/map/pin-paczkomaty-selected.svg',
    },

    INPOST: {
      enabled: '/img/map/pin-inpost-enabled.svg',
      disabled: '/img/map/pin-inpost-disabled.svg',
      selected: '/img/map/pin-inpost-selected.svg',
    },

    PHARMACY_EXPRESS: {
      enabled: '/img/map/pin-pharmacy-enabled.svg',
      disabled: '/img/map/pin-pharmacy-disabled.svg',
      selected: '/img/map/pin-pharmacy-selected.svg',
    },

    userLocation: '/img/map/pin-user-location.png',
  },

  clusterFillColors: {
    PHARMACY: '#3C951F',
    DRMAX_BOX: '#3C951F',
    POST_OFFICE: '#F2AC00',
    PACZKOMAT: '#fec909',
    INPOST: '#fec909',
    ZASILKOVNA: '#BA1B02',
    default: '#808080',
  },

  markerClustererConfig: {
    libraryUrl: 'https://unpkg.com/@googlemaps/markerclusterer@2.0.15/dist/index.min.js',
    maxZoom: 14,
    clusterRadius: 120,
    placesLimit: 200,
    clusterFillColors: {
      pharmacy: '#3C951F',
      drmax_box: '#3C951F',
      postOffice: '#F2AC00',
      paczkomat: '#fec909',
      inpost: '#fec909',
      innoship: '#B21F24',
      packeta: '#BA1B02',
      default: '#808080',
    },
  },
}
