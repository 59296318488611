import logger from '@nsf/cms/logger.js'
import { setState } from '@nsf/core/Store.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { getAnnouncement } from '@nsf/cms/repositories/CmsServiceRepository.js'

const {
  public: { cmsUseAnnouncement },
} = useRuntimeConfig()

export const state = () => ({
  announcement: null,
})

export const nuxtServerInit = async ({ dispatch }) => {
  const promises = []

  if (cmsUseAnnouncement) {
    promises.push(dispatch('cms/loadAnnouncement'))
  }

  await Promise.all(promises)
}

export const mutations = {
  setState,
}

export const actions = {
  setAnnouncement({ commit }, obj) {
    commit('setAnnouncement', obj)
  },

  async loadAnnouncement(store) {
    try {
      const announcement = await getAnnouncement()
      store.commit('setState', { announcement })
    } catch (e) {
      logger.error('[cms-store] getAnnouncement(%s) failed: %s', e.message)
    }
  },
}
