import { Query, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_INT } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Query.named('cmsPage')
  .where('id', variable('id', REQUIRED_INT))
  .fields([
    field('title'),
    field('url_key'),
  ])
  .shouldBeCached(true)
