import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    features: {
      streetNumberToMagentoEnabled,
    },
  },
  rootConfig: {
    global: {
      streetNumberEnabled,
    },
  },
} = useAppConfig()

export default [
  field('firstname'),
  field('lastname'),
  field('company'),
  field('street'),
  field('city'),
  field('country_id'),
  field('region_id'),
  field('region'),
  field('postcode'),
  field('telephone'),
  ...(streetNumberToMagentoEnabled && streetNumberEnabled ? [field('streetNumber: drmax_sk_house_number')] : []),
]
