import { field, Query, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import cartItemsModified from '../fragments/cartItemsModified.js'
import cartPrices from '../fragments/cartPrices.js'
import cartAvailablePaymentMethods from '../fragments/cartAvailablePaymentMethods.js'
import cartAppliedPaymentRestrictions from '../fragments/cartAppliedPaymentRestrictions.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import marketplaceQuote from '../fragments/marketplaceQuote.js'
import cartShippingAddressesShippingMethodsOnly from '../fragments/cartShippingAddressesShippingMethodsOnly.js'

const {
  public: {
    marketplaceEnabled,
  },
} = useRuntimeConfig()

export default () => Query.named('cart')
  .where('cart_id', variable('cartId', REQUIRED_STRING))
  .fields([
    ...cartItemsModified,
    cartShippingAddressesShippingMethodsOnly,
    field('order_type'),
    cartPrices,
    cartAvailablePaymentMethods,
    cartAppliedPaymentRestrictions,
    cartSelectedPaymentMethod,
    ...marketplaceEnabled ? [marketplaceQuote] : [],
  ])
