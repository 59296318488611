const nuxtServerInitActions = {}

/**
 * @param {string} path
 * @param {function} action
 */
export const registerNuxtServerInitAction = (path, action) => {
  nuxtServerInitActions[path] = action
}

/**
 * @returns {function[]}
 */
export const getNuxtServerInitActions = () => Object.values(nuxtServerInitActions)

export const setState = (state, newState) => {
  for (const [key, value] of Object.entries(newState)) {
    state[key] = value
  }
}

export const resetState = (factory) => (state) => {
  setState(state, factory())
}
