/**
 * Function that prevent the possibility of changing data while the application is running
 *
 * @param {Object} object The object to be frozen
 * @returns {Object} The frozen object
 * */
export const useDeepFreeze = (object) => {
  const propNames = Object.getOwnPropertyNames(object)
  for (const name of propNames) {
    const value = object[name]
    if (value && typeof value === 'object') {
      useDeepFreeze(value)
    }
  }
  return Object.freeze(object)
}
export default useDeepFreeze
