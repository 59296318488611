import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone.js'
import utc from 'dayjs/plugin/utc.js'

const { pickupSelectV2: { timezone: envTimezone } } = useAppConfig()

dayjs.extend(timezone)
dayjs.extend(utc)

dayjs.tz.setDefault(envTimezone)

export default dayjs
