import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    defaultAgreements,
  },
  rootConfig: {
    global: {
      agreementsTopic,
    },
  },
} = useAppConfig()

export const getDefaultAgreements = () => [
  {
    channel: 'PPD',
    topic: agreementsTopic,
    value: 'N',
  },
  {
    channel: 'EMAIL',
    topic: agreementsTopic,
    value: 'N',
  },

  ...defaultAgreements(agreementsTopic),
]

export const getRedirectAgreements = () => [
  {
    channel: 'BASKET',
    topic: '',
    value: 'N',
  },
  {
    channel: 'PERSONAL_DATA',
    topic: '',
    value: 'N',
  },
  {
    channel: 'TERMS_AND_CONDITIONS',
    topic: '',
    value: 'N',
  },
]
