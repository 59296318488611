import { setState } from '@nsf/core/Store.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  base: {
    searchVisibleOnScrollEnabled,
  },
} = useAppConfig()

export const state = () => ({
  isHamburgerExpanded: false,
  isSearchMenuExpanded: searchVisibleOnScrollEnabled,
  resolverType: null,
  resolverId: null,
  noMenuItems: false,
  menuOverride: null,
  menuItemsOverride: null,
  menuComponentName: null,
  isSearchDropdownExpanded: false,
})

export const actions = {
  setMenuItemsOverride({ commit }, { menu = null, menuItems = null, name = null }) {
    commit('setState', { menuOverride: menu, menuComponentName: name, menuItemsOverride: menuItems })
  },

  setMenuOverride({ commit }, { menu = null, name = null }) {
    commit('setState', { menuOverride: menu, menuComponentName: name, menuItemsOverride: null })
  },

  removeMenuOverride({ commit, state }, { name = null }) {
    commit('setState', { noMenuItems: false })
    if (state.menuComponentName === name) {
      commit('setState', { menuOverride: null, menuComponentName: null, menuItemsOverride: null })
    }
  },

}

export const mutations = {
  setState,

  toggleHamburger(state) {
    if (state.isSearchMenuExpanded) {
      state.isSearchMenuExpanded = false
    }
    state.isHamburgerExpanded = !state.isHamburgerExpanded
  },

  toggleSearchMenu(state) {
    if (state.isHamburgerExpanded) {
      state.isHamburgerExpanded = false
    }

    state.isSearchMenuExpanded = !state.isSearchMenuExpanded
  },

  setSearchDropdownExpanded(state, isExpanded) {
    state.isSearchDropdownExpanded = isExpanded
  },
}

export const getters = {}
