import Vue from 'vue'

const instances = new Map()

function toggleEventListeners(action, eventHandler) {
  document[`${action}EventListener`]('click', eventHandler, true)
}

function hasModalAncestor(el, className) {
  let element = el

  while (element) {
    if (element.classList && element.classList.contains(className)) {
      return true
    }
    element = element.parentElement
  }

  return false
}

Vue.directive('click-outside', {
  bind(el, { value: handler }) {
    const isFunction = typeof handler === 'function'

    if (!isFunction) {
      throw new Error(`v-click-outside: Binding value should be a function, ${typeof handler} given`)
    }

    const eventHandler = (event) => {
      const isTargetInModal = hasModalAncestor(event.target, 'modal')
      const isElementInModal = hasModalAncestor(el, 'modal')
      const isElementInPopover = hasModalAncestor(el, 'popover')

      const isClickOutside = (event.target !== el && !el.contains(event.target))
        && (
          (isTargetInModal && !isElementInModal && isElementInPopover)
          || (!isTargetInModal && !isElementInModal)
          || (isTargetInModal && isElementInModal && !isElementInPopover)
        )

      return isClickOutside ? handler(event, el) : null
    }

    toggleEventListeners('add', eventHandler)

    instances.set(el, eventHandler)
  },

  unbind(el) {
    const eventHandler = instances.get(el)

    toggleEventListeners('remove', eventHandler)

    instances.delete(el)
  },
})
