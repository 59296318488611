import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    features: {
      cartItemsRowOriginalPriceEnabled,
    },
  },
} = useAppConfig()

export default field('prices', [
  field('row_total_with_discount_including_tax', [
    field('currency'),
    field('value'),
  ]),
  field('row_total_including_tax', [
    field('currency'),
    field('value'),
  ]),
  field('row_total', [
    field('currency'),
    field('value'),
  ]),
  ...cartItemsRowOriginalPriceEnabled
    ? [field('row_original_price', [
        field('currency'),
        field('value'),
      ])]
    : [],
  field('price', [
    field('currency'),
    field('value'),
  ]),
])
