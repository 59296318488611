import isEmpty from 'lodash/isEmpty.js'

/**
 * Group of cart items.
 * @typedef SellerItemGroup
 * @type {Object}
 * @property {number | null} sellerId
 * @property {number} productsCount
 * @property {ItemGroup[]} itemGroups
 */

/**
 * @param {ItemGroup[]} groups
 * @returns {SellerItemGroup[]}
 */
const groupItemsBySeller = (groups) => {
  const groupedBySeller = groups.reduce((acc, group) => {
    const { items } = group
    let drmaxItemGroupTmp = {}

    items.forEach((item) => {
      const sellerId = item.product.drmaxMarketplaceSellerId || null

      if (sellerId) {
        const existingSeller = acc.find((tmpGroup) => tmpGroup.sellerId === sellerId)
        if (existingSeller) {
          existingSeller.itemGroups[0].items.push(item)
        } else {
          acc.push({ sellerId, itemGroups: [{ ...group, items: [item] }] })
        }
      } else if (isEmpty(drmaxItemGroupTmp)) {
        drmaxItemGroupTmp = { ...group, items: [item] }
      } else {
        drmaxItemGroupTmp.items.push(item)
      }
    })

    if (!isEmpty(drmaxItemGroupTmp)) {
      const drMaxGroup = acc.find((tmpGroup) => tmpGroup.sellerId === null)
      if (!drMaxGroup) {
        acc.push({ sellerId: null, itemGroups: [drmaxItemGroupTmp] })
      } else {
        drMaxGroup.itemGroups.push(drmaxItemGroupTmp)
      }
    }

    return acc
  }, [])

  return groupedBySeller
}

/**
 * @param {SellerItemGroup[]} sellerItemGroups
 * @param {ItemGroup[]} groups
 */
const addGlobalGroups = (sellerItemGroups, groups) => {
  const globalGroups = groups.filter((group) => group.key === 'GLOBAL')

  if (globalGroups.length === 0) {
    return
  }

  sellerItemGroups.push({
    sellerId: 0,
    itemGroups: globalGroups,
  })
}

/**
 * Propagate our own brand to the top. Mutates the array.
 * @param {SellerItemGroup[]} sellerItemGroups
 */
const moveOwnSellerToTop = (sellerItemGroups) => {
  sellerItemGroups.sort((a, b) => {
    if (a.sellerId === null) {
      return -1
    }
    if (b.sellerId === null) {
      return 1
    }
    return 0
  })
}

/**
 * @param {ItemGroup[]} groups
 * @return {SellerItemGroup[]}
 */
export default (groups) => {
  if (groups.length === 0) {
    return []
  }

  const sellerItemGroups = groupItemsBySeller(groups)

  addGlobalGroups(sellerItemGroups, groups)
  moveOwnSellerToTop(sellerItemGroups)

  return sellerItemGroups
}
