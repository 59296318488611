export default {
  computed: {
    sentryEnabledDateTill() {
      return Number(`${this.config.drmaxNsfSentryDateTill}000`)
    },
  },

  created() {
    this.config = this.$store.state['_base/config'].config
  },

  mounted() {
    this.$nextTick(() => {
      const now = Date.now()

      if (!!this.$sentryLoad && now <= this.sentryEnabledDateTill) {
        this.$sentryLoad()
      }
    })
  },
}
