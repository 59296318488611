export default {
  elastic: {
    index: 'drmax_eshop_3',
    indices: {
      allIndicesWildcard: 'drmax_frontend*',
      apothekaPharmacyIndex: 'drmax_apotheka_pharmacy_1',
      attributeIndex: 'drmax_frontend_attribute_3',
      brandIndex: 'drmax_frontend_brand_3',
      bundleIndex: 'drmax_frontend_product_bundles_3',
      categoryIndex: 'drmax_frontend_category_3',
      deliveryCalculationConfigIndex: 'drmax_delivery_calculation_config_1',
      filterPageIndex: 'drmax_filter_page_index_1',
      marketplaceSellersIndex: 'drmax_frontend_mirakl_seller_1',
      megamenuIndex: 'drmax_frontend_megamenu_3',
      productAttachmentIndex: 'drmax_frontend_product_attachment_3',
      productGroupIndex: 'drmax_frontend_product_group_1',
      productIndex: 'drmax_frontend_products_3',
      productReviewsIndex: 'drmax_frontend_product_reviews_3',
      promoActionsIndex: 'drmax_frontend_promo_action_3',
      salesRuleIndex: 'drmax_sales_rule_1',
      urlResolverIndex: 'drmax_frontend_url_resolver_1',
    },
  },
}
