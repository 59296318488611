import logger from '@nsf/my-account/logger.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import myAccountServiceClient from '../clients/MyAccountServiceClient.js'
import { withAuth } from '../utils/authDecorator.js'

const {
  public: { recaptchaEnabled },
} = useRuntimeConfig()

export const checkEmail = async (
  email, ctx,
) => {
  let captchaQuery = ''
  if (ctx?.$recaptcha && recaptchaEnabled) {
    captchaQuery = `?captcha=${await ctx.$recaptcha.execute('submit')}`
  }

  try {
    const path = `/clients/check/email/${email}${captchaQuery}`

    const {
      data,
      status,
    } = await myAccountServiceClient.get(path)

    // H – unregistered client, no card, no login (only for info, it is not part of new registration porc.)
    // V – offline client with loyalty card, no login
    // E – pure Ecomm, no LP card, he has login
    // O – online registered client with loyalty card

    return {
      _exists: !!data?.clientId,
      status,
      clientId: data?.clientId,
      type: data?.accountType ?? null,
    }
  } catch (e) {
    logger.error(
      'checkEmail(%s) failed: %s',
      email,
      e.message,
    )

    return {
      _exists: true,
      status: 500,
      clientId: null,
      type: null,
    }
  }
}

export const setEmail = async (clientId, email) => {
  try {
    const { status, ok } = await myAccountServiceClient.put(`/clients/${clientId}`, { email })

    return {
      ok,
      status,
    }
  } catch (e) {
    logger.error(
      'setRegistrationEmail(%s) failed: %s',
      clientId,
      email,
      e.message,
    )

    return {
      ok: false,
      status: 500,
    }
  }
}

export const verifyEmail = async () => {
  try {
    const {
      data,
      status,
      ok,
    } = await withAuth(myAccountServiceClient.post)(`/clients/me/verify-email`)

    const errorCode = !ok && data.detail && JSON.parse(JSON.parse(data.detail).detail).err_code

    if (errorCode === 21) {
      return {
        ok: false,
        status,
        isRegistrationEmailMissing: true,
      }
    }

    if (!ok) {
      throw new Error('Request failed')
    }

    return {
      ok: true,
      status,
    }
  } catch (e) {
    logger.error(
      'resendRegistrationEmail failed: %s',
      e.message,
    )

    return {
      ok: false,
      status: 500,
    }
  }
}

export const resendRegistrationEmail = async (clientId) => {
  try {
    const { status, ok } = await myAccountServiceClient.post(
      `/clients/${clientId}/verify-email`,
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
      status,
    }
  } catch (e) {
    logger.error('resendRegistrationEmail(%s) failed: %s', clientId, e.message)

    return {
      ok: false,
      status: 500,
    }
  }
}
