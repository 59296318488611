import { registerPageResolver } from '@nsf/core/PageResolver.js'
import { CategoryBuilder } from '@nsf/catalog/builders/CategoryBuilder.js'

export default function () {
  registerPageResolver(
    'CATEGORY',
    (id, url, ctx) => new CategoryBuilder(id, url, ctx).build(),
    () => import(/* webpackChunkName: "dynamicPages/CATEGORY" */ '@nsf/catalog/dynamicPages/CategoryDetail.vue'),
  )
}
