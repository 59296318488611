import {
  field, Query, variable,
} from '@nsf/core/GraphQL.js'
import { REQUIRED_INT } from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { tags } from '../fragments/tags.js'
import { postFields } from '../fragments/postFields.js'
import { menuRoot } from '../fragments/menuRoot.js'
import categoryFields from '../fragments/categoryFields.js'
import { readingTime } from '../fragments/readingTime.js'
import { author } from '../fragments/author.js'

const {
  articles: {
    adultsOnlyEnabled,
    blockContentEnabled,
    displayModeEnabled,
    iconEnabled,
    menuRootEnabled,
    themeClassEnabled,
    categoryTitleEnabled,
    readingTimeEnabled,
  },
} = useAppConfig()

export default () => Query.named('catInfo')
  .fields([
    field('category_id'),
    ...categoryTitleEnabled ? [field('drmax_category_title')] : [],
    field('name'),
    field('meta_title'),
    field('meta_description'),
    field('meta_robots'),
    field('post_quantity'),
    field('level'),
    field('url_key'),
    field('page_layout'),
    field('posts')
      .where(
        'page_number',
        variable(
          'page',
          REQUIRED_INT,
        ),
      )
      .fields([
        field('short_content'),
        ...postFields,
        ...readingTimeEnabled ? [readingTime] : [],
        tags,
        author,
      ]),
    field(
      'children',
      [
        ...categoryFields,
        ...iconEnabled ? [field('icon')] : [],
      ],
    ),
    field(
      'parent',
      [...categoryFields],
    ),
    field('color_scheme'),
    ...displayModeEnabled ? [field('display_mode')] : [],
    ...blockContentEnabled ? [field('block_content')] : [],
    ...iconEnabled ? [field('icon')] : [],
    ...themeClassEnabled ? [field('theme_class')] : [],
    ...menuRootEnabled ? [menuRoot] : [],
    ...adultsOnlyEnabled ? [field('drmax_adults_only')] : [],
  ])
  .shouldBeCached(true)
