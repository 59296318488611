import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'

/**
 * @param {string} categoryName
 */
export const pushEventMegamenuView = (categoryName) => {
  const event = {
    event: 'superCategory_view',
    superCategory: {
      name: categoryName,
    },
  }

  usePushEvent(event)
}

/**
 * @param {string} categoryName
 * @param {number} depth
 * @param {string} superCategory
 * @param {string} parentCategory
 */
export const pushEventMegamenuClick = (categoryName, depth, superCategory, parentCategory) => {
  const event = {
    event: 'category_select',
    category: {
      name: categoryName,
      depth,
      superCategory,
      parentCategory: parentCategory || '',
    },
  }

  usePushEvent(event)
}
