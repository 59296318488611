import { registerPageResolver } from '@nsf/core/PageResolver.js'
import { ProductBuilder } from '@nsf/catalog/builders/ProductBuilder.js'

export default function() {
  registerPageResolver(
    'PRODUCT',
    (id, url) => new ProductBuilder(id, url).build(),
    () => import(/* webpackChunkName: "dynamicPages/PRODUCT" */ '@nsf/catalog/dynamicPages/ProductDetail.vue'),
  )
}
