/* eslint-disable camelcase */
import { Mutation, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('setGuestEmailOnCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    email: variable('email', REQUIRED_STRING),
  })
  .fields([
    field('cart', [
      field('email'),
    ]),
  ])
