import logger from '@nsf/articles/logger.js'
import {
  REQUIRED_STRING, INT, STRING, REQUIRED_INT,
} from '@nsf/core/consts/GraphQLTypes.js'
import { withDefault } from '@nsf/core/Mapper.js'
import { variable } from '@nsf/core/GraphQL.js'
import {
  articleBlockQuery, postInfoQuery, postsInfoQuery, postPreviewInfoQuery,
} from '@ioc/graphql/queries.js'
import {
  getDefaultAuthor, getAuthorById,
} from './AuthorRepository.js'
import { mapDataToArticle } from '../mappers/ArticleMapper.js'

export const getDefaultArticle = () => ({
  id: 0,
  authorId: 0,
  author: getDefaultAuthor(),
  categories: [],
  fullContent: {},
  title: '',
  urlKey: '',
  tags: [],
  relatedArticles: [],
  seo: {
    metaTitle: '',
    metaDescription: '',
  },
  mainCategory: {},
})

export const getArticleById = async (id) => {
  try {
    const response = await postInfoQuery()
      .clone()
      .where(
        'id',
        variable(
          'id',
          REQUIRED_INT,
        ),
      )
      .bind({ id })
      .get()
    const article = withDefault(
      getDefaultArticle,
      mapDataToArticle(
        response,
        {},
        true,
      ),
    )

    if (article.authorId) {
      const { author } = await getAuthorById(article.authorId)

      article.author = author
    }

    return { article }
  } catch (e) {
    logger.error(
      'getArticleById(%o) failed: %s',
      id,
      e.message,
    )

    return { article: getDefaultArticle() }
  }
}

export const getArticleByUrl = async (urlKey) => {
  try {
    const response = await postInfoQuery()
      .clone()
      .where(
        'url_key',
        variable(
          'urlKey',
          REQUIRED_STRING,
        ),
      )
      .bind({ urlKey })
      .get()

    const article = withDefault(
      getDefaultArticle,
      mapDataToArticle(response),
    )

    return { article }
  } catch (e) {
    logger.error(
      'getArticleByUrl(%o) failed: %s',
      urlKey,
      e.message,
    )

    return { article: getDefaultArticle() }
  }
}

export const getRecentArticles = async () => {
  try {
    const response = await postsInfoQuery()
      .where(
        'type',
        variable(
          'type',
          REQUIRED_STRING,
        ),
      )
      .bind({ type: 'recent' })
      .get()
    const recentArticles = withDefault(
      getDefaultArticle,
      mapDataToArticle(response?.items),
    )

    return { recentArticles }
  } catch (e) {
    logger.error(
      'getRecentArticles() failed: %s',
      e.message,
    )

    return { recentArticles: [] }
  }
}

export const getFeaturedArticle = async () => {
  try {
    const response = await postsInfoQuery()
      .bind({ type: 'featured' })
      .get()
    const featuredArticle = withDefault(
      getDefaultArticle,
      mapDataToArticle(response?.items?.[0]),
    )

    return { featuredArticle }
  } catch (e) {
    logger.error(
      'getFeaturedArticle() failed: %s',
      e.message,
    )

    return { featuredArticle: getDefaultArticle() }
  }
}

export const getBlockArticles = async ({
  articlesLimit,
  page,
  sort,
  sortOrder,
  filters,
}) => {
  try {
    const res = articleBlockQuery()
      .where(
        'articles_limit',
        variable(
          'articlesLimit',
          INT,
        ),
      )
      .bind({ articlesLimit })
      .where(
        'filters',
        variable(
          'filters',
          'ArticleBlockFilterInput',
        ),
      )
      .bind({ filters })
    if (page) {
      res.where(
        'page',
        variable(
          'page',
          INT,
        ),
      )
        .bind({ page })
    }
    if (sort) {
      res.where(
        'sort',
        variable(
          'sort',
          STRING,
        ),
      )
        .bind({ sort })
    }
    if (sortOrder) {
      res.where(
        'sort_order',
        variable(
          'sortOrder',
          STRING,
        ),
      )
        .bind({ sortOrder })
    }

    const response = await res.get()
    const { count } = response
    const articles = withDefault(
      getDefaultArticle,
      mapDataToArticle(response.articles),
    )

    return {
      count,
      articles,
    }
  } catch (e) {
    logger.error(
      'getBlockArticles() failed: %s',
      e.message,
    )
    return { articles: [] }
  }
}

export const getArticlePreviewById = async (id) => {
  try {
    const response = await postPreviewInfoQuery()
      .clone()
      .where(
        'id',
        variable(
          'id',
          REQUIRED_INT,
        ),
      )
      .bind({ id })
      .get()
    const article = withDefault(
      getDefaultArticle,
      mapDataToArticle(
        response,
        {},
        true,
      ),
    )

    if (article.authorId) {
      const { author } = await getAuthorById(article.authorId)

      article.author = author
    }

    return { article }
  } catch (e) {
    logger.error(
      'getArticlePreviewById(%o) failed: %s',
      id,
      e.message,
    )

    return { article: getDefaultArticle() }
  }
}
