export default (items) => {
  const sellers = items.reduce((acc, item) => {
    const { product } = item
    if (product?.drmaxMarketplaceSellerId) {
      acc[product.drmaxMarketplaceSellerId] = { name: product.drmaxMarketplaceSellerName }
    }
    return acc
  }, {})

  return sellers
}
