import {
  field, Query, variable,
} from '@nsf/core/GraphQL.js'
import { REQUIRED_INT } from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { postFields } from '../fragments/postFields.js'

const {
  articles: {
    categoryTitleEnabled,
    iconEnabled,
  },
} = useAppConfig()

export default () => Query.named('catInfo')
  .fields([
    field('category_id'),
    ...categoryTitleEnabled ? [field('drmax_category_title')] : [],
    field('meta_description'),
    field('meta_title'),
    field('name'),
    field('post_quantity'),
    field('url_key'),
    field('posts')
      .where(
        'page_number',
        variable(
          'page',
          REQUIRED_INT,
        ),
      )
      .fields([
        ...postFields,
        field('short_content'),
      ]),
    ...iconEnabled ? [field('icon')] : [],
  ])
  .shouldBeCached(true)
