import {
  isArray, isObject, toCamel, isNullish, isFunction, isString,
} from './Utils.js'

/**
 * Maps raw data to a unified, camel-cased model
 * Translations can override mappings
 *
 * Usage:
 *
 * const model = mapDataToModel(data, {
 *   'data_id': 'id', // Will translate data_id key as id
 *   'posts': mapDataToPost, // Will use mapDataToPost function to map posts
 * })
 *
 * Parameters listed in except will be ignored from the model
 *
 * @param {object|array} data
 * @param {object} translations
 * @param {boolean} [recursiveTranslations=true]
 * @returns {object|array}
 */
export const mapDataToModel = (data, translations = {}, recursiveTranslations = true) => {
  if (isObject(data)) {
    const model = {}

    for (const [key, value] of Object.entries(data)) {
      // Magento returns " " in some cases, because it cannot store empty values. Space is never a valid value, and is ignored
      if (isNullish(value) || value === ' ') {
        // eslint-disable-next-line no-continue
        continue
      }
      const translation = (recursiveTranslations)
        ? _translate(key, value, translations[key], translations)
        : _translate(key, value, translations[key])

      model[translation.key] = translation.value
    }

    return model
  } if (isArray(data)) {
    return data.map((o) => mapDataToModel(o, translations, recursiveTranslations))
  }

  return data
}

const _translate = (key, value, translation, translations) => {
  if (isNullish(translation)) {
    return {
      key: toCamel(key),
      value: mapDataToModel(value, translations),
    }
  }

  if (isString(translation)) {
    return {
      key: translation,
      value: mapDataToModel(value, translations),
    }
  }

  if (isFunction(translation)) {
    return {
      key: toCamel(key),
      value: translation(value),
    }
  }

  if (isArray(translation)) {
    return translation.reduce(
      ({ key, value }, translation) => _translate(key, value, translation, translations),
      { key, value },
    )
  }

  throw new TypeError(`Unsupported type of model translation: ${typeof translation}`)
}

/**
 * Maps the model to a default null object returned by the factory function.
 * It is used to ensure data consitency returned from a API endpoints.
 *
 * @param {function|object} factory
 * @param {object|array} model
 * @returns {object|array}
 */
export const withDefault = (factory, model) => {
  if (isArray(model)) {
    return model.map((m) => withDefault(factory, m))
  }

  const result = isFunction(factory) ? factory() : factory

  for (const [key, value] of Object.entries(model)) {
    if (!isNullish(value)) {
      result[key] = value
    }
  }

  return result
}

/**
 * @param {array} array
 * @param {string} by
 * @param {(any): any} mapper
 * @returns {any}
 */
export const groupBy = (array, by, mapper = (val) => val) => array.reduce((acc, val) => {
  acc[isString(by) ? val[by] : by(val)] = isString(mapper) ? val[mapper] : mapper(val)

  return acc
}, {})
