/**
 * Degrees to radians ratio
 *
 * @type {number}
 */
import { isString, toCamel } from '@nsf/core/Utils.js'

const DEG_TO_RAD = Math.PI / 180

/**
 * Km per degree of latitude/longitude
 *
 * @type {number}
 */
const DEG_LEN = 110.25

/**
 * Calculate distance between two locations
 *  - naive but fast - Pythagorean theorem
 *  - for country level it's just about ca. 0.5 % less accurate than haversine formula
 *
 * @param {Object} locationA
 * @param {Object} locationB
 *
 * @return {number} Distance in km.
 */
export const getDistance = (locationA, locationB) => {
  const x = locationA.latitude - locationB.latitude
  const y = (locationA.longitude - locationB.longitude) * Math.cos(locationB.latitude * DEG_TO_RAD)

  return Math.sqrt(x ** 2 + y ** 2) * DEG_LEN
}

/**
 * Get type of marker cluster
 * @param markers markers in cluster
 * @returns {string} type of pickup places in cluster, default for mixed types
 */
export const getClusterType = (markers) => {
  if (!markers.length || !markers[0].icon?.url) {
    return 'default'
  }

  const markerUrl = markers[0].icon.url

  if (markerUrl.includes('pharmacy')) {
    return 'pharmacy'
  }
  if (markerUrl.includes('post')) {
    return 'postOffice'
  }
  if (markerUrl.includes('packeta')) {
    return 'packeta'
  }
  if (markerUrl.includes('paczkomaty') || markerUrl.includes('paczkomat')) {
    return 'paczkomat'
  }
  if (markerUrl.includes('inpost')) {
    return 'inpost'
  }
  if (markerUrl.includes('boxes') || markerUrl.includes('drmax_box')) {
    return 'drmax_box'
  }

  return 'default'
}

/**
 * Get type of fake cluster
 * @param places places that the fake cluster represents
 * @returns {string} type of pickup places in cluster, default for mixed types
 */
export const getFakeClusterType = (places) => {
  if (!places.length || !isString(places[0].type)) {
    return 'default'
  }

  const placeType = places[0].type.toLowerCase()

  if (['packeta', 'pharmacy', 'postOffice'].includes(placeType)) {
    return placeType
  }

  if (placeType.includes('pickup-place') && isString(places[0].pickupPlaceType)) {
    const pickupPlaceType = places[0].pickupPlaceType?.toLowerCase()

    if (pickupPlaceType.includes('inpost')) { // inpost box or inpost pok
      return 'inpost'
    }

    if (pickupPlaceType.includes('innoship')) { // innoship box or innoship pok
      return 'innoship'
    }

    if (['pharmacy', 'drmax_box', 'packeta', 'paczkomat'].includes(pickupPlaceType)) {
      return pickupPlaceType
    }
  }

  return 'default'
}

/**
 * Get type of place returned from Google Places API, that determines suitable zoom level of the map
 * @param googlePlaceTypes place types that given place has - https://developers.google.com/maps/documentation/places/web-service/supported_types#table2
 * @returns {string} camel-cased place type relevant for figuring out suitable zoom level
 */
export const getGooglePlaceTypeRelevantForZoomLevel = (...googlePlaceTypes) => {
  const firstType = googlePlaceTypes.pop()
  if (firstType === 'political') {
    const secondType = googlePlaceTypes.pop()
    return toCamel(secondType)
  }
  return toCamel(firstType)
}
