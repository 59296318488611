/* eslint-disable default-case */
/* eslint-disable import/prefer-default-export */
import { Query } from '@nsf/core/ElasticSearch.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import logger from '../logger.js'
import { mapDataToMegamenu } from '../mappers/MegamenuMapper.js'

const OMITED_FIELDS = [
  'items.items.items.hero_product',
  'items.items.items.items.hero_product',
]

const {
  public: { placementGenericEnabled },
} = useRuntimeConfig()

export const getMegamenu = async () => {
  try {
    const omited = [...OMITED_FIELDS]
    if (placementGenericEnabled) {
      omited.push('items.hero_product', 'items.items.hero_product')
    }
    const response = await Query
      .megaMenus()
      .omit(omited)
      .whereIn('key', ['main-menu', 'header-menu', 'bottom-menu', 'footer-menu'])
      .get()
    const menus = {}
    for (let i = 0; i < response.length; i++) {
      switch (response[i].key) {
        case 'main-menu':
          response[i].title = 'Eshop'
          menus.mainMenu = mapDataToMegamenu(response[i])
          break
        case 'header-menu':
          menus.headerMenu = mapDataToMegamenu(response[i])
          break
        case 'bottom-menu':
          menus.bottomMenu = mapDataToMegamenu(response[i])
          break
        case 'footer-menu':
          menus.footerMenu = mapDataToMegamenu(response[i])
          break
      }
    }
    return { menus }
  } catch (e) {
    logger.error('getMegamenu() failed: %s', e.message)

    return { menus: [] }
  }
}
