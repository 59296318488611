import logger from '@nsf/base/logger.js'
import { withDefault } from '@nsf/core/Mapper.js'
import { countriesQuery } from '@ioc/graphql/queries.js'
import { useCountryFlags } from '@nsf/use/composables/useCountryFlags.js'
import { normalizeRomanianSpecialChars } from '@nsf/base/utils/UtilFunctions.js'
import { mapDataToCountry } from '../mappers/CountryMapper.js'

const { isRO } = useCountryFlags()

export const getDefaultCountry = () => ({
  availableRegions: [],
  id: '',
  fullNameEnglish: '',
})

export const getCountries = async () => {
  try {
    const response = await countriesQuery().get()

    const countries = withDefault(getDefaultCountry, mapDataToCountry(response))

    return { countries }
  } catch (e) {
    logger.withTag('CountriesRepository').error('getCountries() failed: %s', e.message)

    return { countries: [] }
  }
}

export const getCountryData = async (countryCode) => {
  const { countries } = await getCountries()

  const { availableRegions = [] } = countries.find(({ id }) => id === countryCode) ?? {}

  return {
    countries,
    availableRegions: availableRegions.map(({
      id, code, name,
    }) => ({
      id,
      value: code,
      label: isRO ? normalizeRomanianSpecialChars(name) : code,
    })),
  }
}
