/* eslint-disable camelcase */
import { Mutation, field, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import cartAvailablePaymentMethods from '../fragments/cartAvailablePaymentMethods.js'
import cartAvailableShippingMethods from '../fragments/cartShippingAddressesShippingMethodsOnly.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import cartPrices from '../fragments/cartPrices.js'
import cartItemsModified from '../fragments/cartItemsModified.js'

export default () => Mutation.named('setShippingMethodsOnCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    shipping_methods: [],
  })
  .fields([
    field('cart', [
      ...cartItemsModified,
      cartAvailablePaymentMethods,
      cartAvailableShippingMethods,
      cartSelectedPaymentMethod,
      cartPrices,
      field('order_type'),
    ]),
  ])
