import {
  field, Query, variable,
} from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { tags } from '../fragments/tags.js'
import { postFields } from '../fragments/postFields.js'

const {
  articles: {
    readingTimeEnabled,
  },
} = useAppConfig()

export default () => Query.named('postsInfo')
  .where(
    'type',
    variable(
      'type',
      REQUIRED_STRING,
    ),
  )
  .fields([
    field(
      'items',
      [
        field('short_content'),
        ...postFields,
        ...readingTimeEnabled ? [field('reading_time_in_minutes')] : [],
        tags,
      ],
    ),
  ])
  .shouldBeCached(true)
