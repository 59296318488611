import {
  field, Query,
} from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { tags } from '../fragments/tags.js'
import { urlField } from '../fragments/urlField.js'
import { author } from '../fragments/author.js'
import { menuRoot } from '../fragments/menuRoot.js'
import categoryFields from '../fragments/categoryFields.js'
import { postFields } from '../fragments/postFields.js'

const {
  articles: {
    adultsOnlyEnabled,
    menuRootEnabled,
    themeClassEnabled,
    readingTimeEnabled,
    socialNetworksEnabled,
  },
} = useAppConfig()

export default () => Query.named('postPreviewInfo')
  .fields([
    field('full_content'),
    field('meta_description'),
    field('meta_title'),
    field('drmax_new_design'),
    ...postFields,
    author,
    ...menuRootEnabled ? [menuRoot] : [],
    ...readingTimeEnabled ? [field('reading_time_in_minutes')] : [],
    ...themeClassEnabled ? [field('theme_class')] : [],
    ...socialNetworksEnabled
      ? [
          field(
            'social_networks',
            [field('name')],
          ),
        ]
      : [],
    ...adultsOnlyEnabled ? [field('drmax_adults_only')] : [],
    field(
      'categories',
      [
        ...categoryFields,
        field(
          'parent',
          [...categoryFields],
        ),
      ],
    ),
    field(
      'main_category',
      [
        ...categoryFields,
        field(
          'parent',
          [...categoryFields],
        ),
      ],
    ),
    field(
      'related_posts',
      [
        field('meta_description'),
        field('meta_title'),
        field('short_content'),
        ...postFields,
        urlField,
        ...readingTimeEnabled ? [field('reading_time_in_minutes')] : [],
        tags,
      ],
    ),
    tags,
  ])
