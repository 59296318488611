/* eslint-disable no-param-reassign */
export const state = () => ({
  selectedCustomOptions: {},
})

export const mutations = {
  selectCustomOption: (moduleState, { optionId, valueId }) => {
    moduleState.selectedCustomOptions[optionId] = valueId
    moduleState.selectedCustomOptions = { ...moduleState.selectedCustomOptions }
  },

  resetCustomOptions: (moduleState) => {
    moduleState.selectedCustomOptions = {}
  },
}

export const getters = {
  getSelectedCustomOptions: (moduleState) => moduleState.selectedCustomOptions,
}
