export default ({ app }) => {
  app.router.beforeEach((
    _, from, next,
  ) => {
    if (window.LUX) {
      if (from.name) {
        window.LUX.send()
      }
      window.LUX.init()
    }
    next()
  })
}
