import { Query, variable, field } from '@nsf/core/GraphQL.js'
import { REQUIRED_INT } from '@nsf/core/consts/GraphQLTypes.js'
import baseFields from '../fragments/baseFields.js'
import tags from '../fragments/tags.js'
import pharmacistsFields from '../fragments/pharmacistsFields.js'

export default () => Query.named('faqQuestions')
  .where('page_number', variable('pageNumber', REQUIRED_INT))
  .fields([
    field('count'),
    field('items', [
      ...baseFields,
      tags,
      field('pharmacist', pharmacistsFields),
    ]),
  ])
  .shouldBeCached(true)
