/* eslint-disable camelcase */
import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import {
  BOOLEAN, REQUIRED_BOOLEAN, REQUIRED_STRING, STRING,
} from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import cartBillingAddress from '../fragments/cartBillingAddress.js'
import cartShippingAddressesShippingMethodsOnly from '../fragments/cartShippingAddressesShippingMethodsOnly.js'
import cartAvailablePaymentMethods from '../fragments/cartAvailablePaymentMethods.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import cartAppliedPaymentRestrictions from '../fragments/cartAppliedPaymentRestrictions.js'
import marketplaceQuoteSimple from '../fragments/marketplaceQuoteSimple.js'

const {
  checkout: {
    features: {
      customerNotesEnabled,
      itInvoicingEnabled,
      privateInvoiceEnabled,
      streetNumberToMagentoEnabled,
    },
  },
  rootConfig: {
    global: {
      crnEnabled,
      regionSelectEnabled,
      skDicEnabled,
      streetNumberEnabled,
      vatIdEnabled,
    },
  },
} = useAppConfig()

const {
  public: {
    marketplaceEnabled,
  },
} = useRuntimeConfig()

export default () => Mutation.named('setBillingAddressOnCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    billing_address: {
      address: {
        city: variable('city', STRING),
        company: variable('companyName', STRING),
        country_code: variable('countryCode', REQUIRED_STRING),
        ...customerNotesEnabled && { customer_notes: variable('customerNotes', STRING) },
        ...crnEnabled && { drmax_crn: variable('companyRegistrationNumber', STRING) },
        ...itInvoicingEnabled && {
          drmax_fiscal_code: variable('itFiscalCode', STRING),
          drmax_invoice_pec: variable('itPec', STRING),
          drmax_invoice_sdi: variable('itSdi', STRING),
        },
        ...privateInvoiceEnabled && {
          drmax_invoice_recipient_type: variable('invoiceRecipientType', 'DrMaxInvoiceRecipientType'),
          drmax_invoice_requested: variable('isInvoiceRequested', BOOLEAN),
        },
        ...skDicEnabled && { drmax_sk_dic: variable('skDic', STRING) },
        ...streetNumberToMagentoEnabled && streetNumberEnabled && { drmax_sk_house_number: variable('streetNumber', STRING) },
        ...vatIdEnabled && { drmax_vat_id: variable('vatId', STRING) },
        firstname: variable('firstname', REQUIRED_STRING),
        lastname: variable('lastname', REQUIRED_STRING),
        postcode: variable('postcode', STRING),
        ...regionSelectEnabled && { region: variable('regionCode', STRING) },
        street: [variable('street', STRING)],
        telephone: variable('telephone', REQUIRED_STRING),
      },
      use_for_shipping: variable('useForShipping', REQUIRED_BOOLEAN),
    },
  })
  .fields([
    field('cart', [
      cartBillingAddress,
      cartShippingAddressesShippingMethodsOnly,
      cartAvailablePaymentMethods,
      cartAppliedPaymentRestrictions,
      cartSelectedPaymentMethod,

      ...marketplaceEnabled ? [marketplaceQuoteSimple] : [],
    ]),
  ])
