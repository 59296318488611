export default {
  carrierCodes: {
    clickAndCollect: {
      pickupPlaceCarrierCode: 'drmaxpickupplace',

      pharmacyHookCode: 'pharmacy',
    },
    limitedByZipCode: {},
    timeSlotDelivery: {},
  },
}
