import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import maps from './maps.mjs'

export default defineAppConfig({
  excludeRegionIds: [],
  filters: [],
  ...maps,
  pickupListMaxLength: 200,
  pickupListPageSize: 20,
  ...config,
  createPickupPlaceTitleFromAddress: ({ street, city, externalId }) => [street, city, externalId].filter(Boolean).join(', '),
})
