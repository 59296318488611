import { registerPageResolver } from '@nsf/core/PageResolver.js'
import { CategoryFilterBuilder } from '@nsf/catalog/builders/CategoryFilterBuilder.js'

export default function () {
  registerPageResolver(
    'DRMAX_FILTER_PAGE_CONTENT',
    (id, url, ctx) => new CategoryFilterBuilder(id, url, ctx).build(),
    () => import(/* webpackChunkName: "dynamicPages/CATEGORY" */ '@nsf/catalog/dynamicPages/CategoryDetail.vue'),
  )
}
