import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import cartItemsModified from './cartItemsModified.js'
import cartPrices from './cartPrices.js'
import cartAvailablePaymentMethods from './cartAvailablePaymentMethods.js'
import cartAppliedPaymentRestrictions from './cartAppliedPaymentRestrictions.js'
import cartBoundProducts from './cartBoundProducts.js'
import cartSelectedPaymentMethod from './cartSelectedPaymentMethod.js'
import cartFeatures from './cartFeatures.js'
import cartShippingAddressesShippingMethodsOnly from './cartShippingAddressesShippingMethodsOnly.js'

const {
  checkout: {
    features: {
      boundProductsEnabled,
    },
  },
} = useAppConfig()

const {
  public: { marketplaceEnabled },
} = useRuntimeConfig()

export default [
  field('cart', [
    field('order_type'),
    ...cartItemsModified,
    cartShippingAddressesShippingMethodsOnly,
    cartPrices,
    cartAvailablePaymentMethods,
    cartAppliedPaymentRestrictions,
    cartSelectedPaymentMethod,
    ...boundProductsEnabled ? [cartBoundProducts] : [],
    ...marketplaceEnabled ? [field('drmax_quote_type')] : [],
    cartFeatures,
  ]),
]
