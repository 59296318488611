import { defineNuxtConfig } from '@nsf/use/composables/defineNuxtConfig.js'

export default defineNuxtConfig({
  runtimeConfig: {
    elasticAuthPwd: '',
    elasticAuthUser: 'nsf-elasticsearch',
    public: {
      elasticUrl: 'http://eck-magento-es-http.magento-elasticsearch.svc.cluster.local:9200',
    },
  },
})
