/* eslint-disable camelcase */
import { Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import cartCouponsModified from '../fragments/cartCouponsModified.js'

export default () => Mutation.named('removeCouponFromCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    cart_coupons: variable('cartCoupons', REQUIRED_STRING),
  })
  .fields(cartCouponsModified)
