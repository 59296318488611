import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { toOpeningHours, pharmacyIsOpened } from '../utils/DateUtils.js'

const {
  rootConfig: {
    global: {
      pharmacyTypes,
    },
  },
  apothekaWeb: {
    pharmacySelect: {
      excludeRegionIds,
    },
  },
} = useAppConfig()

const servicesToAdditionalParams = (additionalParams, services) => {
  const s = {
    babyFood: false,
    dermo: false,
    personalPick: false,
  }
  services.forEach((item) => {
    if (item.websiteVisibility === true) {
      // eslint-disable-next-line default-case
      switch (item.serviceId) {
        case 'BABY_FOOD': s.babyFood = true; break
        case 'DERMATHOLOGY': s.dermo = true; break
        case 'PERSONAL_PICK': s.personalPick = true; break
      }
    }
  })

  return {
    ...additionalParams,
    babyFood: s.babyFood,
    dermo: s.dermo,
    personalPick: s.personalPick,
  }
}

/**
 * Used for Pharmacy API
 *
 * @param {Object[]} responseJson
 * @param {Boolean} onlyDermocenters
 *
 * @returns {Object[]}
 */
export default function (responseJson, onlyDermocenters) {
  if (onlyDermocenters) {
    // eslint-disable-next-line no-param-reassign
    responseJson.data = responseJson.data.filter((element) => element?.services.find((item) => item?.serviceId === 'DERMATHOLOGY'))
  }

  const pharmacies = []

  // eslint-disable-next-line complexity
  responseJson.data.forEach((pharmacy) => {
    let correctPharmacy = true
    if (excludeRegionIds.length > 0) {
      correctPharmacy = !excludeRegionIds.includes(pharmacy.additionalParams?.regionId?.toString())
    }

    if (correctPharmacy && pharmacyTypes.length > 0) {
      correctPharmacy = pharmacyTypes.includes(pharmacy.pharmacyType?.toString())
    }

    if (correctPharmacy) {
      const pickupPlaceId = String(pharmacy.location?.id || pharmacy.id)

      let pharmacyTitle = pharmacy.additionalParams?.pharmacyPublicNameWeb
      if (!pharmacyTitle) {
        pharmacyTitle = pharmacy.pharmacyPublicName
      }

      const pharmacyOpened = pharmacyIsOpened(pharmacy)

      /* eslint-disable sort-keys */
      pharmacies.push({
        pickupPlaceId,
        key: `pharmacy-${pickupPlaceId}`,
        street: pharmacy.location?.street || pharmacy.street,
        city: pharmacy.location?.city || pharmacy.city,
        zip: pharmacy.location?.zipCode || pharmacy.zipCode,
        district: pharmacy.additionalParams?.district || null,
        region: pharmacy.location?.region || pharmacy.region,
        regionId: pharmacy.additionalParams?.regionId || null,
        reservationAvailable: pharmacy.otcReservation?.enabled || false,
        limitReservation: pharmacy.otcReservation?.limits || null,
        reservationStatus: pharmacy.otcReservation?.enabled ? 'OK' : 'disabled',
        orderAvailable: pharmacy.order?.enabled || false,
        limitOrder: pharmacy.order?.limit || null,
        pharmacyStatus: pharmacy.order?.enabled ? 'OPEN' : 'disabled',
        reservationRXAvailable: pharmacy.rxReservation?.enabled || false,
        limitRXReservation: pharmacy.rxReservation?.limits || null,
        reservationOTCAvailable: pharmacy.otcReservation?.enabled || false,
        limitOTCReservation: pharmacy.otcReservation?.limits || null,
        callcentrumAvailable: false,
        latitude: Number(pharmacy.location?.latitude || pharmacy.latitude),
        longitude: Number(pharmacy.location?.longitude || pharmacy.longitude),
        priority: 1,
        phone: pharmacy.phoneNumbers?.[0]?.number || null,
        note: pharmacy.note,
        title: pharmacyTitle,
        urlKey: pharmacy.urlKey,
        openingDate: pharmacy.openingDate,
        closureDate: pharmacy.closureDate,
        pharmacistName: pharmacy.manager,
        pharmacistImageUrl: pharmacy.managerImage,
        pharmacistChiefName: null,
        pharmacistChiefImageUrl: null,
        pharmacistExpertName: pharmacy.manager,
        pharmacistExpertImageUrl: pharmacy.managerImage,
        pharmacyImageUrl: pharmacy.pharmacyImage,
        shoppingMall: pharmacy.location?.description || pharmacy.description,
        description: null,
        destinationId: pharmacy.additionalParams?.destinationId || null,
        htmlId: `pharmacy${pharmacy.location?.id || pharmacy.id}`,
        open: pharmacyOpened,
        openHours: pharmacyOpened ? toOpeningHours(pharmacy.openingHours) : [],
        type: 'pharmacy',
        leadTimeReservationRX: pharmacy.rxReservation?.leadTime || null,
        leadTimeReservationOTC: pharmacy.otcReservation?.leadTime || null,
        leadTimeOrders: pharmacy.order?.leadTime || null,
        disabled: false,
        hidden: pharmacy.pharmacyVisibility?.pharmacyList === false,
        // eslint-disable-next-line camelcase
        pharmacy_type: pharmacy.pharmacyType,
        additionalParams: servicesToAdditionalParams(pharmacy.additionalParams || {}, pharmacy.services || []),
        services: pharmacy.services || [],
      })
    }
  })

  return pharmacies
}
