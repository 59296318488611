import { envBool } from '@nsf/utils/EnvUtils.js'

export default {
  megamenu: {
    largeItems: 6,
    visibleSubItems: 4,
    mobile: {
      showThirdLevel: envBool(process.env.BASE_MEGAMENU_MOBILE_SHOW_THIRD_LEVEL, true),
    },
    isHeroProductInKebabCase: envBool(process.env.HERO_PRODUCT_IN_KEBAB_CASE_ENABLED, false),
  },
}
