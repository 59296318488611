import { mapDataToModel } from '@nsf/core/Mapper.js'

/**
 * @typedef Point
 * @property {number} id
 * @property {number} latitude
 * @property {number} longitude
 * @property {string} title
 * @property {string} type - e.g. `pharmacy`, `post-office`
 */

// noinspection SpellCheckingInspection
/**
 * @param data
 * @returns {Point|Point[]}
 */
export const mapDataToPoints = (data) => mapDataToModel(data, {
  pickupPlaceId: String,
  pickupPlaceLatitude: ['latitude', Number],
  pickupPlaceLongtitude: ['longitude', Number], // typo in GraphQL API
  latitude: ['latitude', Number],
  longtitude: ['longitude', Number], // typo in GraphQL API
  pickupPlaceTitle: 'title',
  type: 'type',
})
