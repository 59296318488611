/* eslint-disable camelcase */
import { Mutation, field, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('setMarketplaceShippingMethodOnCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    shipping_method: {
      carrier_code: variable('carrier', REQUIRED_STRING),
      method_code: variable('method', REQUIRED_STRING),
    },
  })
  .fields([
    field('cart', [
      field('marketplace_quote', [
        field('marketplace_quote_address', [
          field('selected_shipping_method', [
            field('carrier_code'),
            field('method_code'),
          ]),
        ]),
      ]),
    ]),
  ])
