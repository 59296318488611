export default {
  navigation: {
    HEADER_OVERRIDE: {},
    RELATIVE_URL_OVERRIDE: [
      'https://stage-nsf-local-cz.cz.drmax.net',
      'https://www.drmax.cz',
    ],
    SERVER_NAVIGATION_NO_RENDER: [
      'AMASTY_BLOG_CATEGORY',
      'AMASTY_BLOG_POST',
    ],
  },
}
