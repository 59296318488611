import {
  sliceSlashFromUrl, isExternalLink, sliceHashPartFromUrl, sliceQueryPartFromUrl,
} from '@nsf/utils/UrlUtils.js'
import { getUrlPrefix } from '@nsf/catalog/utils/BuilderUtils.js'
import { getAttributeOptionByUrlPrefix } from '@nsf/catalog/repositories/AttributeRepository.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { useLogger } from '@nsf/use/composables/useLogger.js'
import { isNullish, isEmpty, toCamel } from './Utils.js'
import { urlResolverQuery } from './gql/queries/urlResolver.js'

const logger = useLogger('page-resolver')

const { public: { pageResolver } } = useRuntimeConfig()

const registry = {}
const extensions = {}

export const registerPageResolver = (type, resolver, component) => {
  if (!registry[type]) {
    registry[type] = {
      resolver,
      component,
    }
  }
}

export const registerExtension = (name, extension) => {
  if (!extensions[name]) {
    extensions[name] = extension
  }
}

const graphql = async (url) => {
  try {
    const { type, id, relative_url: relativeUrl } = await urlResolverQuery.bind({ url }).get()

    return {
      type, id, relativeUrl,
    }
  } catch (error) {
    logger.error('Error while fetching data from GraphQL: %o', error.message)

    return {
      statusCode: 500,
      statusMessage: 'Internal Server Error',
    }
  }
}

const elastic = async (url) => {
  try {
    const urlResolverResponse = await Query.urlResolver().where('request_url', url)
      .sort('priority', 'desc')
      .first()

    return {
      type: urlResolverResponse?.result_type ?? null,
      id: urlResolverResponse?.result_id ?? null,
      relativeUrl: urlResolverResponse?.result_relative_url ?? null,
    }
  } catch (error) {
    logger.error('Error while fetching data from ElasticSearch: %o', error.message)

    return {
      statusCode: 500,
      statusMessage: 'Internal Server Error',
    }
  }
}

const pageResolvers = {
  graphql,
  elastic,
}

const fetchResponse = async (url) => {
  const cleanUrl = sliceQueryPartFromUrl(sliceHashPartFromUrl(sliceSlashFromUrl(url)))
  let urlResolverResponse = await pageResolvers[pageResolver](cleanUrl)

  if (urlResolverResponse.statusCode >= 400) {
    return urlResolverResponse
  }

  // used for filtering option in url
  const splitUrl = cleanUrl.split('/')
  let urlWithoutPrefix

  if (splitUrl.length > 2) {
    urlWithoutPrefix = splitUrl.splice(1).join('/')
  } else {
    urlWithoutPrefix = splitUrl.pop()
  }

  const isFoundByUrlResolver = urlResolverResponse?.type && urlResolverResponse?.id
  const isUrlWithPrefix = urlWithoutPrefix !== cleanUrl

  if (isUrlWithPrefix && !isFoundByUrlResolver) {
    const urlPrefix = getUrlPrefix(cleanUrl)

    if (!urlPrefix) {
      return null
    }

    // check if prefix is valid option or non-sense (should by moved to core)
    const { option } = await getAttributeOptionByUrlPrefix(urlPrefix)

    if (!option || isEmpty(option)) {
      return null
    }

    urlResolverResponse = await pageResolvers[pageResolver](urlWithoutPrefix)

    // check that prefix type is not the same as resolver type
    if (toCamel(option.attributeCode).toLowerCase()
      .includes(toCamel(urlResolverResponse?.type).toLowerCase())) {
      return null
    }
  }

  // eslint-disable-next-line consistent-return
  return urlResolverResponse
}

const redirectToRelativeUrl = (statusCode, relativeUrl) => {
  let redirectUrl = (relativeUrl.startsWith('/') || isExternalLink(relativeUrl)) ? relativeUrl : `/${relativeUrl}`

  if (redirectUrl.length > 1 && redirectUrl.endsWith('/')) {
    redirectUrl = redirectUrl.slice(0, -1)
  }

  return {
    statusCode,
    redirect: redirectUrl,
    data: {},
  }
}

export const resolvePageDataResponse = (ctx, response, options = {
  handleErrors: true,
  handleNotFound: true,
  handleRedirect: true,
}) => {
  if (options.handleErrors && response.statusCode >= 400) {
    return ctx.error({
      statusCode: response.statusCode,
      statusMessage: response.statusMessage,
    })
  }

  if (options.handleRedirect && response.redirect) {
    return ctx.redirect(response.statusCode ?? 301, response.redirect)
  }

  if (response.type === 'CMS_SERVICE_PAGE') {
    const cmsServicePage = response.data
    if (options.handleNotFound && !cmsServicePage?.name) {
      return ctx.error({ data: 404 })
    }
    return { cmsServicePage }
  }

  const cmsPage = response?.data?.cmsPage
  if (options.handleNotFound && !cmsPage?.title) {
    return ctx.error({ data: 404 })
  }
  return { cmsPage }
}

// eslint-disable-next-line complexity
export const resolvePageData = async (ctx) => {
  const url = ctx.route?.fullPath || ctx

  let responses = await Promise.all([
    fetchResponse(url),
    ...Object.values(extensions).map((extension) => extension(ctx)),
  ])

  // Fallback solution for loading CMS page from magento for pages migrated to CMS service,
  // when CMS page source is set to "magento"
  const cmsServicePageResponse = responses
    .find((response) => response?.type === 'CMS_SERVICE_PAGE')

  if (cmsServicePageResponse?.id > 0) {
    const { config } = ctx.store.state['_base/config']
    if (config.cmsPageDataSource === 'magento') {
      responses = await Promise.all([
        fetchResponse(`/MAGENTO-PAGE${url}`),
        ...Object.values(extensions).map((extension) => extension(ctx)),
      ])
    }
  }

  for (const response of responses) {
    if (response?.statusCode >= 400) {
      return response
    }

    const { type, id, relativeUrl } = response || {}

    if (isNullish(type) || isNullish(id)) {
      // eslint-disable-next-line no-continue
      continue
    }

    if (type === 'REDIRECT_301' && relativeUrl) {
      return redirectToRelativeUrl(301, relativeUrl)
    }

    if (type === 'REDIRECT_302' && relativeUrl) {
      return redirectToRelativeUrl(302, relativeUrl)
    }

    if (isNullish(registry[type])) {
      // eslint-disable-next-line no-continue
      continue
    }

    if (ctx?.store) {
      ctx.store.commit('_base/navigation/setState', { resolverType: type, resolverId: id })
    }

    const { resolver } = registry[type]

    // Lets download the component before we return the data to prevent page transition flicker
    // caused when data is already downloaded but component to render them is missing.
    await registry[type].component()
    const data = await resolver(id, url, ctx) || {}

    if (data?.statusCode >= 400) {
      return {
        statusCode: data.statusCode,
        statusMessage: data.statusMessage,
      }
    }

    if (data?.redirect || data?.redirectLocaleName) {
      return {
        redirect: data.redirectLocaleName ? ctx.localePath({ name: data.redirectLocaleName }) : data.redirect,
        statusCode: data.statusCode || 301,
      }
    }

    if (ctx?.store) {
      const resolverTitle = (() => {
        switch (type) {
          case 'AMASTY_BLOG_POST':
            return data?.article?.title
          case 'BRAND':
            return data?.name
          case 'CMS_PAGE':
            return data?.cmsPage?.title
          case 'CMS_SERVICE_PAGE':
            return data?.name
          default:
            return null
        }
      })()

      ctx.store.commit('_base/resolver/setState', {
        resolverTitle,
      })

      if (type === 'CMS_SERVICE_PAGE' && data?.attributes?.noHeader) {
        ctx.store.commit('setNoHeader', true)
      }

      if (type === 'CMS_SERVICE_PAGE' && data?.attributes?.noFooter) {
        ctx.store.commit('setNoFooter', true)
      }

      if (type === 'CMS_SERVICE_LINK_TREE') {
        ctx.store.commit('setNoHeader', true)
        ctx.store.commit('setNoFooter', true)
      }
    }

    return { type, id, data }
  }

  return {
    statusCode: 404,
  }
}

export const resolvePageComponent = (type) => {
  try {
    return registry[type].component
  } catch {
    logger.error('Missing component for UrlResolver type "%s"', type)

    return null
  }
}
