import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'

const pushEventUserLogout = async() => {
  const event = {
    event: 'logout',
    user: undefined,
  }
  usePushEvent(event)
}

export default pushEventUserLogout
