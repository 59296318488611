/* eslint-disable camelcase */
import { mapDataToModel } from '@nsf/core/Mapper.js'
import { LOADER_LABEL_MAGENTO } from '@nsf/catalog/mixins/IsProduct.js'

export const mapDataToHeroProduct = (data, translations = {}) => mapDataToModel({
  ...data,
  loaderLabel: data.loaderLabel || LOADER_LABEL_MAGENTO,
}, {
  link: ['urlPath', (urlPath) => urlPath],
  finalPrice: ['finalPrice', (price) => +price],
  regularPrice: ['regularPrice', (price) => +price],
  specialPrice: ['specialPrice', (price) => +price],
  attributeSetId: ['attributeSetId', (id) => +id],
  ...translations,
})

export const removeUnusedFields = (obj) => {
  const FIELDS_TO_KEEP = [
    'id',
    'entityId',
    'parentId',
    'title',
    'cssClass',
    'link',
    'key',
    'image',
    'value',
    'urlPath',
    'heroProduct',
    'hero_product',
  ]

  const HERO_PIM_STATUS_TO_KEEP = [
    'Visible',
    'Available',
    'Special sale off',
    'Temporary unavailable',
  ]

  for (const prop in obj) {
    if (['heroProduct', 'hero_product'].includes(prop)) {
      if (!HERO_PIM_STATUS_TO_KEEP.includes(obj[prop].drmaxPimStatus)) {
        // eslint-disable-next-line no-param-reassign
        delete obj[prop]
      }
    }
    if (FIELDS_TO_KEEP.includes(prop)) {
      continue
    }
    if (typeof obj[prop] === 'object') {
      removeUnusedFields(obj[prop])
      continue
    }
    // eslint-disable-next-line no-param-reassign
    delete obj[prop]
  }
}

// hero products on CZ uses local mixin with kebab-case / common uses camelCase
export const mapDataToMegamenu = (data, translations = { hero_product: ['heroProduct', mapDataToHeroProduct] }) => {
  const megamenu = mapDataToModel(data, {
    url_path: ['link', (link) => `/${link}`],
    value: 'link',
    ...translations,
  })
  removeUnusedFields(megamenu)
  return megamenu
}
