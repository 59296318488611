import { setState } from '@nsf/core/Store.js'
import { isEmpty } from '@nsf/core/Utils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { PHARMACY } from '@nsf/checkout/utils/StoreUtils.js'
import { getPharmacies } from '../../repositories/PharmacyRepository.js'

const {
  checkout: {
    carrierCodes: {
      clickAndCollect: {
        pharmacyCollectCarrierCode,
      },
    },
  },
} = useAppConfig()

export const state = () => ({
  /** Place objects. To be shown on a map. Created based on points (simpler objects). */
  places: [],

  /** Pharmacies fetched from the API, cached until page refresh */
  pharmaciesCache: [],
})

export const actions = {
  /**
   * Do not call directly!
   */
  initPlaces: async ({
    commit, _, rootGetters, state, dispatch,
  }, { queryParams = '' }) => {
    const cached = state.pharmaciesCache
    const isCacheAvailable = cached && !isEmpty(cached)
    const graphqlConfig = rootGetters['_base/config/config']
    let places

    if (isCacheAvailable) {
      places = cached
    } else {
      places = await getPharmacies(graphqlConfig, queryParams)

      for (const i in places) {
        places[i].type = PHARMACY
      }

      commit('setPharmaciesCache', { places })
    }

    dispatch('setCheckoutPickupIds', { carrierCode: pharmacyCollectCarrierCode, places })
    commit('setState', { places })
  },

  setCheckoutPickupIds: ({ commit, rootState }, { carrierCode, places }) => {
    commit(
      'checkout/pickupIds/setPickupIdsStateGeneric',
      { path: 'pickupIds', value: { ...rootState['checkout/pickupIds/pickupIds'], [carrierCode]: places } },
      { root: true },
    )
  },
}

export const mutations = {
  setState,

  setPlaces: (state, payload) => {
    state.places = payload
  },

  clearPlaces: (state) => {
    state.places = []
  },

  setPharmaciesCache: (state, { places }) => {
    state.pharmaciesCache = places
  },
}
