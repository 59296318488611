export const getBoundProductObjectBySku = (
  sku, boundProducts,
) => {
  if (!boundProducts) {
    return null
  }

  for (const boundProduct of boundProducts) {
    if (boundProduct?.product?.sku === sku) {
      return boundProduct
    }
  }
  return null
}

export const mapDepositFeePrice = (
  itemGroups, boundProductsObjects,
) => {
  for (const itemGroup of itemGroups) {
    for (const item of itemGroup.items) {
      item.prices.depositFee = 0

      if (item.product?.boundProductsSku) {
        for (const boundProductSku of item.product?.boundProductsSku) {
          const product = getBoundProductObjectBySku(
            boundProductSku,
            boundProductsObjects,
          )
          item.prices.depositFee += product?.prices?.price?.value * item.quantity
        }
      }
    }
  }

  return itemGroups
}
