/* eslint-disable no-param-reassign */
import logger from '@nsf/catalog/logger.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { withDefault, mapDataToModel } from '@nsf/core/Mapper.js'
import { isNullish } from '@nsf/core/Utils.js'
import { ensureArray } from '@nsf/utils/ArrayUtils.js'
import { mapDataToAttribute, mapDataToFilterOption, mapDataToOption } from '@nsf/catalog/mappers/AttributeMapper.js'

export const getDefaultAttribute = () => ({
  code: '',
  label: '',
  options: [],
  usedInProductListing: false,
  isVisibleInDescriptionTab: false,
})

export const getFilterableAttributes = async (productsQuery) => {
  try {
    const response = await Query.attributes()
      .only('attribute_code', 'attribute_id', 'frontend_label', 'frontend_input', 'position')
      .where('is_filterable', 1)
      .exists('options')
      .getAll()

    const query = Query.fromString(productsQuery)
      .without('drmax_show_in_listing')

    response.forEach((attribute) => query.aggregate(attribute.attribute_code, 1000))

    const aggregations = await query.aggregations()

    const attributes = withDefault(getDefaultAttribute, mapDataToAttribute(response))
      .sort((a, b) => a.position - b.position)
      .filter(
        (attribute) => {
          const buckets = aggregations[attribute.code]?.buckets ?? []

          if (attribute.input === 'boolean') {
            return !!buckets.find((item) => +item.key === 1)
          }
          return buckets.length > 0
        },
      )
      .map((attribute) => {
        attribute.options = aggregations[attribute.code]?.buckets.map((bucket) => ({
          value: `${bucket.key}`,
          count: bucket.doc_count,
        }))
        return attribute
      })
    return { attributes }
  } catch (e) {
    logger.error('getFilterableAttributes() failed: %s', e.message)

    return { attributes: [] }
  }
}

// get count for options of a given attributes including all query filters
export const getAttributeOptionsCountWithFilter = async (attributeCodes, productsQuery, ranges) => {
  try {
    if (attributeCodes.length === 0) {
      return {}
    }
    const query = Query.fromString(productsQuery)
      .without('drmax_show_in_listing')
    attributeCodes.forEach((attributeCode) => {
      if (attributeCode === 'final_price') {
        query.aggregateRange(attributeCode, ranges)
      } else {
        query.aggregate(attributeCode, 1000)
      }
    })
    const attributeCounts = await query.aggregations()

    return attributeCounts ?? {}
  } catch (e) {
    logger.error('getAttributeOptionsCountWithFilter() failed: %s', e.message)
    return {}
  }
}

// get count for options of a given attribute excluding the attribute from query filter
export const getAttributeOptionsCount = async (attributeCode, productsQuery, ranges) => {
  try {
    const query = Query.fromString(productsQuery)
      .without(attributeCode)
      .without('drmax_show_in_listing')

    if (attributeCode === 'final_price') {
      query.aggregateRange(attributeCode, ranges)
    } else {
      query.aggregate(attributeCode, 1000)
    }

    const optionCounts = await query
      .aggregations()

    return optionCounts?.[attributeCode] ?? {}
  } catch (e) {
    logger.error('getAttributeOptionsCount() failed: %s', e.message)
    return {}
  }
}

export const getOptionsForAttributes = async (attributeCodes) => {
  try {
    const attrCodes = ensureArray(attributeCodes)
    const query = Query.attributes()
      .only('options', 'attribute_code', 'frontend_input', 'frontend_label')
      .whereIn('attribute_code', attrCodes)

    const result = await query.getAll()

    return { attributesWithOptions: result }
  } catch (e) {
    logger.error('getOptionsForAttributes() failed: %s', e.message)

    return { attributesWithOptions: {} }
  }
}

// obsolete
export const getOptionsForAttributesWithCount = async (attributeCodes, query) => {
  try {
    const attrCodes = ensureArray(attributeCodes)
    const resAttr = Query.attributes()
      .only('options')
      .whereIn('attribute_code', attrCodes)

    const resAggr = Query.fromString(query)
      .without('eLeader')
      .without('topProducts')
      .without('promoProducts')
      .without('drmax_show_in_listing')

    attrCodes.forEach((code) => resAggr.without(code).aggregate(code, 2000))

    const [resOptions, resAggregations] = await Promise.all([resAttr.getAll(), resAggr.aggregations()])

    const options = mapDataToOption(resOptions.flatMap((opt) => opt?.options || []))
      .filter((option) => {
        if (!option) {
          return undefined
        }
        Object.keys(resAggregations).forEach((aggregationCode) => {
          const aggCodeEntity = ensureArray(resAggregations[aggregationCode]?.buckets)
            .find((agg) => String(agg.key) === String(option.value))
          if (!isNullish(aggCodeEntity)) {
            option.count = aggCodeEntity.doc_count
            option.attributeCode = aggregationCode
          }
        })
        return !!option.count
      })

    return { options }
  } catch (e) {
    logger.error('getOptionsForAttributeWithCount() failed: %s', e.message)

    return { options: [] }
  }
}

export const getAttributeOptionsByAttributeName = async (currentAttributes) => {
  const filterQueries = []
  currentAttributes.forEach((tag) => {
    if (tag.attribute === 'category_id') {
      filterQueries.push(
        Query.categories()
          .only('name', 'options', 'frontend_label', 'frontend_input')
          .whereIn('id', tag.optionId),
      )
    }

    filterQueries.push(Query.attributes()
      .only('attribute_code', 'options', 'frontend_label', 'frontend_input')
      .where('attribute_code', tag.attribute))
  })
  const attributes = await Promise.all(filterQueries.map((e) => e.first()))

  return withDefault(getDefaultAttribute, mapDataToAttribute(attributes.filter((e) => e)))
}

export const getAttributeOptionByUrlPrefix = async (prefix) => {
  try {
    const attribute = await Query.attributes()
      .where('options.url_key', prefix)
      .get()

    const option = attribute[0]?.options?.find((opt) => opt.url_key === prefix) ?? {}
    // eslint-disable-next-line camelcase
    option.attribute_code = attribute[0]?.attribute_code
    return { option: mapDataToFilterOption(option) }
  } catch (e) {
    logger.error('getAttributeOptionByUrlPrefix() failed: %s', e.message)

    return { option: {} }
  }
}

export const getAttributeById = async (id) => {
  try {
    const attribute = await Query.attributes()
      .where('attribute_id', id)
      .get()

    return mapDataToModel(attribute[0])
  } catch (e) {
    logger.error('getAttributeById() failed: %s', e.message)

    return { }
  }
}
