export const state = () => ({
  productDetailVersion: '',
})

export const mutations = {
  // eslint-disable-next-line no-shadow
  setProductDetailVersion(state) {
    const convertTests = document.convert?.data?.experiences || []
    if (!convertTests.length) {
      state.productDetailVersion = 'A'
      return
    }
    // TODO we wait for Michal to add some data to convert, so that we can use it
    // unknown variables: key, variations
    // convertTests.forEach((test) => {
    //   if (test.key === 'ab-test-product-detail') {
    //     state.productDetailVersion = test?.variations?.[0] || 'A'
    //   }
    // })
  },
}
