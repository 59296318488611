import { mapDataToModel } from '@nsf/core/Mapper.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    features: {
      streetNumberToMagentoEnabled,
    },
  },
  rootConfig: {
    global: {
      streetNumberEnabled,
    },
  },
} = useAppConfig()

export const mapDataToBillingAddress = (data, translations = {}) => {
  const {
    street: [street] = [],
    streetNumber,
    city,
    postcode,
    region: { code: regionCode } = {},
    country: { code: countryCode } = {},
    customerNotes,
  } = mapDataToModel(data, { ...translations })

  return {
    street: street?.trim() ?? '',
    streetNumber,
    city,
    postcode,
    regionCode,
    countryCode,
    customerNotes,
  }
}

export const mapStreet = (address) => ({
  ...address,
  street: ((streetNumberEnabled && !streetNumberToMagentoEnabled) ? `${address.street} ${address.streetNumber}` : address.street),
})
