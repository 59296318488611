import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  base: {
    currency,
  },
} = useAppConfig()

export const getDefaultPrices = () => ({
  appliedTaxes: [],
  grandTotal: {
    value: 0,
    currency,
  },
  subtotalExcludingTax: {
    value: 0,
    currency,
  },
  subtotalIncludingTax: {
    value: 0,
    currency,
  },
  subtotalWithDiscountExcludingTax: {
    value: 0,
    currency,
  },
  subtotalWithDiscountIncludingTax: {
    value: 0,
    currency,
  },
})

export const cartItemsAndPricesDefaultResponse = {
  itemGroups: [],
  appliedCoupons: [],
  appliedGiftCards: [],
  loyaltyProgram: {},
  freeShipping: {},
  shippingMethods: [],
  prices: getDefaultPrices(),
  isReservationAllowed: true,
}
