import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('repayOrder')
  .where('input', {
    hash: variable('hash', REQUIRED_STRING),
  })
  .fields([
    field('order', [
      field('currency'),
      field('date'),
      field('delivery_address', [
        field('city'),
        field('post_code'),
        field('street'),
      ]),
      field('discount_coupons'),
      field('drmax_gateway_method'),
      field('drmax_repay_url'),
      field('grand_total'),
      field('order_id'),
      field('order_items', [
        field('item_id'),
        field('name'),
        field('product_id'),
        field('product_type'),
        field('qty_ordered'),
        field('base_price_incl_tax'),
        field('original_price'),
        field('tax_percent'),
      ]),
      field('order_type'),
      field('payment_method'),
      field('shipping_method'),
      field('shipping_price'),
      field('drmax_pickup_place_id'),
      field('revenue', [
        field('value'),
      ]),
      field('tax'),
      field('time'),
      field('user_id'),
      field('user_email'),
    ]),
  ])
