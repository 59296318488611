import Vue from 'vue'
import VueLazyHydration from 'vue-lazy-hydration'
import Link from '@nsf/base/shared/Link.vue'
import LazyImage from '@nsf/base/shared/LazyImage.vue'

export default function() {
  Vue.component('VLazyHydrate', VueLazyHydration)
  Vue.component('VLink', Link)
  Vue.component('VLazyImage', LazyImage)
}
