import _drmaxPackage from 'drmax/.nsf/drmax.package.mjs'
import { useDeepFreeze } from '@nsf/use/utils/useDeepFreeze.js'

// Freezing of loaded drmax.package data
const drmaxPackage = useDeepFreeze(_drmaxPackage)

// TODO: rework it and use nuxt/kit and template generation in module
export const useDrmaxPackage = () => drmaxPackage

export default useDrmaxPackage
