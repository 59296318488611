import { useMutationID } from '@nsf/use/composables/useMutationID.js'

/**
 * Function returns an object containing country-specific flags
 * based on a mutation identifier/ID
 *
 * @returns {{
 *   isApteka: boolean,
 *   isCZ: boolean,
 *   isDrmaxPL: boolean,
 *   isDrogeria: boolean,
 *   isIT: boolean,
 *   isPL: boolean,
 *   isRO: boolean,
 *   isSK: boolean,
 * }}
 */
export const useCountryFlags = () => {
  const mutationID = useMutationID()
  return {
    isApteka: mutationID === 'pl-apteka',
    isCZ: mutationID === 'cz',
    get isDrmaxPL() {
      return this.isPL && !this.isApteka && !this.isDrogeria
    },
    isDrogeria: mutationID === 'pl-drogeria',
    isIT: mutationID === 'it',
    isPL: mutationID.startsWith('pl'),
    isRO: mutationID === 'ro-drmax',
    isSK: mutationID === 'sk',
  }
}
export default useCountryFlags
