import { field, variable, Query } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Query.named('stockAlertGetProducts')
  .where('input', {
    email: variable('email', REQUIRED_STRING),
  })
  .fields([
    field('total_count'),
    field('products', [
      field('sku'),
      field('id'),
    ]),
  ])
