import { refreshToken as refreshTokenAuthServer } from '../repositories/authRepository.js'
import { getToken, isUnauthorizedStatus, removeTokens } from './auth.js'

const INVALID_OR_MISSING_MY_ACCOUNT_TOKEN = 'INVALID_OR_MISSING_MY_ACCOUNT_TOKEN'

const getHeaders = () => ({ Authorization: `Bearer ${getToken()}` })

// simulates something like axios request interceptor, but with RestClient requests
const enrichRequestArgsWithAuth = (argumentCount, args) => {
  const newArgs = []

  for (let i = 0; i <= argumentCount; i++) {
    newArgs.push(args[i])
  }

  newArgs[argumentCount] = {
    ...newArgs[argumentCount],
    headers: {
      ...newArgs[argumentCount]?.headers,
      ...getHeaders(),
    },
  }

  return newArgs
}

// works only with RestClient
export function withAuth(requestFn) {
  return async function (...args) {
    let response = await requestFn(
      ...enrichRequestArgsWithAuth(requestFn.length, args),
    )

    if (!response.ok && isUnauthorizedStatus(response.status)) {
      if (response.data?.detail === INVALID_OR_MISSING_MY_ACCOUNT_TOKEN) {
        removeTokens()
      }

      await refreshTokenAuthServer()
      response = await requestFn(...enrichRequestArgsWithAuth(requestFn, args))
    }

    return response
  }
}
