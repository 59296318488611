import { defineNuxtConfig } from '@nsf/use/composables/defineNuxtConfig.js'

export default defineNuxtConfig({
  runtimeConfig: {
    public: {
      cmsServiceHost: 'https://cms-service.drmax-gl.space/v1',
      cmsCdnHost: 'https://cms-cdn-gl.drmax.net',
      cmsUseAnnouncement: false,
    },
  },
})
