import { mapDataToModel } from '@nsf/core/Mapper.js'

export const mapDataToPurchaseHistoryList = (data, translations = {}) => {
  const model = mapDataToModel(data, {
    data: (item) => mapDataToModel(item, {
      orderId: Number,
      channel: String,
      dateTimeCreated: String,
      purchaseHistoryTransactionType: String,
      purchaseHistoryTransactionStatus: String,
      purchaseHistoryTransactionItems: (item) => mapDataToModel(item, {
        itemType: ['type', String],
        orderItemId: Number,
        sku: String,
        productName: String,
        quantity: Number,
        unitPrice: Number,
        unitBasePrice: Number,
        rowDiscount: Number,
        price: Number,
        vatRate: Number,
        ...translations,
      }),
      totalPrice: Number,
      totalDiscount: Number,
      orderDelivered: Boolean,
      ...translations,
    }),
    ...translations,
  })

  model.data.forEach((purchase) => {
    purchase.isReservation = purchase.purchaseHistoryTransactionType === 'reservation'
    purchase.isMpLogistic = purchase.purchaseHistoryTransactionType === 'mp_logistic'
    purchase.purchaseHistoryTransactionItems.forEach((item) => {
      item.additionalData = {}
    })
  })

  return model
}

export const mapDataToPurchase = (data, translations = {}) => {
  const model = mapDataToModel(data, {
    orderId: Number,
    channel: String,
    dateTimeCreated: String,
    dateTimeLastUpdated: String,
    purchaseHistoryTransactionType: String,
    purchaseHistoryTransactionStatus: String,
    purchaseHistoryTransactionItems: (item) => mapDataToModel(item, {
      itemType: ['type', String],
      orderItemId: Number,
      sku: String,
      productName: String,
      quantity: Number,
      unitPrice: Number,
      unitBasePrice: Number,
      rowDiscount: Number,
      price: Number,
      vatRate: Number,
      ...translations,
    }),
    totalPrice: Number,
    totalDiscount: Number,
    orderDelivered: Boolean,
    shippingCharge: Number,
    paymentCharge: Number,
    addresses: (x) => mapDataToModel(x, {
      addressType: String,
      street: String,
      city: String,
      customerEmail: ['email', String],
      firstname: String,
      lastname: String,
      regionCode: ['regionCode', String],
      postalCode: ['zip', String],
      country: String,
      company: ['companyName', String],
      identCode: ['companyRegistrationNumber', String],
      skDic: ['skDic', String],
      vatCode: ['vatId', String],
      phoneNumber: String,
    }),
    ...translations,
  })

  model.isReservation = model.purchaseHistoryTransactionType === 'reservation'
  model.isMpLogistic = model.purchaseHistoryTransactionType === 'mp_logistic'

  model.billingAddress = model?.addresses?.find(({ addressType }) => addressType === 'billing')
  model.deliveryAddress = model?.addresses?.find(({ addressType }) => addressType === 'shipping')
  model.addressesMatch = !!model.billingAddress && !!model.deliveryAddress && Object.keys(model.deliveryAddress)
    .filter((key) => key !== 'addressType')
    .every((key) => model.deliveryAddress[key] === model.billingAddress[key])

  model.purchaseHistoryTransactionItems.forEach((item) => {
    item.additionalData = {}
  })

  return model
}
