import { registerPageResolver } from '@nsf/core/PageResolver.js'
import { resolveCmsPageById } from '@nsf/cms/repositories/CmsServiceRepository.js'

export default function () {
  registerPageResolver(
    'CMS_SERVICE_PAGE',
    resolveCmsPageById,
    () => import('@nsf/cms/dynamicPages/PageDetail.vue'),
  )
}
