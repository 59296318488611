/* eslint-disable camelcase */
import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import cartItemsModified from '../fragments/cartItemsModified.js'
import cartPrices from '../fragments/cartPrices.js'
import cartShippingAddressesShippingMethodsOnly from '../fragments/cartShippingAddressesShippingMethodsOnly.js'
import cartAvailablePaymentMethods from '../fragments/cartAvailablePaymentMethods.js'
import cartAppliedPaymentRestrictions from '../fragments/cartAppliedPaymentRestrictions.js'
import cartBoundProducts from '../fragments/cartBoundProducts.js'
import marketplaceQuote from '../fragments/marketplaceQuote.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import cartFeatures from '../fragments/cartFeatures.js'

const {
  checkout: {
    features: {
      boundProductsEnabled,
    },
  },
} = useAppConfig()

const {
  public: {
    marketplaceEnabled,
  },
} = useRuntimeConfig()

const REQUIRED_CART_ITEM_UPDATE_INPUT = '[CartItemUpdateInput]!'

export default () => Mutation.named('updateCartItems')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    cart_items: variable('cartItems', REQUIRED_CART_ITEM_UPDATE_INPUT),
  })
  .fields([
    field('cart', [
      ...cartItemsModified,
      cartPrices,
      cartShippingAddressesShippingMethodsOnly,
      cartAvailablePaymentMethods,
      cartAppliedPaymentRestrictions,
      cartSelectedPaymentMethod,
      ...boundProductsEnabled ? [cartBoundProducts] : [],
      ...marketplaceEnabled ? [marketplaceQuote] : [],
      cartFeatures,
    ]),
  ])
