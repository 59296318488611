import { Query, variable, field } from '@nsf/core/GraphQL.js'

export default (withOrder, withReservation, withPickupTimes) => Query.named('pickupPlaceList')
  .toDeliveryCalculator()
  .where('coordinates', variable('location', 'CoordinatesInput!'))
  .where('types', variable('types', '[String!]'))
  .where('page', variable('page', 'Int'))
  .where('products', variable('products', '[ProductInput!]'))
  .fields([
    field('data', [
      field('id'),
      field('type'),
      field('title'),
      field('location', [
        field('latitude'),
        field('longitude'),
        field('street'),
        field('city'),
        field('description'),
      ]),
      field('distance'),
      field('image'),
      field('note'),
      field('labels'),
      field('openingHours', [
        field('date'),
        field('day'),
        field('isOpen'),
        field('isOpenNonstop'),
        field('timePeriods', [
          field('from'),
          field('to'),
          field('isOpen'),
        ]),
      ]),
      ...withOrder
        ? [field('order', [
            field('available'),
            field('message'),
            ...withPickupTimes
              ? [field('pickupTimes', [
                  field('cutoffTime'),
                  field('pickupTime'),
                ])]
              : [],
          ])]
        : [],
      ...withReservation
        ? [field('reservation', [
            field('available'),
            field('message'),
            ...withPickupTimes
              ? [field('pickupTimes', [
                  field('cutoffTime'),
                  field('leadTime'),
                  field('pickupTime'),
                ])]
              : [],
          ])]
        : [],
    ]),
    field('page', [
      field('totalElements'),
      field('number'),
      field('last'),
    ]),
  ])
