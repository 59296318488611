import { createRestClient } from '@nsf/core/RestClient.js'
import logger from '@nsf/my-account/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { decodeAndSetTokens, getRefreshToken } from '../utils/auth.js'

const {
  rootConfig: {
    global: {
      multiStoreEnabled,
    },
  },
} = useAppConfig()

const {
  public: {
    storeViewCode,
    myAccountServerUrl,
  },
} = useRuntimeConfig()

let headers = {}
if (multiStoreEnabled) {
  headers = {
    'Compdev-Website-Code': storeViewCode || 'drmax',
  }
}

const authClient = createRestClient({
  baseURL: myAccountServerUrl,
  headers,
})

export const refreshToken = async (refreshTokenValue) => {
  try {
    const body = {
      refreshToken: refreshTokenValue || getRefreshToken(),
    }

    const response = await authClient.fetch(
      '/login/refresh',
      {
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    const {
      data, ok, status,
    } = response

    if (!ok) {
      throw new Error(`${status} ${data && data.detail}`)
    }

    decodeAndSetTokens(data)

    return { ok: true }
  } catch (e) {
    logger.error(
      'refreshToken(***) failed: %s',
      e.message,
    )

    return { ok: false }
  }
}

export const login = async (payload) => {
  try {
    const body = {
      username: payload.email,
      password: payload.password,
    }

    const response = await authClient.fetch(
      '/login',
      {
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    const {
      data, ok, status,
    } = response

    if (ok) {
      decodeAndSetTokens(data)
    }

    return {
      ok: true,
      status,
    }
  } catch (e) {
    logger.error(
      'login(***) failed: %s',
      e.message,
    )

    return {
      ok: false,
      status: 500,
    }
  }
}
