import { getMutationID } from '@nsf/use/utils/getMutationID.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  public: {
    countryCode,
    storeViewCode,
  },
} = useRuntimeConfig()

/**
 * Function that returns a string that is used as an ID for a mutation,
 * based on the current runtime environment variables.
 *
 * @returns {string} The mutation ID: (example: `cz`, `pl-drogeria`)
 * */
export const useMutationID = () => getMutationID(
  countryCode,
  storeViewCode,
)
export default useMutationID
