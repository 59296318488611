import { field } from '@nsf/core/GraphQL.js'

export default field('prices', [
  field('applied_taxes', [
    field('amount', [
      field('currency'),
      field('value'),
    ]),
    field('label'),
  ]),
  field('grand_total', [
    field('currency'),
    field('value'),
  ]),
  field('subtotal_excluding_tax', [
    field('currency'),
    field('value'),
  ]),
  field('subtotal_including_tax', [
    field('currency'),
    field('value'),
  ]),
  field('subtotal_with_discount_excluding_tax', [
    field('currency'),
    field('value'),
  ]),
  field('subtotal_with_discount_including_tax', [
    field('currency'),
    field('value'),
  ]),
])
