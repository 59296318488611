import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  articles: {
    readingTimeEnabled,
  },
} = useAppConfig()

export const readingTime = readingTimeEnabled ? field('reading_time_in_minutes') : ''
