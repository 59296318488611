export default {
  features: {
    pharmacyFilterEnabled: true,
    detailCmsContentEnabled: false,
    dermocenters: {
      cmsContentEnabled: false,
    },
    pharmacistInfoEnabled: false,
  },
}
