import unsupportedBrowser from '#unsupported-browser';
export default function (event) {
  var _useLegacyBrowsersDet = useLegacyBrowsersDetection(event),
    isBrowser = _useLegacyBrowsersDet.isBrowser,
    isUnsupported = _useLegacyBrowsersDet.isUnsupported;
  var _useLegacyBrowsersOpt = useLegacyBrowsersOptions(),
    serveUnsupportedOnNoUA = _useLegacyBrowsersOpt.serveUnsupportedOnNoUA;

  // renders a custom page if it's an unsupported browser
  // or if it's not a browser and the configuration is enabled for it
  if (isUnsupported || !isBrowser && serveUnsupportedOnNoUA) {
    if (!unsupportedBrowser) {
      console.error('File `unsupported-browser.html` was not found. Check the module configuration.');
      return false;
    }
    return unsupportedBrowser;
  }
}