import { field } from '@nsf/core/GraphQL.js'
import { urlField } from './urlField.js'

export default [
  field('category_id'),
  field('level'),
  field('name'),
  field('parent_id'),
  field('path'),
  field('status'),
  field('tip_icon'),
  field('color_scheme'),
  urlField,
]
