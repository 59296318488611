import { field } from '@nsf/core/GraphQL.js'

export default field('applied_coupons', [
  field('code'),
  field('units'),
  field('value', [
    field('currency'),
    field('value'),
  ]),
])
