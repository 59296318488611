import { mapDataToModel } from '@nsf/core/Mapper.js'

const keepOriginal = (data) => data

export const mapDataToCategory = (data, translations = {}) => {
  const model = mapDataToModel(data, {
    eleader: 'eLeaderId',
    top_products: ['topProductsIds', (topProducts) => topProducts ?? []],
    promo_products: ['promoProductsIds', (promoProducts) => promoProducts ?? []],
    defaultSortBy: 'defaultSortBy',
    description: keepOriginal,
    description_top: keepOriginal,
    description_middle: keepOriginal,
    ...translations,
  })

  return model
}
