import { getCmsBlockByIdentifier } from '@nsf/base/repositories/CmsBlockRepository.js'
import { setState } from '@nsf/core/Store.js'

export const state = () => ({
  infoBox: {},
  mainPageText: {},
})

export const mutations = {
  setState,
}

export const actions = {
  async getInfoBox({ commit }) {
    const { cmsBlock } = await getCmsBlockByIdentifier('articles_info_box')
    commit(
      'setState',
      { infoBox: cmsBlock.content },
    )
  },

  async getMainPageText({ commit }) {
    const { cmsBlock } = await getCmsBlockByIdentifier('articles_main_page_text')
    commit(
      'setState',
      { mainPageText: cmsBlock.content },
    )
  },
}
