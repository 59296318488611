import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  i18n: {
    defaultLocale,
  },
} = useAppConfig()

const rules = {
  /**
   * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
   * @param choicesLength {number} an overall amount of available choices
   * @returns a final choice index to select plural word by
   */
  common(
    choice,
    choicesLength,
  ) {
    let option = 0
    if (choice === 0 || choice >= 5) {
      option = 2
    } else if (choice >= 2 && choice <= 4) {
      option = 1
    }
    return option > choicesLength ? choicesLength : option
  },
  /**
   * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
   * @param choicesLength {number} an overall amount of available choices
   * @returns a final choice index to select plural word by
   */
  pl(
    choice,
    choicesLength,
  ) {
    let option = 2
    if (choice === 1) {
      option = 0
    } else if (choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)) {
      option = 1
    }
    return option > choicesLength ? choicesLength : option
  },
  /**
   * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
   * @param choicesLength {number} an overall amount of available choices
   * @returns a final choice index to select plural word by
   */
  ro(
    choice,
    choicesLength,
  ) {
    let option = 2
    if (choice === 1) {
      option = 0
    } else if (choice === 0 || (choice % 100 > 0 && choice % 100 < 20)) {
      option = 1
    }
    return option > choicesLength ? choicesLength : option
  },
}

export default (ctx) => {
  const { locale = defaultLocale } = ctx.app.i18n || {}
  const shortCode = locale.slice(
    0,
    2,
  )

  ctx.app.i18n.pluralizationRules[locale] = rules[shortCode] || rules.common
}
