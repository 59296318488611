/**
 * Function that returns a string that is used as an ID for a mutation,
 * based on the current environment variables.
 *
 * @param countryCode {string} Two-digit country code (example: `PL`)
 * @param storeViewCode {string} Flag of the store view type (example: `drogeria`)
 * @throws {Error} If the parameter `countryCode` is not specified
 * @returns {string} The mutation ID: (example: `cz`, `pl-drogeria`)
 * */
export const getMutationID = (
  countryCode,
  storeViewCode = '',
) => {
  if (!countryCode) {
    throw new Error('The `countryCode` parameter was not specified but is required!')
  }
  return [
    countryCode.toLowerCase(), // Use the lowercase version of the country code
    storeViewCode, // Use the store view code (if defined)
  ]
    .filter(String) // Remove any empty strings
    .join('-') // Join the remaining array elements with a hyphen to form the final ID string
}

export default getMutationID
