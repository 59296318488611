import { Query, field } from '@nsf/core/GraphQL.js'

export default () => Query.named('tags')
  .fields([
    field('items', [
      field('tag_id'),
      field('full_content'),
      field('url_key'),
      field('name'),
    ]),
  ])
  .shouldBeCached(true)
