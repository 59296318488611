export default {
  footer: {
    footerTopLogos: [
      {
        src: '/images/footer/sukl.png',
        alt: 'SUKL',
        link: 'https://rejestrymedyczne.ezdrowie.gov.pl/ra/pharmacy/public/details/1219675',
        width: 120,
        height: 100,
      },
      {
        src: '/images/footer/solgar.jpg',
        alt: 'Solgar banner',
        width: 66,
        height: 100,
      },
    ],
    emailSubscription: false,
    paymentsImages: [
      {
        src: '/images/no-responsive-images/payments_pl.svg',
        alt: 'Akceptujemy płatności: mastercard, visa, payu, dpd',
        width: 350,
        height: 40,
      },
    ],
    suklImages: false,
    footerTopAbsoluteImages: [],
    footerTopAbsoluteImagesHeight: 0,
    feedatyWidget: false,
    feedatyMerchantId: '',
    useFooterCertificateBlock: false,
    footerCertificateText: '',
    footerCertificateTextClass: '',
    footerCertificateImages: [],
    flags: false,
    mobileApplications: true,
  },
}
