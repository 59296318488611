import logger from '@nsf/catalog/logger.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { isString } from '@nsf/core/Utils.js'
import { mapDataToReview, mapDataToProductsTotalRating } from '@nsf/catalog/mappers/ReviewMapper.js'
import { queryRange } from '@nsf/catalog/utils/ElasticQueryUtils.js'

const REVIEWS_PER_LOAD = 20

export const getReviewsByProductId = async (productId, {
  from = 0,
  size = REVIEWS_PER_LOAD,
  sort,
  match,
  range,
} = {}) => {
  try {
    const query = Query.reviews()
      .where('product_id', productId)
    if (!isNaN(from)) {
      query.from(REVIEWS_PER_LOAD * from)
    }
    if (!isNaN(size)) {
      query.size(size)
    }

    if (isString(match) && match.length) {
      query.must((query) => {
        query.should((query) => query.match('detail', match))
        query.should((query) => query.match('drmax_cons', match))
        query.should((query) => query.match('drmax_pros', match))
      })
    }

    queryRange(query, range)

    if (isString(sort)) { // explicit sorting selected by the user
      const [field, order] = sort.split(':')

      query.sort(field, order)
    } else {
      query.sort('created_at', 'desc')
    }

    const response = await query.fetch()

    const hits = response.hits?.hits?.map((hit) => hit._source) ?? []

    const total = response.hits.total?.value ?? response.hits.total

    const reviews = mapDataToReview(hits)

    return { reviews, total }
  } catch (e) {
    logger.error('getReviewsByProductId(%o) failed: %s', productId, e.message)

    return {
      reviews: [],
      total: 0,
    }
  }
}

export const getRatingsByProductIds = async (productIds, countPerStar = false) => {
  try {
    const aggField = 'product_id'
    const aggMetricField = 'ratings.value'

    const query = Query.reviews()
      .whereIn('product_id', productIds)

    query._aggs.product_id = {
      terms: {
        field: 'product_id',
        order: {
          avg: 'desc',
        },
      },
      aggs: {
        avg: {
          avg: { field: aggMetricField },
        },
        ...(countPerStar && {
          stars: {
            terms: { field: aggMetricField },
          },
        }),
      },
    }

    const response = await query.fetch(0)
    const productsTotalRating = mapDataToProductsTotalRating(response.aggregations[aggField] ? response.aggregations[aggField].buckets : [])
    const total = response.hits.total ?? 0

    return { total, ratings: productsTotalRating }
  } catch (e) {
    logger.error('getReviewsTotalOnlyByProductIds(%o) failed: %s', productIds, e.message)

    return { total: 0, ratings: [] }
  }
}
