import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: 'ki0k88magbqmm23sqjr2qnvs',
  dataLayerName: 'dataLayerV2',
  persooEnabled: true,

  persooOfferIdAlternatives: 'e98b024e808a47fc8ace72c3565faf02',
  persooOfferIdBasket: 'ffc36058e45c40f692dad67fa2b2436f',
  persooOfferIdLastViewed: 'd859875ac415422fb44389435e0660c7',
  persooOfferIdNotFound: 'dcbb14ba1f43468ea9f5ce5a51abcee0',
  persooOfferIdPreBasket: 'afe24518e5c545639b196f1b48302517',

  persooPreBasketLimit: 6,
  persooNotFoundLimit: 6,
  ...config,
})
