import { mapDataToModel } from '@nsf/core/Mapper.js'

export const mapFavoritesToData = (data) => {
  if (!data.products) {
    return { products: [] }
  }
  return mapDataToModel(
    data,
    {
      products: (product) => mapDataToModel(
        product,
        {
          sku: String,
          createdAd: Date,
        },
      ),
    },
  )
}

export const mapFavoritePharmaciesToData = (data) => {
  if (!data.pharmacies) {
    return { pharmacies: [] }
  }
  return mapDataToModel(
    data,
    {
      pharmacies: (pharmacy) => mapDataToModel(
        pharmacy,
        {
          id: ['pickupPlaceId', String],
        },
      ),
    },
  )
}
