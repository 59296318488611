// Freezing of data
export const useDeepFreeze = (object) => {
  const propNames = Object.getOwnPropertyNames(object)
  for (const name of propNames) {
    const value = object[name]
    if (value && typeof value === 'object') {
      useDeepFreeze(value)
    }
  }
  return Object.freeze(object)
}

export default useDeepFreeze
