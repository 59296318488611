import logger from '@nsf/catalog/logger.js'
import { queryFilter, queryWithout } from '@nsf/catalog/utils/ElasticQueryUtils.js'
import { listingRequiredFields, omitProductDetailFields } from '@nsf/catalog/utils/FieldsUtils.js'
import {
  mapBundlesToProduct, productsQueryRange, productsQuerySearch, productsQuerySort,
} from '@nsf/catalog/utils/ProductUtils.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { withDefault } from '@nsf/core/Mapper.js'
import { isEmpty } from '@nsf/core/Utils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { ensureArray } from '@nsf/utils/ArrayUtils.js'
// eslint-disable-next-line import/no-cycle
import { mapDataToProduct } from '@nsf/catalog/mappers/ProductMapper.js'
import { mapReviewsToProduct, mapReviewsToProducts } from '@nsf/catalog/mappers/ReviewMapper.js'
import { getRatingsByProductIds } from '@nsf/catalog/repositories/ReviewServiceRepository.js'

import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  rootConfig: {
    global: {
      pagination: {
        productsPerPage,
      },
    },
  },
} = useAppConfig()

const {
  public: {
    reviewServiceEnabled,
  },
} = useRuntimeConfig()

export const getDefaultProduct = () => ({
  id: 0,
  name: '',
  categoryIds: [],
  productLinks: [],
})

/**
 * Special function for getting product for product detail. It's using different filter for
 * PIM statuses!
 * @param id
 * @param callback
 * @return {Promise<{product: (Object|Array)}|{product: {categoryIds: *[], productLinks: *[], name: string, id: number}}|{product: {}}>}
 */
export const getProductDetailById = async (
  id, callback = null,
) => {
  try {
    const types = ['product', 'product_bundle']
    // eslint-disable-next-line no-underscore-dangle
    const query = Query.product()
      ._setType(types)
      .where(
        'product_id', id,
      )
      .omit(omitProductDetailFields)
    if (callback) {
      callback(query)
    }
    let response = await query.getRaw(types.length)
    response = mapBundlesToProduct(response)

    let product = withDefault(
      getDefaultProduct,
      mapDataToProduct(response),
    )

    if (reviewServiceEnabled) {
      const { reviews } = await getRatingsByProductIds([response.sku])
      if (reviews?.length > 0) {
        product = mapReviewsToProduct(product, reviews)
      }
    }

    return {
      product,
    }
  } catch (e) {
    logger.error('getProductDetailById(%o) failed: %s', id, e.message)
    return { product: {} }
  }
}

export const getProductById = async (id, callback = null) => {
  // eslint-disable-next-line no-use-before-define
  const { products } = await getProductsByIds([id], { callback })
  if (!products.length) {
    return { product: getDefaultProduct() }
  }

  return { product: products[0] }
}

export const getProductsByIds = async (ids, {
  from,
  size,
  callback,
  without,
  sort,
  filter,
  search,
  range,
  fields,
} = {}) => {
  try {
    // Force Number (integer) type
    const numberIds = ids.map((id) => parseInt(id))
      .filter((x) => x)

    const query = Query.products()
      .whereIn('id', numberIds)
      .only(fields || listingRequiredFields)
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(from)) {
      query.from(from)
    }
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(size)) {
      query.size(size)
    }

    queryWithout(query, without)

    if (callback) {
      callback(query)
    }

    const sortByGettingIds = productsQuerySort(query, sort, filter) === 0
    queryFilter(query, filter)
    productsQuerySearch(query, search)
    productsQueryRange(query, range)

    const response = await query.fetch()

    const { total } = response.hits
    // eslint-disable-next-line no-underscore-dangle
    const items = response.hits.hits.map((hit) => hit._source)

    const productsUnordered = withDefault(getDefaultProduct, mapDataToProduct(items))

    // Preserve order, keep only products returned from ES
    let products = sortByGettingIds
      ? numberIds.map((id) => productsUnordered.find((product) => product.id === id))
        .filter((product) => product)
      : productsUnordered

    if (reviewServiceEnabled) {
      const skus = items.map((item) => item.sku)
      const { reviews } = await getRatingsByProductIds(skus)
      if (reviews?.length > 0) {
        products = mapReviewsToProducts(products, reviews)
      }
    }

    return {
      products,
      total,
      query: query.toString(),
    }
  } catch (e) {
    logger.error('getProductsByIds(%o) failed: %s', ids, e.message)

    return {
      products: [],
      total: 0,
      query: {},
    }
  }
}

export const getProductsBySkus = async (skus, {
  from = 0,
  size = 10,
  callback,
  without,
  sort,
  filter,
  search,
  range,
  fields,
  ignorePimStatus = false,
} = {}) => {
  try {
    const query = ignorePimStatus
      ? Query.productsUnfiltered()
      : Query.products()

    const filteredSkus = skus.filter((sku) => sku)

    query
      .whereIn(
        'sku', filteredSkus,
      )
      .size(size)
      .from(from)
      .only(fields || listingRequiredFields)

    queryWithout(query, without)

    if (callback) {
      callback(query)
    }

    const sortByGettingSkus = productsQuerySort(query, sort, filter) === 0
    queryFilter(query, filter)
    productsQuerySearch(query, search)
    productsQueryRange(query, range)

    const response = await query.fetch()

    const { total } = response.hits
    // eslint-disable-next-line no-underscore-dangle
    const items = response.hits.hits.map((hit) => hit._source)

    const productsUnordered = withDefault(getDefaultProduct, mapDataToProduct(items))

    // Preserve order, keep only products returned from ES
    let products = sortByGettingSkus
      ? skus.map((sku) => productsUnordered.find((product) => product.sku === sku))
        .filter((product) => product)
      : productsUnordered

    if (reviewServiceEnabled && skus.length > 0) {
      const { reviews } = await getRatingsByProductIds(skus)
      if (reviews?.length > 0) {
        products = mapReviewsToProducts(products, reviews)
      }
    }

    return {
      products,
      total,
      query: query.toString(),
    }
  } catch (e) {
    logger.error('getProductsBySkus(%o) failed: %s', skus, e.message)

    return {
      products: [],
      total: 0,
    }
  }
}
export const getProductsByPromotionAttrs = async (
  {
    ruleIds,
    skus,
  }, {
    from = 0,
    size = 24,
    without,
    sort,
    filter,
    search,
    range,
  } = {},
) => {
  // eslint-disable-next-line no-shadow
  const nestedSalesRulesMust = (ruleIds) => ({
    nested: {
      path: 'sales_rules',
      query: {
        bool: {
          must: {
            terms: {
              'sales_rules.rule_id': ruleIds,
            },
          },
        },
      },
    },
  })

  const callback = (query) => {
    productsQuerySort(query, sort, filter)

    if (!isEmpty(ruleIds) && !isEmpty(skus)) {
      query.must((y) => {
        // eslint-disable-next-line
        y.should((x) => x._must = [nestedSalesRulesMust(ruleIds)])
        y.should((x) => x.whereIn('sku', skus))
      })
    } else if (!isEmpty(ruleIds)) {
      // eslint-disable-next-line
      query.must((y) => y._must = [nestedSalesRulesMust(ruleIds)])
    } else if (!isEmpty(skus)) {
      query.whereIn('sku', skus)
    }
  }

  // eslint-disable-next-line no-use-before-define
  return await getCatalogProducts({
    from,
    size,
    without,
    sort,
    filter,
    search,
    range,
    callback,
  })
}

export const getProductsByCategoryId = async (
  categoryId, {
    from = 0,
    size = productsPerPage,
    without,
    withoutSku,
    sort,
    filter,
    search,
    range,
  } = {},
) => {
  const callback = (query) => {
    query.whereIn('category_ids', categoryId)

    // eslint-disable-next-line no-shadow
    productsQuerySort(query, sort, filter, (query) => query
      .sortByValues('drmax_pim_status', [
        'Available',
        'Special sale off',
        'Visible',
        'Temporary unavailable',
      ])
      .sortByHigherThan('drmax_stock_salable_qty')
      .sortNested('category.com_index', 'asc', 'category', (q) => q.where('category.category_id', categoryId))
      .sortNested('category.position', 'desc', 'category', (q) => q.where('category.category_id', categoryId)))
  }

  // eslint-disable-next-line no-use-before-define
  return await getCatalogProducts({
    from,
    size,
    without,
    withoutSku,
    sort,
    filter,
    search,
    range,
    callback,
  })
}

export const getProductsByBrandId = async (brandId, {
  from = 0,
  size = productsPerPage,
  without,
  sort,
  filter,
  search,
  range,
  categoryId,
} = {}) => {
  const callback = (query) => {
    query.where('drmax_brand', brandId)
      .from(from)
      .size(size)

    productsQuerySort(query, sort, filter)

    if (categoryId) {
      // eslint-disable-next-line no-shadow
      query.must((query) => query.where('category_ids', categoryId))
    }
  }

  // eslint-disable-next-line no-use-before-define
  return await getCatalogProducts({
    from,
    size,
    without,
    sort,
    filter,
    search,
    range,
    callback,
  })
}

export const getProductsBySellerId = async (sellerId, {
  from = 0,
  size = productsPerPage,
  without,
  sort,
  filter,
  search,
  range,
  categoryId,
} = {}) => {
  const callback = (query) => {
    query.where('drmax_marketplace_seller_id', sellerId)
      .from(from)
      .size(size)

    productsQuerySort(query, sort, filter)

    if (categoryId) {
      // eslint-disable-next-line no-shadow
      query.must((query) => query.where('category_ids', categoryId))
    }
  }

  // eslint-disable-next-line no-use-before-define
  return await getCatalogProducts({
    from,
    size,
    without,
    sort,
    filter,
    search,
    range,
    callback,
  })
}

export const getMaxValueOfRange = async (query, rangeField, ranges = null) => {
  try {
    // eslint-disable-next-line no-param-reassign
    const maxQuery = Query.fromString(query)
    maxQuery.without(rangeField)

    maxQuery.size(0)
    if (ranges) {
      maxQuery.aggregateRange(rangeField, ranges)
    }
    maxQuery.aggregateFunction('max', rangeField)

    // get the maximal price among products with the same filter, but without price range
    const maxPriceResponse = await maxQuery.fetch()

    return maxPriceResponse
      ? {
          maxValue: Math.ceil(maxPriceResponse.aggregations[`${rangeField}_max`].value),
          priceOptions: maxPriceResponse.aggregations[rangeField]?.buckets?.map((bucket) => ({
            value: bucket.key,
            from: bucket.from,
            to: bucket.to,
            count: bucket.doc_count,
          })) ?? [],
        }
      : {
          maxValue: 0,
          priceOptions: [],
        }
  } catch (e) {
    logger.log('getMaxPriceInFilter() failed: %s', e.message)

    return {
      maxValue: 0,
      priceOptions: [],
    }
  }
}

export const getNextPageProducts = async (query, from) => {
  try {
    // eslint-disable-next-line no-param-reassign
    query = Query.fromString(query)
    query.from(from)

    const items = await query.get(productsPerPage)
    const products = withDefault(getDefaultProduct, mapDataToProduct(items))

    return { products }
  } catch (e) {
    logger.error('getNextPageProducts() failed: %s', e.message)

    return { products: [] }
  }
}

// TODO: remove this once we have the new catalog service
export const getCatalogProductsCount = async ({
  query,
  filter,
  search,
  range,
} = {}) => {
  try {
    const countQuery = Query.fromString(query).size(0)
    if (filter) {
      queryFilter(countQuery, filter)
    }
    if (search) {
      productsQuerySearch(countQuery, search)
    }
    if (range) {
      productsQueryRange(countQuery, range)
    }

    const response = await countQuery.fetch()
    return {
      total: response.hits.total.value ?? response.hits.total,
      query: countQuery.toString(),
    }
  } catch (e) {
    logger.error('getCatalogProductsCount failed: %s', e.message)

    return {
      total: 0,
      query: '',
    }
  }
}

const getCatalogProducts = async ({
  from = 0,
  size = 24,
  without,
  withoutSku,
  filter,
  search,
  range,
  callback,
} = {}) => {
  try {
    const ignorePimStatus = ensureArray(filter)
      .some((f) => f.includes('drmax_pim_status'))

    const query = ignorePimStatus
      ? Query.productsUnfiltered()
      : Query.products()

    // Filter out products that are not visible in listing
    query.named('drmax_show_in_listing', (e) => e.where('drmax_show_in_listing', true))

    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(from)) {
      query.from(from)
    }
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(size)) {
      query.size(size)
    }
    query.only(listingRequiredFields)

    if (callback) {
      callback(query)
    }

    queryWithout(query, without)

    const baseQuery = query.toString()

    queryWithout(query, withoutSku, 'sku')
    queryFilter(query, filter)
    productsQuerySearch(query, search)
    productsQueryRange(query, range)

    const response = await query.fetch()

    // Note: The newer version of ElasticSearch has had encapsulated total hits in object
    const total = response.hits.total.value ?? response.hits.total
    // eslint-disable-next-line no-underscore-dangle
    const items = response.hits.hits.map((hit) => hit._source)

    let products = withDefault(getDefaultProduct, mapDataToProduct(items))

    if (reviewServiceEnabled) {
      const skus = items.map((item) => item.sku)
      const { reviews } = await getRatingsByProductIds(skus)
      if (reviews?.length > 0) {
        products = mapReviewsToProducts(products, reviews)
      }
    }

    return {
      products,
      total,
      query: query.toString(),
      baseQuery,
    }
  } catch (e) {
    logger.error('getCatalogProducts failed: %s', e.message)

    return {
      products: [],
      total: 0,
      query: '',
    }
  }
}
