const INT = 'Int'
const FLOAT = 'Float'
const STRING = 'String'
const BOOLEAN = 'Boolean'
const ID = 'ID'

const REQUIRED_INT = 'Int!'
const REQUIRED_FLOAT = 'Float!'
const REQUIRED_STRING = 'String!'
const REQUIRED_BOOLEAN = 'Boolean!'
const REQUIRED_ID = 'ID!'

const REQUIRED_STRING_ARRAY = '[String!]'
const INT_ARRAY = '[Int]'

export {
  INT, FLOAT, STRING, BOOLEAN, ID,
  REQUIRED_INT, REQUIRED_FLOAT, REQUIRED_STRING, REQUIRED_BOOLEAN, REQUIRED_ID,
  REQUIRED_STRING_ARRAY,
  INT_ARRAY,
}
