import runtimeConfig from 'drmax/.nsf/runtime.config.mjs'

/**
 * Function that returns environment config depends on app part when it is required
 *
 * Temporary function until migration on Nuxt bridge/3
 *
 * @property {boolean} cached In case if you need to return uncached runtime value
 * @throws {Error} When config is required on client side but nuxt instance is not ready yet
 * @returns {Object}
 * */
export const useRuntimeConfig = () => {
  if (process.client) {
    const { __NUXT__ } = window || {}
    if (!__NUXT__) {
      throw new Error('Nuxt instance is unavailable')
    }
    return __NUXT__.config || {}
  }
  return runtimeConfig
}

export default useRuntimeConfig
