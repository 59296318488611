export default {
  authorDescriptionLongEnabled: true,
  authorEnabled: true,
  authorPostsEnabled: true,
  blockContentEnabled: true,
  displayModeEnabled: true,
  iconEnabled: true,
  menuRootEnabled: true,
  readingTimeEnabled: true,
  relatedPostsEnabled: true,
  relativeUrlEnabled: true,
  richTagsEnabled: true,
  socialNetworksEnabled: true,
  themeClassEnabled: true,
}
