import { Query } from '@nsf/core/ElasticSearch.js'

export const state = () => ({
  taxrules: [],
})

export const actions = {
  async fetch({ commit }) {
    const taxrules = await Query.taxrules().get()

    commit('setTaxrules', taxrules)
  },
}

export const mutations = {
  setTaxrules(state, payload) {
    state.taxrules = payload
  },
}

export const getters = {
  taxrules: (state) => state.taxrules,
}
