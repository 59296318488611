import logger from '@nsf/my-account/logger.js'
import myAccountServiceClient from '../clients/MyAccountServiceClient.js'
import { mapFavoritesToData } from '../mappers/FavoritesMapper.js'
import { withAuth } from '../utils/authDecorator.js'

export const mergeSkusWithUserData = (
  skus, data,
) => {
  if (!skus?.length || !data) {
    return {}
  }

  const userData = JSON.parse(JSON.stringify(data))
  skus.forEach((sku) => {
    if (!userData.data?.products) {
      return
    }

    if (userData.data.products.findIndex((product) => product.sku === sku) === -1) {
      userData.data.products.push({
        sku,
        createdAt: Date.now(),
      })
    }
  })
  userData.version = userData.version + 1

  return userData
}

export const removeSkuFromUserData = (
  sku, data,
) => {
  const userData = JSON.parse(JSON.stringify(data))
  return {
    data: { products: userData.data?.products?.filter((product) => product.sku !== sku) },
    version: userData.version + 1,
  }
}

export const setFavoriteProductsUserData = async (obj = {
  data: { products: [] },
  version: 1,
}) => {
  try {
    const {
      data, ok,
    } = await withAuth(myAccountServiceClient.post)(
      '/clients/me/metadata/favoriteProducts',
      obj,
    )

    if (ok) {
      return {
        data: mapFavoritesToData(data.data),
        version: data.version || 1,
      }
    }

    if (data.message === 'METADATA_NEWER_VERSION_EXISTS') {
      const error = new Error(data.message)
      error.data = data
      throw error
    }
  } catch (e) {
    logger.error(
      'setFavoriteProductsUserData failed: %s',
      e.message,
    )

    if (e.message === 'METADATA_NEWER_VERSION_EXISTS') {
      return {
        data: mapFavoritesToData(e.data.detail.data),
        version: e.data.detail.version,
      }
    }

    logger.error(
      'setFavoriteProductsUserData failed: %s',
      e.message,
    )

    return {
      version: 0,
      data: { products: [] },
    }
  }
}

export const getFavoriteProductsUserData = async () => {
  try {
    const {
      data, ok,
    } = await withAuth(myAccountServiceClient.get)('/clients/me/metadata/favoriteProducts')

    if (ok) {
      return {
        data: mapFavoritesToData(data.data),
        version: data.version,
      }
    }

    if (data.message === 'METADATA_NOT_FOUND') {
      return setFavoriteProductsUserData()
    }
    throw new Error(data.message)
  } catch (e) {
    logger.error(
      'getFavoriteProductsUserData failed: %s',
      e.message,
    )

    return {
      version: 0,
      data: {
        products: [],
      },
    }
  }
}
