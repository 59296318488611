import { setState } from '@nsf/core/Store.js'

export const state = () => ({
  modalsOpened: 0,
})

export const mutations = {
  setState,
  increaseCount (state) {
    state.modalsOpened++
  },
  decreaseCount (state) {
    if(state.modalsOpened === 0) return
    state.modalsOpened--
  },
}

export const getters = {
  getModalsOpened: (state) => state.modalsOpened,
  ableToUnblockScroll: (state) => state.modalsOpened === 0
}

export const actions = {
  increaseCount({commit}) {
    commit('increaseCount')
  },
  decreaseCount({commit}) {
    commit('decreaseCount')
  }
}
