import { Query, field } from '@nsf/core/GraphQL.js'

export default () => Query.multi([
  field('cmsBlocks').alias('headerTitle')
    .where('identifiers', 'header-title')
    .fields([
      field('items', [
        field('content'),
        field('identifier'),
        field('title'),
      ]),
    ]),
  field('cmsBlocks').alias('headerLink')
    .where('identifiers', 'header-links')
    .fields([
      field('items', [
        field('content'),
        field('identifier'),
        field('title'),
      ]),
    ]),
]).shouldBeCached(true)
