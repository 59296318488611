import { defineNuxtConfig } from '@nsf/use/composables/defineNuxtConfig.js'

export default defineNuxtConfig({
  runtimeConfig: {
    public: {
      /**
       * Type: String
       * Value: will be converted into array of strings in v-lazy-image component,
       *  - items must be separated by a comma
       * @example: avif,webp,jpg
       **/
      imageFormats: 'webp',
    },
  },
})
