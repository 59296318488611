export default {
  apotheka: {
    api: process.env.APOTHEKA_API || 'https://stage-apotheka-api.cz.drmax.net',
    web: process.env.APOTHEKA_WEB || 'https://stage-apotheka-web.cz.drmax.net/lekarny/',

    url: {
      js: process.env.APOTHEKA_URL_JS || 'https://stage-apotheka-web-vuejs.cz.drmax.net/lib/apothekaWeb.umd.min.js',
      css: process.env.APOTHEKA_URL_CSS || 'https://stage-apotheka-web-vuejs.cz.drmax.net/lib/apothekaWeb.css',
    },
  },
}
