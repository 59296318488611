import { mapDataToModel } from '@nsf/core/Mapper.js'

export const mapDataToConfig = (data, translations = {}) => mapDataToModel(data, {
  drmax_nsf_json_pharmacy_list: 'drmaxNsfPharmacyJsonFile',
  drmax_nsf_json_faq_list: 'drmaxNsfFaqJsonList',
  drmax_nsf_my_account_enabled: 'drmaxNsfMyAccountEnable',
  drmax_announcement_bar_enabled_from: String,
  drmax_announcement_bar_enabled_to: String,
  drmax_announcement_bar_enabled: Boolean,
  drmax_announcement_bar_type: String,
  drmax_announcement_bar_content: String,
  ...translations,
})
