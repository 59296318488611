import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  rootConfig: {
    feedaty: {
      isEnabled: feedatyEnabled,
    },
  },
} = useAppConfig()

// eslint-disable-next-line import/prefer-default-export
export const nuxtServerInit = ({ dispatch }) => {
  const promises = [
    dispatch('_base/config/fetch'),
    dispatch('_base/megamenu/fetch'),
  ]

  if (feedatyEnabled) {
    promises.push(dispatch('_base/rating/fetch'))
  }

  return Promise.all(promises)
}
