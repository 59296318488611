/**
 * Function that checks whether the .env variable is of type `boolean` and what value it has.
 *
 * @param {string|boolean} envValue Value of the .env variable.
 * @param {boolean} defaultValue Value to return if the .env variable is undefined. (default: false)
 * @returns {boolean} Converted boolean value of .env variable or the sent default value
 */
export const envBool = function (envValue, defaultValue = false) {
  if (typeof envValue === 'boolean') {
    return envValue
  }

  return !envValue ? defaultValue : envValue === 'true'
}

/**
 * Function that checks whether the .env variable is of type `integer` and what value it has.
 *
 * @param {string|number} envValue Value of the .env variable.
 * @param {number} defaultValue Value to return if the .env variable is undefined. (default: 0)
 * @returns {number} Converted number value of .env variable or the sent default value
 */
export const envInt = function (envValue, defaultValue = 0) {
  if (typeof envValue === 'number') {
    return envValue
  }

  return !envValue ? defaultValue : parseInt(envValue, 10)
}

/**
 * Function that checks whether the .env variable is of type `array` and what value it has.
 *
 * @param {string|[]} envValue Value of the .env variable.
 * @param {string[]} defaultValue Value to return if the .env variable is undefined. (default: [])
 * @returns {string[]} Converted array value of .env variable or the sent default value
 */
export const envArray = function (envValue, defaultValue = []) {
  if (typeof envValue === 'object' && Array.isArray(envValue)) {
    return envValue
  }

  return typeof envValue === 'string' ? envValue.split('|') : defaultValue
}
