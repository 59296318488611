import { Query, field } from '@nsf/core/GraphQL.js'
import pharmacistsFields from '../fragments/pharmacistsFields.js'

export default () => Query.named('faqCats')
  .fields([
    field('items', [
      field('category_id'),
      field('title'),
      field('url_key'),
      field('pharmacists', pharmacistsFields),
    ]),
  ])
  .shouldBeCached(true)
