import { isArray, isNullish } from '@nsf/core/Utils.js'

/**
 * @param {any} val
 * @returns {array}
 */
export const ensureArray = (val) => {
  if (isNullish(val)) {
    return []
  }
  if (isArray(val)) {
    return val
  }

  return [val]
}

/**
 * @param {array} array
 * @returns {array{}
 */
export const flatten = (array, depth = 1) => {
  const result = []

  for (const item of array) {
    if (depth > 0 && isArray(item)) {
      result.push(...flatten(item, depth - 1))
    } else {
      result.push(item)
    }
  }

  return result
}

/**
 * Transposes 2D array
 *
 * @param {array} array
 * @returns {array}
 */
export const transpose = (array) => array[0].map((_, col) => array.map((row) => row[col]))

export const getObjectFromArray = (items) => {
  if (!items || items.length === 0) {
    return
  }
  return Object.assign({}, ...items.map(({ id, items, ...rest }) => ({ [id]: { items: getObjectFromArray(items), ...rest } })))
}

/**
 * Finds in array by supplied object
 *
 * Usage:
 * arr.find(by({ id:3 })) // Finds an object in arr which has id === 3
 *
 * @param {object} template
 * @returns {function}
 */
export const by = (template) => (object) => {
  for (const [key, value] of Object.entries(template)) {
    if (value !== object[key]) {
      return false
    }
  }

  return true
}

/**
 * Finds in array by id
 * @param arr
 * @param value
 * @returns {boolean|*}
 */

export const findInArrayById = (array = [], value) => {
  if (!isArray(array) || !value) {
    return false
  }
  return array.find((item) => Number(item.id) === Number(value))
}

export const toChunks = (array = [], countPerChunk = null) => {
  if (!isArray(array) || !array?.length) {
    return []
  }
  if (!countPerChunk) {
    return [array]
  }
  const chunks = []
  for (let i = 0; i < array.length; i += countPerChunk) {
    const chunk = array.slice(i, i + countPerChunk)
    chunks.push(chunk)
  }
  return chunks
}

export const chunksPerPage = (array = [], perPage = null) => {
  if (!isArray(array) || !array?.length) {
    return []
  }
  if (!perPage) {
    return [array]
  }
  const chunks = []
  for (let i = 0; i < array.length; i += perPage) {
    const chunk = array.slice(i, i + perPage)
    chunks.push(chunk)
  }
  return chunks
}

export const mergeWithoutDuplicates = (...arrays) => [...new Set(arrays.flat())]

export const swapKeysValues = (obj) => Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]))

export const areArraysEqualIgnoreOrder = (a, b) => {
  if (a.length !== b.length) {
    return false
  }

  const sortedA = [...a].sort()
  const sortedB = [...b].sort()

  return sortedA.every((val, i) => val === sortedB[i])
}
