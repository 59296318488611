import { Query, field } from '@nsf/core/GraphQL.js'
import baseFields from '../fragments/baseFields.js'
import tags from '../fragments/tags.js'
import itemsPharmacistsFields from '../fragments/pharmacistsFields.js'

export default () => Query.named('faqBlock')
  .fields([
    field('faqs', [
      ...baseFields,
      tags,
      field('pharmacist', itemsPharmacistsFields),
      field('categories', [
        field('category_id'),
        field('title'),
      ]),
    ]),
  ])
  .shouldBeCached(true)
