/**
 * Degrees to radians ratio
 *
 * @type {number}
 */
import { toCamel } from '@nsf/core/Utils.js'

const DEG_TO_RAD = Math.PI / 180

/**
 * Km per degree of latitude/longitude
 *
 * @type {number}
 */
const DEG_LEN = 110.25

/**
 * Calculate distance between two locations
 *  - naive but fast - Pythagorean theorem
 *  - for country level it's just about ca. 0.5 % less accurate than haversine formula
 *
 * @param {Object} locationA
 * @param {Object} locationB
 *
 * @return {number} Distance in km.
 */
export const getDistance = (locationA, locationB) => {
  const x = locationA.latitude - locationB.latitude
  const y = (locationA.longitude - locationB.longitude) * Math.cos(locationB.latitude * DEG_TO_RAD)

  return Math.sqrt(x ** 2 + y ** 2) * DEG_LEN
}

/**
 * Get type of marker cluster
 * @param markers markers in cluster
 * @returns {string} type of pickup places in cluster, default for mixed types
 */
export const getClusterType = (markers) => {
  if (markers.every((marker) => marker.icon.url.includes('pharmacy'))) {
    return 'pharmacy'
  }
  if (markers.every((marker) => marker.icon.url.includes('post'))) {
    return 'postOffice'
  }
  if (markers.every((marker) => marker.icon.url.includes('packeta'))) {
    return 'packeta'
  }
  return 'default'
}

/**
 * Get type of fake cluster
 * @param places places that the fake cluster represents
 * @returns {string} type of pickup places in cluster, default for mixed types
 */
export const getFakeClusterType = (places) => {
  if (places.every((place) => place.type.includes('pharmacy'))) {
    return 'pharmacy'
  }
  if (places.every((place) => place.type.includes('post'))) {
    return 'postOffice'
  }
  if (places.every((place) => place.type.includes('packeta'))) {
    return 'packeta'
  }
  return 'default'
}

/**
 * Get type of place returned from Google Places API, that determines suitable zoom level of the map
 * @param googlePlaceTypes place types that given place has - https://developers.google.com/maps/documentation/places/web-service/supported_types#table2
 * @returns {string} camel-cased place type relevant for figuring out suitable zoom level
 */
export const getGooglePlaceTypeRelevantForZoomLevel = (...googlePlaceTypes) => {
  const firstType = googlePlaceTypes.pop()
  if (firstType === 'political') {
    const secondType = googlePlaceTypes.pop()
    return toCamel(secondType)
  }
  return toCamel(firstType)
}

/**
 * Calculate right triangle values based on side and not right angle on that side
 *
 * @param {Number} b
 * @param {Number} alfa - in degrees
 *
 * @return {Object} triangle values
 */
export const getRightTriangleInfo = (b, alfa) => {
  const gama = 90
  const beta = 180 - alfa - gama
  const c = b / Math.cos(alfa * DEG_TO_RAD)
  const a = c * Math.sin(alfa * DEG_TO_RAD)
  const S = (a * b) / 2
  const o = a + b + c

  return {
    a,
    b,
    c,
    alfa,
    beta,
    gama,
    va: b,
    vb: a,
    vc: 2 * S / c,
    S,
    o,
  }
}

/**
 * Detect where pointer must be absolutely placed inside rectangle based on heading angle
 *       top
 *       ----
 *  left |  | right
 *       ----
 *      bottom
 *
 * @param {Number} width
 * @param {Number} height
 * @param {Number} heading - in degrees
 *
 * @return {String}
 */
export const getPointerLocation = (width, height, heading) => {
  const angle = Math.atan(width / height) / DEG_TO_RAD
  if (Math.abs(heading) < angle) {
    return 'top'
  }
  if (Math.abs(heading) > 180 - angle) {
    return 'bottom'
  }
  if (heading < 0) {
    return 'left'
  }
  return 'right'
}

/**
 * Detect which quadrant of rectangle is representing by heading angle
 * Heading has value from -180 to 180
 *
 *  1|4
 *  -|-
 *  2|3
 *
 * @param {Number} heading - in degrees
 *
 * @return {Number}
 */
export const getHeadingQuadrant = (heading) => {
  if (heading >= 0 && heading < 90) {
    return 1
  }
  if (heading >= -90 && heading < 0) {
    return 2
  }
  if (heading >= 90) {
    return 4
  }
  return 3
}
