import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween.js'
import { isDate } from '@nsf/core/Utils.js'

dayjs.extend(isBetween)

export const MILLIS = 1

export const SECONDS = 1000 * MILLIS

export const MINUTES = 60 * SECONDS

export const HOURS = 60 * MINUTES

export const DAYS = 24 * HOURS

export const DAYLIST = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday',
}

/**
 * @param {Date} date
 * @param {string} format
 */
export const format = (date, format) => dayjs(date).format(format)

/**
 * @returns {Date}
 */
export const now = () => {
  if (now._mock) {
    return now._mock()
  }

  return new Date()
}

/**
 * @param dates
 * @returns {Date}
 */
export const add = (...dates) => {
  const toTime = (date) => (isDate(date) ? date.getTime() : date)

  const time = dates.reduce((acc, date) => acc + toTime(date), 0)

  return new Date(time)
}

export const sub = (...dates) => {
  const toTime = (date) => (isDate(date) ? date.getTime() : date)

  const [_date, ..._dates] = dates

  const time = _dates.reduce((acc, date) => acc - toTime(date), toTime(_date))

  return new Date(time)
}

export const floor = (date) => {
  const floored = new Date(date.getTime())

  floored.setMilliseconds(0)
  floored.setSeconds(0)
  floored.setMinutes(0)
  floored.setHours(0)

  return floored
}

export const max = (...dates) => new Date(Math.max(...dates))

export const min = (...dates) => new Date(Math.min(...dates))

/**
 * @param {Date} a
 * @param {Date} b
 */
export const similar = (a, b, diff = 5 * MINUTES) => Math.abs(a.getTime() - b.getTime()) <= diff

export const isInThePast = (date) => date < now()

export const isToday = (date) => {
  const today = new Date()

  return (
    date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear()
  )
}

export const isTomorrow = (date) => {
  const today = new Date()

  const possibleTomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)

  return (
    date.getDate() === possibleTomorrow.getDate()
    && date.getMonth() === possibleTomorrow.getMonth()
    && date.getFullYear() === possibleTomorrow.getFullYear()
  )
}

export const isTodayBetween = (dateFrom, dateTo) => dayjs().isBetween(dateFrom, dateTo, 'day', '[]')

export const isInSameDay = (a, b) => (
  a.getFullYear() === b.getFullYear()
    && a.getMonth() === b.getMonth()
    && a.getDay() === b.getDay()
)

export const isInSameHour = (a, b) => isInSameDay(a, b)
    && a.getHours() === b.getHours()

/**
 * Checks if the given date is less than 24 hours from now.
 *
 * @param {Date} date
 * @returns {boolean}
 */
export const isLessThan24Hours = (date) => {
  const future = new Date(now().getTime() + 1 * DAYS)

  return date <= future
}

/**
 * @param {Date} dateTime
 */
export const dayTimestamp = (dateTime) => dateTime.getHours() * HOURS + dateTime.getMinutes() * MINUTES + dateTime.getSeconds() * SECONDS
