import { Query } from '@nsf/core/ElasticSearch.js'
import { setState } from '@nsf/core/Store.js'

export const state = () => ({
  enabled: false,
  enabledCatalog: false,
  enabledProductDetail: false,
  enabledCheckout: false,
  allPharmacies: [],
})

// eslint-disable-next-line no-shadow
export const nuxtServerInit = async({ state }) => {
  const config = await Query.deliveryCalculationConfig().first()

  // eslint-disable-next-line no-param-reassign
  state['delivery-fe'].enabled = !!config?.enabled
  // eslint-disable-next-line no-param-reassign
  state['delivery-fe'].enabledCatalog = !!config?.enabled_catalog
  // eslint-disable-next-line no-param-reassign
  state['delivery-fe'].enabledProductDetail = !!config?.enabled_product_detail
  // eslint-disable-next-line no-param-reassign
  state['delivery-fe'].enabledCheckout = !!config?.enabled_checkout
}

export const mutations = {
  setState,
}

export const getters = {
  // eslint-disable-next-line no-shadow
  getEnabledCatalog: (state) => state.enabled && state.enabledCatalog,
}
