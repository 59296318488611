export default {
  priceFilterRanges: [
    { from: 0, to: 1 },
    { from: 1, to: 2 },
    { from: 2, to: 5 },
    { from: 5, to: 10 },
    { from: 10, to: 20 },
    { from: 20, to: 50 },
    { from: 50, to: 100 },
    { from: 100, to: 200 },
    { from: 200, to: 500 },
    { from: 500, to: 1000 },
    { from: 1000, to: 2000 },
    { from: 2000, to: 5000 },
    { from: 5000, to: 10000 },
    { from: 10000, to: 20000 },
    { from: 20000, to: 50000 },
    { from: 50000 },
  ],
}
