/* eslint-disable camelcase */
import { Mutation, field, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import cartAvailablePaymentMethods from '../fragments/cartAvailablePaymentMethods.js'
import cartAvailableShippingMethods from '../fragments/cartShippingAddressesShippingMethodsOnly.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import appliedPaymentRestrictions from '../fragments/cartAppliedPaymentRestrictions.js'
import cartPrices from '../fragments/cartPrices.js'
import cartItemsModified from '../fragments/cartItemsModified.js'
import marketplaceQuoteTotals from '../fragments/marketplaceQuoteTotals.js'

const {
  public: {
    marketplaceEnabled,
  },
} = useRuntimeConfig()

const {
  checkout: {
    features: {
      deliveryRouteIdEnabled,
      orderTypeEnabled,
    },
  },
} = useAppConfig()

export default () => Mutation.named('setShippingMethodsOnCart')
  .where('input', {
    cart_id: variable('cartId', REQUIRED_STRING),
    ...orderTypeEnabled && { order_type: variable('orderType', REQUIRED_STRING) },
    shipping_methods: [{
      carrier_code: variable('carrier', REQUIRED_STRING),
      method_code: variable('method', REQUIRED_STRING),
      pickup_id: variable('pickupId', REQUIRED_STRING),
      ...deliveryRouteIdEnabled && { delivery_route_id: variable('deliveryRouteId', REQUIRED_STRING) },
    }],
  })
  .fields([
    field('cart', [
      ...cartItemsModified,
      cartAvailablePaymentMethods,
      cartAvailableShippingMethods,
      cartSelectedPaymentMethod,
      cartPrices,
      appliedPaymentRestrictions,
      field('order_type'),
      ...marketplaceEnabled ? [field('drmax_quote_type'), field('marketplace_quote', [marketplaceQuoteTotals])] : [],
    ]),
  ])
