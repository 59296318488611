import { destr } from 'destr'
import { snakeCase } from 'scule'

const ENV_PREFIX = 'NUXT_'

export const getEnv = (key) => {
  const envKey = snakeCase(key).toUpperCase()
  return destr(process.env[ENV_PREFIX + envKey])
}

export default getEnv
